import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import {
    colorFromProp,
    darkenFromProp,
    componentWithoutProps
} from '../../../styles/styled-components/helpers'

export const NormalButton = styled.button`
  background: ${colorFromProp('background')};
  border: 1px solid ${colorFromProp('border')};
  border-radius: ${props =>
        props.borderRadius || props.theme.button.borderRadius};
  color: ${colorFromProp('color')};
  cursor: pointer;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font: inherit;
  font-size: ${props => props.theme.button.fontSize};
  font-weight: ${props => props.theme.button.fontWeight};
  letter-spacing: ${props => props.theme.button.letterSpacing};
  line-height: ${props => props.theme.button.lineHeight};
  margin: 0;
  opacity: 1;
  padding: ${props => props.theme.button.paddingVertical} ${props =>
        props.theme.button.paddingHorizontal};
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: ${props => props.theme.button.textTransform};
  transition-duration: 0.23s;
  transition-property: background-color, border-color, color, opacity;
  transition-timing-function: ease-out;
  width: ${props => props.width};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:focus,
  &:active {
    !props.disabled && {
        background-color: ${darkenFromProp(0.1, 'background')};
        border-color: ${darkenFromProp(0.1, 'border')};
    }


    ${props =>
        props.hoverProps &&
        css`
      background: ${colorFromProp('background')({
            theme: props.theme,
            ...props.hoverProps
        })};
      border-color ${colorFromProp('border')({
            theme: props.theme,
            ...props.hoverProps
        })};
      color: ${colorFromProp('color')({
            theme: props.theme,
            ...props.hoverProps
        })};
    `}
  }

  ${props =>
        props.round &&
        css`
          padding: 0;
          border-radius: 50%;

          &::before {
              display: block;
              content: '';
              width: 100%;
              padding-top: 100%;
          }
      `}

  ${props =>
        props.disabled &&
        css`
          background: #999;
          border: 1px solid #444;
          opacity: 0.4;
      `}

  ${props =>
        props.size === 'sm' &&
        css`
          padding: ${props.theme.button.paddingVerticalSm}
              ${props.theme.button.paddingHorizontalSm};
          font-size: ${props.theme.button.fontSizeSm};
      `};

  ${props =>
        props.size === 'lg' &&
        css`
          padding: ${props.theme.button.paddingVerticalLg}
              ${props.theme.button.paddingHorizontalLg};
          font-size: ${props.theme.button.fontSizeLg};
      `};

  ${props =>
        props.padding &&
        css`
          padding: ${props.padding};
      `}
`

export const AnchorButton = NormalButton.withComponent('a')

export const LinkButton = NormalButton.withComponent(
    componentWithoutProps(Link, ['background', 'fullWidth'])
)

export const ButtonContent = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    opacity: ${props => (props.hide ? 0 : 1)};
    transition: opacity 0.23s;

    ${props =>
        props.round &&
        css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        `};
`
