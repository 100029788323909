export const configureHotJar = user => {
    //check if the user is in the exemption email list
    const hotJarExemptionList = [
        'canon.dev@canon.com.au',
        'mahmod.homaisi@canon.com.au'
    ]

    if (hotJarExemptionList.includes(user)) {
        document.cookie =
            '_hjIncludedInSample =-1; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }
}
