import {Switch} from 'react-router'
import PrivateRoute from 'routes/private-route'
import {Route} from 'react-router-dom'
import React from 'react'
import {metadata} from 'app-config'
import Assets from './assets'
import {GenericError} from 'components/error-pages'
import ErrorCodes from 'utils/errorCodes'
import Loadable from 'react-loadable'
import Loading from 'components/common/loading'

const Consumables = Loadable({
    loader: () => import('./consumables/consumables-listing/index.js'),
    loading: () => (
        <Loading
            center
            size="40px"
            thickness="1px"
            paddingAround="200px"
            message="Loading Consumable Orders"
        />
    )
})

const Consumable = Loadable({
    loader: () => import('./consumables/consumable/index.js'),
    loading: () => (
        <Loading
            center
            size="40px"
            thickness="1px"
            paddingAround="200px"
            message="Loading Consumable Order"
        />
    )
})

const Request = Loadable({
    loader: () => import('./consumables/consumables-request/index.js'),
    loading: () => (
        <Loading
            center
            size="40px"
            thickness="1px"
            paddingAround="200px"
            message="Loading Consumable Request Data"
        />
    )
})

const Routes = () => (
    <Switch>
        <PrivateRoute
            exact
            path={metadata.consumables.route}
            component={Consumables}
            permissions={metadata.consumables.permissions}
        />
        <Route
            exact
            path={metadata.consumable.route}
            render={props => {
                if (props.match.params.orderId === 'request') {
                    return <Request {...props} />
                } else {
                    return <Consumable {...props} />
                }
            }}
        />

        <PrivateRoute
            path={metadata.assetorders.route}
            component={Assets}
            permissions={metadata.assetorders.permissions}
        />
        <Route
            render={() => {
                return <GenericError errorObj={ErrorCodes.NOT_FOUND}/>
            }}
        />
    </Switch>
)

export default Routes
