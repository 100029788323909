var base64 = require('base64-js')

function padding(str) {
    var mod = str.length % 4
    var pad = 4 - mod

    if (mod === 0) {
        return str
    }

    return str + new Array(1 + pad).join('=')
}

function stringToByteArray(str) {
    var arr = new Array(str.length)
    for (var a = 0; a < str.length; a++) {
        arr[a] = str.charCodeAt(a)
    }
    return arr
}

function byteArrayToString(array) {
    var result = ''
    for (var i = 0; i < array.length; i++) {
        result += String.fromCharCode(array[i])
    }
    return result
}

function encode(str) {
    return base64
        .fromByteArray(stringToByteArray(str))
        .replace(/\+/g, '-') // Convert '+' to '-'
        .replace(/\//g, '_') // Convert '/' to '_'
}

function decode(str) {
    str = padding(str)
        .replace(/-/g, '+') // Convert '-' to '+'
        .replace(/_/g, '/') // Convert '_' to '/'

    return byteArrayToString(base64.toByteArray(str))
}

module.exports = {
    encode: encode,
    decode: decode
}
