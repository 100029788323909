import React, {useEffect, createRef} from 'react'
import './landing.css'
import AnimateLoad from 'components/common/animate-load'
import Tile from './components/tile.js'
import {connect, useDispatch} from 'react-redux'
import Tiles from './tile.json.js'
import {gsap} from 'gsap/dist/gsap'
import {Slide} from 'react-slideshow-image'
import {setSearchOptions, setPagingSetting} from '../../redux/actions/index'
import 'react-slideshow-image/dist/styles.css'
import Button from "../../components/common/buttons/link-button-icon";
import {AU} from 'utils/constants'

const Landing = (props) => {
    const dispatch = useDispatch()

    const slideImages = [
        {
            id: 1,
            title: 'My Canon Business',
            url: '/assets/img/landing-page-hero-nz-slide-1.jpg',
            caption: 'Your Business, Your Time, Your Way'
        },
        {
            id: 2,
            url: '/assets/img/Print-Hub-MCB-Portal-Banner.png',
            title: 'Canon Print Hub',
            caption: 'You qualify for Preferential Customer Pricing through the Canon Print Hub. Register now and receive $150 credit on high quality outsourced printing, including brochures, booklets, signage and more.',
            buttonCaption: 'Register Now',
            buttonAction: () => window.open("https://print.canon.co.nz/login/userregistration/UserRegistration", "_blank")
        },
    ];

    const {
        isAccountAccessLoading,
        isAccountDetailsLoading,
        memberInfo,
        isNZ
    } = props

    const handleSetOptionsData = () => {
        const payload = {}
        dispatch(setSearchOptions(payload))
        dispatch(setPagingSetting(payload))
    }
    // cards, elements tha will be used in the tween
    let cards = []

    // the timeline instance
    const tl = gsap.timeline()

    //MH- test tween
    const tweenRestart = () => {
        const myRef = '.tile-101'
        tl.to(
            myRef,
            0.6,
            {
                stagger: {
                    autoAlpha: 1,
                    y: -20,
                    ease: 'easeInOut',
                    onComplete: null
                },
                delay: 2
            },
            0.1
        ).restart()
    }


    useEffect(
        () => {
            if (
                !cards ||
                cards.length <= 0 ||
                isAccountDetailsLoading ||
                isAccountAccessLoading
            ) return

            handleSetOptionsData()

            tl.to(
                '.tile-101',
                0.6,
                {
                    stagger: {
                        autoAlpha: 1,
                        y: -20,
                        ease: 'easeInOut',
                        onComplete: null
                    }
                },
                0.1
            ).play()
        },
        [isAccountAccessLoading, isAccountDetailsLoading, cards]
    )


    return (
        <div className="mcb__landing">
            {isNZ ?
                <Slide>
                    {slideImages.map(({id, title, caption, buttonCaption, buttonAction, url}, index) => (
                        <div className="landing-hero" style={{backgroundImage: `url(${url})`}} key={`${id}-${index}`}>
                            <div className="heading d-flex-jc d-flex-dir-c" key={id}>
                                <div className="">
                                    <h1 className="fs-bigy fs-headline ma-0 pb-16">
                                        {title}
                                    </h1>
                                    <div className="fs-headliney fs-title ma-0 pt-16">
                                        {caption}
                                    </div>
                                    {(buttonCaption && buttonAction) && (
                                        <div className='buttonContainer'>
                                            <Button
                                                btnClass="btn__1-r"
                                                onClick={buttonAction}
                                                btnText={buttonCaption}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </Slide>
                :
                <div className="landing-hero" style={{backgroundImage: `url('/assets/img/landing-page-hero.jpg')`}}>
                    <div className="heading d-flex-jc d-flex-dir-c">
                        <div className="">
                            <h1 className="fs-bigy fs-headline ma-0 pb-16">
                                Welcome to MyCanon Business
                            </h1>
                            <div className="fs-headliney fs-title ma-0 pt-16">
                                Your Business, Your Time, Your{' '}<span onClick={() => tweenRestart()}>Way</span>
                            </div>
                        </div>
                    </div>
                </div>

            }

            <div
                className="section-layout-1200 tiles-container d-flex-jsb"
                style={{
                    flexWrap: 'wrap',
                    filter:
                        isAccountDetailsLoading && isAccountAccessLoading
                            ? 'blur(3px)'
                            : 'none',
                    pointerEvents:
                        isAccountDetailsLoading && isAccountAccessLoading
                            ? 'none'
                            : 'inherit'
                }}
            >
                {Object.keys(Tiles).map(key => {
                    let permissions = new Set()
                    Tiles[key].links.forEach(link => {
                        if (link.permissions)
                            link.permissions.forEach(permission => {
                                permissions.add(permission)
                            })
                    })

                    return (
                        <Tile
                            title={Tiles[key].title}
                            icon={Tiles[key].icon}
                            links={Tiles[key].links}
                            toolTip={Tiles[key].toolTip}
                            metadata={{
                                permissions: [...permissions]
                            }}
                            memberInfo={memberInfo}
                            root={props.match.params.id}
                            key={Tiles[key].title}
                            ref={(cards[key] = createRef(div => div))}
                            isNZ={isNZ}
                        />
                    )
                })}
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    const isAccountDetailsLoading = state?.canonhub?.accountDetails?.loading
    const isAccountAccessLoading = state?.canonhub?.accountAccess?.loading
    const isNZ = state?.canonhub?.accountDetails?.data?.Localization !== AU
    const memberInfo = state?.canonhub?.memberInfo

    return {
        isAccountDetailsLoading,
        isAccountAccessLoading,
        isNZ,
        memberInfo
    }
}

export default connect(
    mapStateToProps,
    null
)(AnimateLoad(Landing))
