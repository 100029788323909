import {
    RRP_ADD_PERMISSION,
    RRP_REMOVE_PERMISSION,
    RRP_CLEAR
} from './constants'

export function addRoles(roles = '[]') {
    return {
        type: RRP_ADD_PERMISSION,
        roles: Array.isArray(roles) ? roles : [roles]
    }
}

export function removeRoles(role) {
    return {
        type: RRP_REMOVE_PERMISSION,
        role
    }
}

export function clearRoles() {
    return {
        type: RRP_CLEAR
    }
}
