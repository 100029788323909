import * as actionTypes from '../actions/actionTypes.js'

const initialState = {
    selectedAccount: '',
    selectedDevice: '',
    selectedServiceOrder: '',
    header: {
        links: '',
        permissions: []
    }
}

const sel = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SELECTED_ACCOUNT:

            return {
                ...state,
                selectedAccount: action.selectedAccount
            }
        case actionTypes.UPDATE_SELECTED_DEVICE:

            return {
                ...state,
                selectedDevice: action.selectedDevice
            }
        case actionTypes.UPDATE_SELECTED_SERVICEORDER:

            return {
                ...state,
                selectedServiceOrder: action.selectedServiceOrder
            }

        default: {

            return state
        }
    }
}

export default sel
