import axios from 'axios'
import history from 'history/history.js'
import {metadata} from 'app-config'

const forbiddenStatusCodes = [401, 403, 405, 406]
const exemptUrls = '/invoices'

export const configureInterceptors = () => {
    /**
     * @name constructor
     * @desc intercept the outgoing http request and append the token (for Canon STS)
     * @desc calling them injectors cause i keep forgetting there interceptors
     * @param {Object} request - the request properties
     */
    axios.interceptors.request.use(
        (request) => {
            let token = localStorage.getItem('access_token')
            if (window.location.hostname === 'localhost' || window.location.hostname === 'devbusiness.canon.com.au') {
                // add new environment
                //if (!window.isDEMO) request.url = request.url.replace('hub/', 'devhub/')
            }
            if (window.isPROD) request.url = request.url.replace('uat', '')

            //[SP] Wrong place; this is the interceptor for the normal requests. Need to put this logic in the MockService for intercepting the mock urls
            // if (window.isDEMO) {
            //   request.url = window.isPROD
            //     ? request.url.replace('env', 'prod')
            //     : request.url.replace('env', 'uat')
            // }
            if (
                window.location.pathname.indexOf('service/techconnect') >= 0 &&
                request.url.indexOf('/serviceOrders/') >= 0 &&
                request.url.indexOf('?key=') > 0
            ) {
                // remove then bearer token on the salted service order request
                if (window.DEBUG) console.log('intc: Salted url - removing token')
                token = null
            }
            //forms access
            if (
                window.location.pathname.indexOf('service/tech/') >= 0
                //  &&
                // request.url.indexOf('/accounts/') >= 0 &&
                // request.url.indexOf('/forms/') > 0 &&
                // request.url.indexOf('technician?url=') > 0 &&
                // request.url.indexOf('key=') > 0
            ) {
                // remove then bearer token on the salted forms access URL
                if (window.DEBUG) console.log('intc: Salted url - removing token')
                token = null
            }
            // console.log(request.headers)
            if (request?.url?.indexOf('canon.com.au') > 0) {
                //console.log('token',token)
                request.headers = {
                    Accept: 'application/json',
                    ...request.headers,

                    Authorization: `Bearer ${token}`,
                    Source: 'development',
                    ...request.heads // overrides any params
                }
            }
            return request
        },
        (error) => {
            console.error(error)
            return Promise.reject(error)
        }
    )
    // ---------------------------------------------
    //   HTTP INTERCEPTOR FOR AXIOS - RESPONSE
    // ---------------------------------------------
    axios.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            if (error.response && forbiddenStatusCodes.includes(error.response.status))
                history.push(metadata.accesserror.route)
            // else return Promise.reject(error)
            else return history.push(metadata.accesserror.route)
        }
    )
}
