import styled from 'styled-components'
import { color } from '../../../styles/styled-components/helpers'

import Select from 'react-select'

// css calc needs units
const fixZero = v => (v === '0' ? '0px' : v)

// Determine the inner height by removing the border width
const innerHeight = ({ theme, height }) =>
  `calc(${height} - ${fixZero(theme.forms.borderTopWidth)} - ${fixZero(
    theme.forms.borderBottomWidth
  )})`

export const StyledSelect = styled(Select)`
  font-size: ${props => props.theme.forms.fontSize};

  .Select-control {
    border-radius: ${props => props.theme.forms.borderRadius};
    border-top-width: ${props => props.theme.forms.borderTopWidth};
    border-right-width: ${props => props.theme.forms.borderRightWidth};
    border-bottom-width: ${props => props.theme.forms.borderBottomWidth};
    border-left-width: ${props => props.theme.forms.borderLeftWidth};
    border-color: ${props => color(props.theme.forms.borderColor)(props)};
    height: ${props => props.height};

    &:hover {
      box-shadow: none;
    }
  }

  &.Select.is-focused:not(.is-open) {
    > .Select-control {
      ${'' /* box-shadow: none; */}
      border-color: blue;
    }
  }

  &.Select.is-disabled:not(.is-open) {
    > .Select-control {
      background-color: ${color('disabled')};
      cursor: not-allowed;
    }
  }

  .Select-input {
    height: ${innerHeight};
    line-height: ${innerHeight};
    padding-left: ${props =>
    props.searchable ? props.theme.forms.paddingHorizontal : 0};
    padding-right: ${props =>
    props.searchable ? props.theme.forms.paddingHorizontal : 0};

    > input {
      color:
      line-height: ${innerHeight};
      padding: 0;
    }
  }

  &.Select--single {
    .Select-control {
      .Select-value {
        line-height: ${innerHeight};
        padding-left: ${props => props.theme.forms.paddingHorizontal};
        padding-right: ${props => props.theme.forms.paddingHorizontal};
      }
    }
  }

  &.Select--multi {
    .Select-value-icon {
      padding: 1px 5px;
    }

    .Select-input {
      margin-left: ${props => props.theme.forms.paddingHorizontal};
    }
  }

  .Select-placeholder {
    line-height: ${innerHeight};
    padding-left: ${props => props.theme.forms.paddingHorizontal};
    padding-right: ${props => props.theme.forms.paddingHorizontal};
    color: ${props => color(props.theme.forms.placeholderColor)(props)};
    opacity: ${props => color(props.theme.forms.placeholderOpacity)(props)};
  }
  .Select-menu-outer {
    z-index: 999 !important;
    font-weight:400;
    
}
  
`

export const StyledCreatableSelect = StyledSelect.withComponent(
  Select.Creatable
)
