import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 90%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
`

export const ErrorSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    i {
        font-size: 5rem;
    }
    p {
        font-size: 2rem;
        a{
          font-size:1.2rem;
          color: #1601b3;
          text-decoration: underline;
          cursor: pointer;
        }
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 1.2rem;
    width: 70%;
    line-height: 1;
    li {
        padding: 0.5rem;
    }
`
export const ErrorLink = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 1.2rem;
    width: 70%;
    line-height: 1;
    li {
        padding: 0.5rem;
    }
`
