import * as actionTypes from '../actions/actionTypes.js'

const initialState = {
    acls: []
}

const metadata = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ACL:
            return [...action.data]
        default: {
            return state
        }
    }
}

export default metadata
