/**
 * Canon
 *
 * @package     mcb-front-end
 * @project     Ignite
 *
 */

// -------------------------------------
//   Dependencies
// -------------------------------------
/* --empty block-- */

// -------------------------------------
//   Page - Home
// -------------------------------------
/* --empty block-- */

// ---------------------------------------------
//   Export block
// ---------------------------------------------
import HamburgerMenu from './hamburger-menu.js'

export default HamburgerMenu
