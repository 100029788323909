import React from 'react'
import './index.css'
//import { TimelineLite } from 'gsap/all'
const AnimateLoad = WrappedComponent => {
    return class extends React.Component {
        constructor(props) {
            super(props)
            //this.componentTimeLine = new TimelineLite({ paused: true })
            this.state = { didMount: false }
        }

        componentDidMount() {
            setTimeout(() => {
                this.setState({ didMount: true })
            }, 0)
        }

        componentDidUpdate(prevProps, prevState, snapshot) {}

        render() {
            const { didMount } = this.state
            // cards, elements tha will be used in the tween
            //this.animateCards = []
            return (
                <div
                    className={`load-fade-in ${didMount && 'visible'}`}
                    //ref={div => (this.animateCards[0] = div)}
                >
                    <WrappedComponent {...this.props} />
                </div>
            )
        }
    }
}

export default AnimateLoad
