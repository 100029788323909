import React, {Component, Fragment} from 'react'
import StoreManager from "redux/store-manager";
import dsf from "../../../redux/reducers/dsf-reducer";
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {login, logout, isLoggedIn, changePassword, reLoginSSO} from 'auth'
import './header.css'
import AccountSelect from './AccountSelect/index.js'
import HamburgerMenu from './HamburgerMenu'
import * as actionCreators from 'redux/actions/index.js'
import {debounce} from 'utils'
import {setAccountFavs} from '../../common/local-storage/helper.js'

import {withToastManager} from 'react-toast-notifications'
import axios from 'axios'
import config from 'config.json'
import {store} from '../../../redux/store.js'
import * as actionTypes from '../../../redux/actions/actionTypes.js'
import {
    ACCOUNT_INFO, MEMBER_ID,
    MEMBER_INFO,
    RELOGIN_FLAG,
    STORED_ACCOUNT
} from '../../../auth/constants'

let haveAccountList = false
let fetchAccountList = false

const exemptedURLs = [
    'callback',
    'accesserror',
    'accesserrorsts',
    'signout',
    'service',
    'ordercomplete'
]

StoreManager.registerReducers({dsf: dsf})


class Header extends Component {
    constructor(props) {
        super(props)
        if (props) {
            this.state = {
                accountList: null,
                accountListMax: 1,
                accountListLarge: false,
                accountNum: '',
                memberAccount: null,
                searchTerm: '',
                showMenu: false,
                hideMenu: false,
                loginOverride: false,
                showSubmenu: false,
                showSubmenu1: true,
                showSubmenu2: false,
                showSubmenu3: false,
                showLinksSubmenu: false,
                accessTokenStatus: false,
                showAccessDenied: false,
                linksSubmenus: {
                    Account: true
                }
            }
        }

        this.updateSelectedAccount = this.updateSelectedAccount.bind(this)
        this.search = debounce(this.search, 750)
        this.onSearchInputChange = this.onSearchInputChange.bind(this)
        this.onAccountClickHandler = this.onAccountClickHandler.bind(this)
        this.toggleLinksSubmenu = this.toggleLinksSubmenu.bind(this)
        this.handleAccountMenuClick = this.handleAccountMenuClick.bind(this)
        this.handleLinksHamburgerMenuClick = this.handleLinksHamburgerMenuClick.bind(
            this
        )
        this.handleClick = this.handleClick.bind(this)
    }

    setAccount(newAccount) {
        this.props.getAccountDetails({
            accountNumber: newAccount,
            force: true
        })
        this.props.updateSelectedAccount(newAccount)
    }

    componentDidMount() {
        const url = this.props.location.pathname
        const newAccount = url.split('/')[1]

        if (newAccount.length && newAccount !== 'callback' && url !== "/undefined") {
            localStorage.setItem(STORED_ACCOUNT, newAccount);
        }


        if (isLoggedIn()) {
            //[SP] if there is a manual change of the account number in the URL then the header re-mounts, then we need to check if the selectedAccount and fecth the details if different
            const prevSelectedAccount = localStorage.getItem(
                'selectedAccount'
            )

            if (exemptedURLs.includes(newAccount)) return
            if (newAccount) {
                this.setState({
                    accountNum: newAccount
                })
                //[MH] check local storage and redux, if not same as header then refresh
                if (newAccount !== prevSelectedAccount || this.props.selectedAccount !== newAccount) this.setAccount(newAccount)
            } else if (prevSelectedAccount !== null && prevSelectedAccount !== "") {
                this.setAccount(localStorage.getItem(
                    'selectedAccount'
                ))
            }

            if (!this.props?.memberAccount?.member) {

                const memberId = localStorage.getItem(MEMBER_ID)

                store
                    .dispatch(
                        actionCreators.getCanonHub({
                            type: actionTypes.UPDATE_MEMBER_INFO,
                            memberId,
                            force: true
                        })
                    )
                    .then(() => {
                        store.dispatch(actionCreators.getCanonHub({type: actionTypes.UPDATE_ACCOUNTS}))
                    })
                    .catch((e) => {
                        throw new Error(`Error retrieving the Member Info - ${e}`)
                    })
            }
        }


        this.setState({
            accountListMax: Math.max(
                this.props.memberAccount
                    ? this.props.memberAccount.accounts.length
                    : 0 || 0,
                this.props.totalResults || 0,
                this.state.accountListMax
            )
        })
        document.addEventListener('touchstart', this.handleClick, false)
        document.addEventListener('mousedown', this.handleClick, false)
        document.addEventListener('keyup', this.handleClick, false)
    }

    componentDidUpdate(prevProps) {
        const {authAccessToken, location} = this.props

        //watcher on the pathname
        //If the account is changed re fetch the account details
        if (prevProps.authAccessToken !== authAccessToken && authAccessToken) {
            this.setState({accessTokenStatus: true})
            const account = this.state.accountNum
            //we we have an account number at this point it means our initial call would have been blocked
            //so we make the call here
            if (account) this.setAccount(account)
        }

        //watcher on the pathname
        //If the account is changed re fetch the account details
        if (prevProps.location.pathname !== location.pathname) {

            const newAccount = location.pathname.split('/')[1]
            const prevSelectedAccount = prevProps.location.pathname.split(
                '/'
            )[1]
            if (exemptedURLs.includes(newAccount)) return
            if (newAccount) {
                this.setState({accountNum: newAccount})
                if (newAccount !== prevSelectedAccount) {
                    //check if we have an access token
                    //not the best approach
                    //authAccessToken ? this.setAccount(newAccount) : this.setAccountAttempts(newAccount)
                    if (authAccessToken) this.setAccount(newAccount)
                }
            }
        }
    }

    //might not be required
    setAccountAttempts(newAccount) {
        const {accessTokenStatus} = this.state
        let i = 0
        do {
            setTimeout(() => {
                this.setAccount(newAccount)
            }, 600)
            i++
        } while (i < 5 || !accessTokenStatus)
    }

    //[MH] need to move functions out of this
    //https://reactjs.org/docs/react-component.html#unsafe_componentwillupdate
    componentWillUpdate(nextProps, nextState) {

        if (!this.state.showAccessDenied && nextProps.hubError) {
            if (nextProps.hubError === 10106 && !localStorage.getItem(RELOGIN_FLAG)) {
                localStorage.setItem(RELOGIN_FLAG, true)

                store.dispatch(actionCreators.getCanonHub({
                    type: actionTypes.CLEAR_ACCOUNTS,
                }))
                    .then(() => {
                        const storedAccount = localStorage.getItem(STORED_ACCOUNT)
                        if (storedAccount) reLoginSSO(storedAccount)
                    })

                //TODO: maybe re-call the last failed API (windows.relocate = fav account) if login doesnt already.
            } else {
                nextProps.history.replace('/accesserrorsts/')
                /** [SP] Move this to PostAuth since its required only once during the login*/
                this.setState({showAccessDenied: true})
            }
        } else if (this.state.showAccessDenied) {

        }

        if (
            nextProps &&
            nextProps.accountList &&
            nextProps.totalPages &&
            !haveAccountList
        ) {
            if (window.DEBUG) console.log('acct: accountList', nextProps.accountList)
            haveAccountList = true
            this.setState({
                accountListMax: Math.max(
                    nextProps.totalResults,
                    this.state.accountListMax
                )
            })
        }

        if (
            nextProps.memberAccount &&
            this.state.memberAccount &&
            this.state.memberAccount.company === nextProps.memberAccount.company
        ) {
        } else {
            if (!nextProps.memberAccount) return
            this.setState({
                memberAccount: nextProps.memberAccount, // storing locally to stop the flicker of company name,
            })
            if (this.props.toastManager.toasts.length && this.state.toastId) {
                this.props.toastManager.remove(this.state.toastId)
            }
        }
    }

    // const scrollToAnchor = () => {
    componentWillUnmount() {
        //sp to handle case of ios, since ios doesn't recognize mousedown events
        document.removeEventListener('touchstart', this.handleClick, false)
        document.removeEventListener('mousedown', this.handleClick, false)
    }

    handleClick = e => {
        if (this.node && this.node.contains(e.target)) {
            if (e.keyCode && e.keyCode === 27) {
                this.setState({
                    showSubmenu: false,
                    showLinksSubmenu: false
                })
                return
            }

        } else if (this.state.showLinksSubmenu || this.state.showSubmenu) {
            this.setState({
                showLinksSubmenu: false,
                showSubmenu: false
            })
        }
    }
    updateSelectedAccount = (selectedAccountNumber) => {
        this.props.history.push({
            pathname: `/${selectedAccountNumber}`,
            state: {
                refreshSoState: true,
                accountNumber: selectedAccountNumber
            }
        })
        this.onAccountClickHandler(selectedAccountNumber)
    }

    updateDropdownList = accounts => {
        if (!accounts) return []
        const options = accounts.map(e => {
            return {label: e.Name?.toLowerCase(), value: e.Number}
        })
        return options
    }

    updateAccountList = (accounts, isFav) => {
        if (!accounts || !accounts.length) return
        const url = this.props.location.pathname
        return (
            <ul className="links pa-0 pl-16 pt-8 ma-0">
                {accounts.map((item, key) => {

                    //get current URL


                    //urlExplode
                    let tempUrl = url.split('/')
                    //strip account number from URL
                    const newAccount = tempUrl[1]
                    const newUrl = tempUrl
                    newUrl[1] = item.value

                    //find out new URL by checking exemptedURLs
                    let urlSwitch = (exemptedURLs.includes(newAccount)) ? `/${item.value}` : newUrl.join('/')
                    //todo move this into helper
                    const NotSwitchURLs = [
                        {
                            "url": ["safetyformtemplate"],
                            "parentUrl": 'safetyformtemplates',
                        },
                        {
                            "url": ["networkformtemplate"],
                            "parentUrl": 'networkformtemplates',
                        },
                        {
                            "url": ["safetyformbulk"],
                            "parentUrl": 'safetyformbulk',
                        },
                        {
                            "url": ["safetyform"],
                            "parentUrl": 'safetyform',
                        },
                        {
                            "url": ["networkformbulk"],
                            "parentUrl": 'networkformbulk',
                        },
                        {
                            "url": ["networkform"],
                            "parentUrl": 'networkform',
                        },
                        {
                            "url": ["assets"],
                            "parentUrl": 'assets',
                        },
                        {
                            "url": ["orders", "consumables", "request"],
                            "parentUrl": 'orders/consumables/request',
                        },
                        {
                            "url": ["orders", "consumables"],
                            "parentUrl": 'orders/consumables',
                        },
                        {
                            "url": ["orders", "assets"],
                            "parentUrl": 'orders/consumables',
                        },
                        {
                            "url": ["service", "orders", "request"],
                            "parentUrl": 'service/orders/request',
                        },
                        {
                            "url": ["service", "orders"],
                            "parentUrl": 'service/orders',
                        },
                    ];

                    NotSwitchURLs.map(pathSetting => {
                        for (let i = 0; i < pathSetting.url.length; i++) {
                            if (pathSetting.url[i] !== newUrl[i + 2]) {
                                return false;
                            } else if (pathSetting.url.length - 1 === i) {
                                urlSwitch = `/${item.value}/${pathSetting.parentUrl}`
                            }
                        }
                    })

                    const name = item.label || item.Name

                    return name.indexOf('**') < 0 ? (
                        <li key={key} title={`(${item.value}) ${item.label}`}>
                            <Link
                                to={urlSwitch}
                                className="link posn__rel fs-small text-capitalize"
                                onClick={() => {
                                    this.onAccountClickHandler(
                                        item.value,
                                        item.label
                                    )
                                }
                                }
                            >
                                {isFav && (
                                    <i className="fal fa-bookmark mr-8 fs-tiny"/>
                                )}
                                {name?.toLowerCase()}
                            </Link>
                        </li>
                    ) : null
                })}
            </ul>
        )
    }

    onSearchInputChange = searchTerm => {
        const targetTerm = searchTerm.target.value.replace(
            /[^\x20-\x7E]/gim,
            ''
        )

        this.setState(
            {
                searchTerm: targetTerm,
                pageNo: 1
            },
            () => {
                this.search()
            }
        )
    }

    search = () => {
        this.searchAccounts()
    }

    searchAccounts() {

        //MH - avoiding redux, using api call to issue with conflicting reducers till we can sort that issue out
        const pageNo = 'pageNo=1'
        const pageSize = '&pageSize=10'
        const search = this.state.searchTerm
            ? '&search=' + this.state.searchTerm
            : ''
        const tempUrl = `${config.accountsApi}?${pageNo}${pageSize}${search}`
        const url = window.isPROD ? tempUrl.replace('uat', '') : tempUrl
        //clear accountList
        this.setState({accountList: []})

        if (window.DEBUG) console.time(url)
        return axios
            .get(url)
            .then(response => {
                if (window.DEBUG) console.timeEnd(url)

                if (response.data) {
                    if (response.data.Error && response.data.Error !== '')
                        return []
                    if (
                        response.data.Payload &&
                        response.data.Payload.Account &&
                        response.data.Payload.Account.Data
                    )
                        this.setState({
                            accountList: response.data.Payload.Account.Data,
                            accountListMax: Math.max(
                                this.props.memberAccount
                                    ? this.props.memberAccount.accounts.length
                                    : 0 || 0,
                                this.props.totalResults || 0,
                                this.state.accountListMax
                            )
                        })
                }
            })
            .catch(error => {
                if (window.DEBUG) console.timeEnd(url)
                // need to do something here when get 403 error. this means the api end point is forbidden (not auth)

                // )
            })
    }

    onAccountClickHandler(accountNum, accountName) {
        this.setState({
            showSubmenu: false
        })
        if (accountName) {
            setAccountFavs(accountNum, accountName)
            this.props.toastManager.add(
                `Retreiving ${accountName}`,
                {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3250
                    // placement: 'bottom-center'
                },
                toastId => {
                    this.setState({toastId})
                }
            )
        }
    }

    toggleLinksSubmenu = menu => {
        const toggle = !!!this.state.linksSubmenus[menu]
        this.setState({
            linksSubmenus: {...this.state.linksSubmenus, [menu]: toggle}
        })
    }
    handleAccountMenuClick = () => {
        this.setState({
            showSubmenu: !this.state.showSubmenu,
            showLinksSubmenu: false
        })
        if (!fetchAccountList) {
            //one off to get the initial accounts listing
            fetchAccountList = true
            this.searchAccounts()
        }
        this.searchInput && this.searchInput.focus()
    }
    handleLinksHamburgerMenuClick = () => {
        this.setState({
            showSubmenu: false,
            showLinksSubmenu: !this.state.showLinksSubmenu
        })
    }


    render() {
        const {selectedAccount, accountDetails, accountList} = this.props
        const tempList = accountList || []
        const options = this.state.accountList
            ? this.updateDropdownList(this.state.accountList)
            : tempList
        const listAccounts = this.updateAccountList(options)
        return (
            <header className="mcb__header-v2">
                <div className="section-layout-1200 d-flex-jsb d-flex-aic">
                    <div className="d-flex-aic">
                        <div className="logo mr-16">
                            <img
                                src="/assets/img/canonlogo.svg"
                                role="presentation"
                                alt="Canon"
                            />
                        </div>
                        {isLoggedIn() && (
                            <div className="home">
                                <Link
                                    to={`/${this.state.accountNum}`}
                                    className="home-click"
                                ><i className="fal fa-home mr-8"/>MyCanon Business</Link>
                            </div>
                        )}
                    </div>
                    <div
                        className="d-flex-aic"
                        ref={node => (this.node = node)}
                    >
                        {!isLoggedIn() && (
                            <button className="login" onClick={() => login()}>
                                {this.props.isAuthenticating ||
                                this.props.isMemberInfoLoading ? (
                                    <Fragment>
                                            <span className="text-capitalize">
                                                {' '}
                                                Loading{' '}
                                            </span>{' '}
                                        <i className="fal fa-circle-notch ml-8"/>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <i className="fal fa-sign-in mr-8"/>Login
                                    </Fragment>
                                )}
                            </button>
                        )}

                        {isLoggedIn() && (
                            <div
                                className={`account-menu posn__rel mr-16 ${this.state.showSubmenu ? 'active' : ''
                                }`}
                            >
                                <a
                                    role="button"
                                    tabIndex="0"
                                    className="home-click account-click d-flex-aic curs-p"
                                    onClick={() => {
                                        this.handleAccountMenuClick(true)
                                    }}
                                    onKeyPress={() => {
                                        this.handleAccountMenuClick(true)
                                    }}
                                >
                                    {this.state.memberAccount &&
                                        !this.props.isAccountDetailsLoading && (
                                            <Fragment>
                                                <span>
                                                    {' '}
                                                    {this.state.memberAccount.company}{' '}
                                                </span>
                                                <i className="fal fa-user ml-8"/>
                                            </Fragment>
                                        )}
                                    {this.props.isAccountDetailsLoading && (
                                        <Fragment>
                                            <span className="text-capitalize">
                                                {' '}
                                                Retreiving{' '}
                                                {this.state.accountNum}{' '}
                                            </span>
                                            <i className="fal fa-circle-notch ml-8"/>
                                        </Fragment>
                                    )}
                                </a>
                                <div className="posn__abs dropdown">
                                    <ul className="ma-0 pa-16 fs-small">
                                        <li
                                            className={`canon-contact ${this.state.showSubmenu1
                                                ? 'active'
                                                : ''
                                            }`}
                                        >
                                            <a
                                                role="button"
                                                tabIndex="0"
                                                className="fs-base subhead"
                                                onKeyPress={() => {
                                                    this.setState({
                                                        showSubmenu1: !this
                                                            .state.showSubmenu1
                                                    })
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        showSubmenu1: !this
                                                            .state.showSubmenu1
                                                    })
                                                }}
                                            >
                                                <i className="fal fa-chevron-down mr-8 fs-vsmall"/>Company
                                            </a>
                                            <div className="submenu1">
                                                {this.state.accountListMax ===
                                                    1 && (
                                                        <div className="company fs-base text-capitalize py-8 pl-16">
                                                            {this.state
                                                                    .memberAccount &&
                                                                this.state?.memberAccount?.company?.toLowerCase()}
                                                        </div>
                                                    )}
                                                {this.state.accountListMax >
                                                    1 &&
                                                    this.state.accountListMax <
                                                    11 &&
                                                    !this.state.searchTerm && (
                                                        <div
                                                            className="account-selector py-8 pl-16"
                                                            id="account-selector"
                                                        >
                                                            <AccountSelect
                                                                id="state-select"
                                                                ref={ref => {
                                                                    this.select = ref
                                                                }}
                                                                onBlurResetsInput={
                                                                    false
                                                                }
                                                                onSelectResetsInput={
                                                                    false
                                                                }
                                                                autoFocus
                                                                options={
                                                                    options
                                                                }
                                                                simpleValue
                                                                clearable={
                                                                    false
                                                                }
                                                                name="selected-state"
                                                                disabled={false}
                                                                value={
                                                                    selectedAccount
                                                                }
                                                                onChange={
                                                                    this
                                                                        .updateSelectedAccount
                                                                }
                                                                searchable={
                                                                    false
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                {(this.state.accountListMax >
                                                    10 ||
                                                    this.state.searchTerm) && (
                                                    <div className="multi-account pl-16">
                                                        <div className="search-container pt-16">
                                                            <input
                                                                type="search"
                                                                ref={input => {
                                                                    this.searchInput = input
                                                                }}
                                                                className="fs-base pr-8"
                                                                placeholder="search"
                                                                pattern="^[a-zA-Z]*$"
                                                                onChange={
                                                                    this
                                                                        .onSearchInputChange
                                                                }
                                                                value={
                                                                    this.state
                                                                        .searchTerm
                                                                }
                                                            />
                                                        </div>
                                                        {listAccounts}
                                                    </div>
                                                )}
                                            </div>
                                        </li>
                                        <li
                                            className={`canon-contact pt-16 ${this.state.showSubmenu2
                                                ? 'active'
                                                : ''
                                            }`}
                                        >
                                            <a
                                                role="button"
                                                tabIndex="0"
                                                className="fs-base subhead"
                                                onKeyPress={() => {
                                                    this.setState({
                                                        showSubmenu2: !this
                                                            .state.showSubmenu2
                                                    })
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        showSubmenu2: !this
                                                            .state.showSubmenu2
                                                    })
                                                }}
                                            >
                                                <i className="fal fa-chevron-down mr-8 fs-vsmall"/>User
                                                Profile
                                            </a>
                                            <div className="submenu">
                                                <ul className="pa-0 pl-16 pt-8">
                                                    <li>
                                                        <i className="fal fa-briefcase mr-8"/>
                                                        {this.props
                                                                .accountRole &&
                                                            `${this.props
                                                                .accountRole
                                                            }`}
                                                    </li>
                                                    <li>
                                                        <i className="fal fa-user mr-8"/>
                                                        {this.state.memberAccount &&
                                                            `${this.state.memberAccount?.member?.FirstName} ${this.state.memberAccount?.member?.LastName}`}
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li
                                            className={`canon-contact ${this.state.showSubmenu3
                                                ? 'active'
                                                : ''
                                            }`}
                                        >
                                            <div className="divider pb-16 mt-16"/>
                                            <a
                                                role="button"
                                                tabIndex="0"
                                                className="fs-base subhead"
                                                onKeyPress={() => {
                                                    this.setState({
                                                        showSubmenu3: !this
                                                            .state.showSubmenu3
                                                    })
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        showSubmenu3: !this
                                                            .state.showSubmenu3
                                                    })
                                                }}
                                            >
                                                <i className="fal fa-chevron-down mr-8 fs-vsmall"/>
                                                {accountDetails &&
                                                    (accountDetails.VarCustomer
                                                        ? 'Account Contact'
                                                        : 'Account Contact')}
                                            </a>
                                            <div className="submenu">
                                                <ul className="pa-0 pl-16 pt-8">
                                                    <li>
                                                        <i className="fal fa-briefcase mr-8"/>
                                                        {accountDetails &&
                                                            (accountDetails.VarCustomer
                                                                ? `Account Name`
                                                                : `Account Manager`)}
                                                    </li>
                                                    <li>
                                                        <i className="fal fa-user mr-8"/>
                                                        {accountDetails &&
                                                            (accountDetails.VarCustomer
                                                                ? `${accountDetails
                                                                    .AccountManager
                                                                    .FirstName
                                                                }`
                                                                : `${accountDetails
                                                                    .AccountManager
                                                                    .FirstName
                                                                } ${accountDetails
                                                                    .AccountManager
                                                                    .LastName
                                                                }`)}
                                                    </li>
                                                    <li>
                                                        <i className="fal fa-phone mr-8"/>
                                                        {accountDetails &&
                                                            accountDetails
                                                                .AccountManager
                                                                .Phone &&
                                                            `${accountDetails
                                                                .AccountManager
                                                                .Phone
                                                            }`}
                                                    </li>
                                                    <li>
                                                        <i className="fal fa-phone mr-8"/>
                                                        {accountDetails &&
                                                            accountDetails
                                                                .AccountManager
                                                                .Mobile && (
                                                                <span>{`${accountDetails
                                                                    .AccountManager
                                                                    .Mobile
                                                                }`}</span>
                                                            )}
                                                    </li>
                                                    <li>
                                                        <i className="fal fa-envelope mr-8"/>
                                                        {accountDetails &&
                                                            accountDetails
                                                                .AccountManager
                                                                .Email && (
                                                                <a
                                                                    className="email-link fs-small"
                                                                    href={`mailto:${accountDetails
                                                                        .AccountManager
                                                                        .Email
                                                                    }`}
                                                                    title={`${accountDetails
                                                                        .AccountManager
                                                                        .Email
                                                                    }`}
                                                                >
                                                                    <span>{`${accountDetails
                                                                        .AccountManager
                                                                        .Email
                                                                    }`}</span>
                                                                </a>
                                                            )}
                                                    </li>
                                                    {accountDetails &&
                                                        accountDetails &&
                                                        accountDetails.VarCustomer && (
                                                            <li>
                                                                <i className="fal fa-location-arrow mr-8"/>
                                                                {accountDetails &&
                                                                    accountDetails
                                                                        .AccountManager
                                                                        .Website && (
                                                                        <a
                                                                            className="email-link fs-small"
                                                                            href={`${accountDetails
                                                                                .AccountManager
                                                                                .Website
                                                                            }`}
                                                                            title={`${accountDetails
                                                                                .AccountManager
                                                                                .Website
                                                                            }`}
                                                                            target="_blank"
                                                                        >
                                                                            <span
                                                                            >{`${accountDetails
                                                                                .AccountManager
                                                                                .Website
                                                                            }`}</span>
                                                                        </a>
                                                                    )}
                                                            </li>
                                                        )}

                                                    {accountDetails &&
                                                        accountDetails.csm && (
                                                            <Fragment>
                                                                <li
                                                                    style={{
                                                                        borderTop:
                                                                            '1px solid #000'
                                                                    }}
                                                                    className="mt-8 pt-8"
                                                                >
                                                                    <i className="fal fa-briefcase mr-8"/>Client
                                                                    Service
                                                                    Manager
                                                                </li>
                                                                <li>
                                                                    <i className="fal fa-user mr-8"/>
                                                                    {accountDetails &&
                                                                        `${accountDetails
                                                                            .csm
                                                                            .FirstName
                                                                        } ${accountDetails
                                                                            .csm
                                                                            .LastName
                                                                        }`}
                                                                </li>
                                                                <li>
                                                                    <i className="fal fa-phone mr-8"/>
                                                                    {accountDetails &&
                                                                        accountDetails
                                                                            .csm
                                                                            .Phone &&
                                                                        `${accountDetails
                                                                            .csm
                                                                            .Phone
                                                                        }`}
                                                                </li>
                                                                <li>
                                                                    <i className="fal fa-phone mr-8"/>
                                                                    {accountDetails &&
                                                                        accountDetails
                                                                            .csm
                                                                            .Mobile && (
                                                                            <span
                                                                            >{`${accountDetails
                                                                                .csm
                                                                                .Mobile
                                                                            }`}</span>
                                                                        )}
                                                                </li>
                                                                <li>
                                                                    <i className="fal fa-envelope mr-8"/>
                                                                    {accountDetails &&
                                                                        accountDetails
                                                                            .csm
                                                                            .Email && (
                                                                            <a
                                                                                className="email-link fs-small"
                                                                                href={`mailto:${accountDetails
                                                                                    .csm
                                                                                    .Email
                                                                                }`}
                                                                                title={`${accountDetails
                                                                                    .csm
                                                                                    .Email
                                                                                }`}
                                                                            >
                                                                                <span
                                                                                >{`${accountDetails
                                                                                    .csm
                                                                                    .Email
                                                                                }`}</span>
                                                                            </a>
                                                                        )}
                                                                </li>
                                                            </Fragment>
                                                        )}
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {isLoggedIn() && (
                            <HamburgerMenu
                                handleLinksHamburgerMenuClick={() =>
                                    this.handleLinksHamburgerMenuClick()
                                }
                                showLinksSubmenu={this.state.showLinksSubmenu}
                                linksSubmenus={this.state.linksSubmenus}
                                toggleLinksSubmenu={title =>
                                    this.toggleLinksSubmenu(title)
                                }
                                accountNum={this.state.accountNum}
                            />
                        )}
                    </div>
                </div>
            </header>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const isAccountDetailsLoading = state.canonhub.accountDetails.loading
    const isMemberInfoLoading = state?.canonhub?.memberInfo.loading
    const isAuthenticating = state.auth.isAuthenticating
    const authAccessToken = state.auth.auth ? state.auth.auth.accessToken : ''

    const totalResults =
        state.canonhub.account && state.canonhub.account.data
            ? state.canonhub.account.data.TotalResults
            : 1
    const totalPages =
        state.canonhub.account && state.canonhub.account.data
            ? state.canonhub.account.data.TotalPages
            : 1
    const hubError = state?.canonhub?.account?.hubError


    const tempAccountInfo = sessionStorage.getItem(ACCOUNT_INFO)
    const accountInfo = state.canonhub.account?.Data || JSON.parse(tempAccountInfo)

    return {
        isAccountDetailsLoading,
        isMemberInfoLoading,
        isAuthenticating,
        memberAccount:
            state?.canonhub?.accountDetails?.data
                ? {
                    company: state.canonhub.accountDetails.data.Name,
                    accounts: accountInfo || [],
                    member: state.canonhub?.memberInfo?.data,
                }
                : null,
        accountDetails: state.canonhub.accountDetails
            ? state.canonhub.accountDetails.data
            : '',
        accountRole: state?.canonhub?.accountAccess?.data?.Role,
        accountList: accountInfo || [],
        totalResults,
        totalPages,
        authAccessToken,
        selectedAccount: state.account.selectedAccount
            ? state.account.selectedAccount.selectedAccount
                ? state.account.selectedAccount.selectedAccount
                : state.account.selectedAccount
            : '',
        hubError,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSelectedAccount: payload =>
            dispatch(actionCreators.setSelectedAccount(payload)),
        updateCanonHub: payload =>
            dispatch(actionCreators.getCanonHub(payload)),
        getAccountDetails: payload =>
            dispatch(actionCreators.getAccountDetails(payload))
    }
}
export default withToastManager(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Header)
)
