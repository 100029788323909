import config from './data.json'
import {
    getPermissions,
    PERMISSIONS_ROUTES_ONLY,
    PERMISSIONS_SECTIONS_ONLY
} from './acl'
import MetaConfiguration from './meta-configuration'
import AppConfigApi from './AppConfigApi'

const metadata = config.metadata
export {
    PERMISSIONS_ROUTES_ONLY,
    PERMISSIONS_SECTIONS_ONLY,
    metadata,
    getPermissions,
    MetaConfiguration,
    AppConfigApi
}
