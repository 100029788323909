/**
 * Canon
 *
 * @package     mcb-front-end
 * @project     Ignite
 * @desc        Reducers for the CanonHub API
 *
 */
import * as actionTypes from '../actions/actionTypes.js'
import {ACCOUNT_INFO, MEMBER_INFO} from '../../auth/constants'

const initialState = {
    account: {
        lastFetch: 0,
        loading: false
    },
    accountList: {
        lastFetch: 0,
        loading: false
    },
    accountDetails: {
        lastFetch: 0,
        loading: false
    },
    accountAccess: {
        lastFetch: 0,
        loading: false
    },

    memberInfo: {
        lastFetch: 0,
        loading: false
    },
    selectedAccount: '',

    tech: {
        lastFetch: 0,
        loading: false
    }
}

const canonHub = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ACCOUNTS:

            if (action.data?.data?.Data) {
                const tempAccountInfo = JSON.stringify(action.data.data.Data)
                sessionStorage.setItem(ACCOUNT_INFO, tempAccountInfo)
            }

            return {
                ...state,
                account: action.data
            }

        case actionTypes.CLEAR_ACCOUNTS:
            return {
                ...state,
                account: {lastFetch: 0}
            }

        case actionTypes.STORE_SELECTED_ACCOUNT:
            return {
                ...state,
                storedAccountNumber: action.data
            }

        case actionTypes.UPDATE_HEADER_ACCOUNT_LIST_REQUEST:
            return {
                ...state,
                accountList: action.data
            }
        case actionTypes.UPDATE_HEADER_ACCOUNT_LIST_SUCCESS:
            return {
                ...state,
                accountList: action.data
            }
        case actionTypes.UPDATE_HEADER_ACCOUNT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                lastError: Date.now()
            }
        case actionTypes.UPDATE_ACCOUNTS_DETAILS:
            return {
                ...state,
                accountDetails: action.data,
            }
        case actionTypes.UPDATE_ACCOUNTS_ACCESS:
            return {
                ...state,
                accountAccess: action.data,
            }

        case actionTypes.UPDATE_SELECTED_SERVICEORDER:
            return {
                ...state,
                deviceServiceOrders: action.data
            }

        case actionTypes.UPDATE_MEMBER_INFO:
            const tempMemberInfo = JSON.stringify(action.data)
            //todo removesession storage
            sessionStorage.setItem(MEMBER_INFO, tempMemberInfo)
            return {
                ...state,
                memberInfo: action.data
            }

        case actionTypes.ABORT_FETCH_HUB:
            return state
        default: {
            return state
        }
    }
}

export default canonHub
