import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import { withToastManager } from 'react-toast-notifications'
import {ISNZ} from '../../../auth/constants'

import './footer.css'

const d = new Date()
const year = d.getFullYear()

const FooterAU = ({accountDetails}) =>(
		<Fragment>
			<div className="section-layout-1200 d-flex">
				<div className="col-xs-3">
					<ul className="list">
						<li>Canon Australia</li>
						<li>
							<a
								href="https://www.canon.com.au/about-canon"
								target="_blank"
								rel="noopener noreferrer"
							>
								About Canon
							</a>{' '}
						</li>
						<li>
							<a
								href="https://www.canon.com.au/about-canon/corporate-profile"
								target="_blank"
								rel="noopener noreferrer"
							>
								Corporate Profile
							</a>{' '}
						</li>
						<li>
							<a
								href="https://www.canon.com.au/about-canon/sustainability"
								target="_blank"
								rel="noopener noreferrer"
							>
								Sustainability
							</a>
						</li>
						<li>
							<a
								href="https://www.canon.com.au/about-canon/compliance"
								target="_blank"
								rel="noopener noreferrer"
							>
								Compliance
							</a>
						</li>
						<li>
							<a
								href="https://www.canon.com.au/about-canon/community"
								target="_blank"
								rel="noopener noreferrer"
							>
								Canon in the Community
							</a>{' '}
						</li>
					</ul>
				</div>
				<div className="col-xs-3">
					<ul className="list">
						<li>Business Support</li>
						{accountDetails && !accountDetails.csm && [
							<li key='drivers'>
								<a
									href="https://www.canon.com.au/business/support"
									target="_blank"
									rel="noopener noreferrer"
								>
									Drivers and downloads
								</a>
							</li>,
							<li key='register'>
								<a
									href="https://www.canon.com.au/business/support/business-product-registration-form"
									target="_blank"
									rel="noopener noreferrer"
								>
									Register your product
								</a>
							</li>]}
						<li>
							<a
								href="https://www.canon.com.au/business/support/safety-and-compliance"
								target="_blank"
								rel="noopener noreferrer"
							>
								Safety data sheets
							</a>
						</li>
						{accountDetails && !accountDetails.csm && (
							<li>
								<a
									href="https://www.canon.com.au/business/end-user-licence-agreements"
									target="_blank"
									rel="noopener noreferrer"
								>
									End user licence agreements
								</a>
							</li>)}
					</ul>
				</div>
				<div className="col-xs-3">
					<ul className="list">
						<li>Contact Canon Australia</li>
						<li>
							<a href="https://www.canon.com.au/business" target="_blank" rel="noopener noreferrer">
								Business
							</a>
						</li>
						<li>
							<a
								href="https://www.canon.com.au/about-canon/contact-us"
								target="_blank"
								rel="noopener noreferrer"
							>
								General Inquiry
							</a>
						</li>
						<li>
							<a
								href="https://www.canon.com.au/about-canon/contact-us"
								target="_blank"
								rel="noopener noreferrer"
							>
								PR (Media)
							</a>
						</li>
					</ul>
				</div>
				<div className="col-xs-3">
					<ul className="list">
						<li>Other Sites</li>
						<li>
							<a href="http://www.harbourit.com.au/" target="_blank" rel="noopener noreferrer">
								Harbour IT
							</a>
						</li>
						<li>
							<a href="http://www.converga.com.au/" target="_blank" rel="noopener noreferrer">
								Converga
							</a>
						</li>
						<li>
							<a href="https://sunstudiosaustralia.com/" target="_blank" rel="noopener noreferrer">
								Sunstudios
							</a>
						</li>
					</ul>
				</div>
			</div>
			<br />
			<div className="section-layout-1200 d-flex">
				<div className="col-xs-3">
					<ul className="list">
						<li>{`v${process.env.REACT_APP_VERSION}`}</li>
					</ul>
				</div>
				<div className="col-xs-3">
					<ul className="list">
						<li>
							<a href="https://www.canon.com.au/privacy-policy">Privacy Policy</a>
						</li>
					</ul>
				</div>
				<div className="col-xs-3">
					<ul className="list">
						<li>
							<a href="https://www.canon.com.au/support/mycanon-business/mycanon-business-terms-of-use">
								Terms of Use
							</a>
						</li>
					</ul>
				</div>
				<div className="col-xs-3">
					<ul className="list">
						<li>
							<a>{`© Copyright ${year} Canon Australia Pty Ltd`}</a>
						</li>
					</ul>
				</div>
			</div>
		</Fragment>
)


const FooterNZ = ({accountDetails}) => (
	<Fragment>
		<div className="section-layout-1200 d-flex">
			<div className="col-xs-3">
				<ul className="list">
					<li>Canon New Zealand</li>
					<li>
						<a
							href="https://www.canon.co.nz/about-canon"
							target="_blank"
							rel="noopener noreferrer"
						>
							About Canon
						</a>{' '}
					</li>
					<li>
						<a
							href="https://www.canon.co.nz/about-canon/corporate-profile"
							target="_blank"
							rel="noopener noreferrer"
						>
							Corporate Profile
						</a>{' '}
					</li>
					<li>
						<a
							href="https://www.canon.co.nz/about-canon/sustainability"
							target="_blank"
							rel="noopener noreferrer"
						>
							Sustainability
						</a>
					</li>
					<li>
						<a
							href="https://www.canon.co.nz/about-canon/compliance"
							target="_blank"
							rel="noopener noreferrer"
						>
							Compliance
						</a>
					</li>
					<li>
						<a
							href="https://www.canon.co.nz/about-canon/community"
							target="_blank"
							rel="noopener noreferrer"
						>
							Canon in the Community
						</a>{' '}
					</li>
				</ul>
			</div>
			<div className="col-xs-3">
				<ul className="list">
					<li>Business Support</li>
					{accountDetails && !accountDetails.csm && [
						<li key='drivers'>
							<a
								href="https://www.canon.co.nz/business/support"
								target="_blank"
								rel="noopener noreferrer"
							>
								Drivers and downloads
							</a>
						</li>]}
					<li>
						<a
							href="https://www.canon.co.nz/business/support/safety-and-compliance"
							target="_blank"
							rel="noopener noreferrer"
						>
							Safety data sheets
						</a>
					</li>
					{accountDetails && !accountDetails.csm && (
						<li>
							<a
								href="https://www.canon.co.nz/business/end-user-licence-agreements"
								target="_blank"
								rel="noopener noreferrer"
							>
								End user licence agreements
							</a>
						</li>)}
				</ul>
			</div>
			<div className="col-xs-3">
				<ul className="list">
					<li>Contact Canon New Zealand</li>
					<li>
						<a href="https://www.canon.co.nz/business" target="_blank" rel="noopener noreferrer">
							Business
						</a>
					</li>
					<li>
						<a
							href="https://www.canon.co.nz/about-canon/contact-us"
							target="_blank"
							rel="noopener noreferrer"
						>
							General Inquiry
						</a>
					</li>
					<li>
						<a
							href="https://www.canon.co.nz/about-canon/contact-us"
							target="_blank"
							rel="noopener noreferrer"
						>
							PR (Media)
						</a>
					</li>
					<li>

					</li>
				</ul>
			</div>
			<div className="col-xs-3">
				<ul className="list">
					<li>Other Sites</li>
					<li>
						<a href="https://shop.canon.co.nz/" target="_blank" rel="noopener noreferrer">
							Canon Store
						</a>
					</li>

					<li>
						<a href="http://www.converga.co.nz/" target="_blank" rel="noopener noreferrer">
							Converga
						</a>
					</li>

				</ul>
			</div>
		</div>
		<br />
		<div className="section-layout-1200 d-flex">
			<div className="col-xs-3">
				<ul className="list">
					<li>{`v${process.env.REACT_APP_VERSION}`}</li>
				</ul>
			</div>
			<div className="col-xs-3">
				<ul className="list">
					<li>
						<a href="https://www.canon.co.nz/privacy-policy">Privacy Policy</a>
					</li>
				</ul>
			</div>
			<div className="col-xs-3">
				<ul className="list">
					<li>
						<a href="https://www.canon.co.nz/support/mycanon-business/mycanon-business-terms-of-use">
							Terms of Use
						</a>
					</li>
				</ul>
			</div>
			<div className="col-xs-3">
				<ul className="list">
					<li>
						<a>{`© Copyright ${year} Canon New Zealand Pty Ltd`}</a>
					</li>
				</ul>
			</div>
		</div>
	</Fragment>
)

const Footer = props => {
	const {accountDetails,Localization} = props

	return (
		<footer className="mcb__footer" name="mcbfooter">
			{(ISNZ) && (
				<FooterNZ accountDetails={accountDetails}/>
			)}
			{(!ISNZ) && (
				<FooterAU accountDetails={accountDetails} />
			)}
		</footer>
	)
}

const mapStateToProps = (state) => {

    return {
        accountDetails: state.canonhub.accountDetails
            ? state.canonhub.accountDetails.data
            : '',
		Localization: state.canonhub.accountDetails
			? state.canonhub.accountDetails.Localization
			: '',
    }
}
export default withToastManager(
    connect(
        mapStateToProps
    )(Footer)
)
