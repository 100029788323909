import React from 'react'
import { Route } from 'react-router-dom'

import { isLoggedIn, canCallBack, login } from 'auth'
import Loading from 'components/common/loading'
import { compose, lifecycle } from 'recompose'
import { Permissions } from 'utils/acl-permissions'
//import { getPermissions, PERMISSIONS_ROUTES_ONLY } from 'app-config'
import { GenericError } from 'components/error-pages'
import ErrorCodes from 'utils/errorCodes'

const getQueryVariable = variable => {
    var query = window.location.search.substring(1)
    // console.log(query) //"app=article&act=news_content&aid=160990"
    var vars = query.split('&')
    // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        // console.log(pair) //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
        if (pair[0] == variable) {
            return pair[1]
        }
    }
    return ''
}

const withReloginData = lifecycle({
    componentDidMount() {
        //If we are hitting the relogin component, persist the path of the route in the localstorage using the canCallBack and attempt a relogin.

        //persist the route to the local storage so that the SSO component can pick it up and redirect after sucessul authentication
        canCallBack()

        const idp = getQueryVariable('idp')
        //relogin since the token has expired or not logged in
        login(idp)
    }
})

const enhance = compose(withReloginData)

const ReLogin = enhance(props => (
    <div className="device section-layout-1200">
        <Loading
            center
            size="40px"
            thickness="1px"
            paddingAround="200px"
            message="Logging in"
        />
    </div>
))

//A Decorator/ wrapper Route component that adds the additional functionality to check and
// reauthenticate the corresponding route children if not already authenticated
const PrivateRoute = ({ component: Component, ...rest }) => {

    return (
        <Permissions
            routeOrSectionPermissions={rest.permissions}
            fallbackElement={
                <GenericError errorObj={ErrorCodes.ACCESS_DENIED} />
            }
        >
            <Route
                {...rest}
                render={props =>
                    isLoggedIn() ? (
                        <Component {...props} />
                    ) : (
                        <ReLogin {...props} />
                    )
                }
            />
        </Permissions>
    )
}

export default PrivateRoute
