import React from 'react'
import { lighten as lightenColor, darken as darkenColor } from 'polished'

// Pass in a know color variant from the theme provider
export const color = variant => props => props.theme.color[variant] || variant

// Pass in a prop to select a color variant from the theme provider
export const colorFromProp = prop => props =>
    props.theme.color[props[prop]] || props[prop]

// Helper functions to use theme colors with polished methods

// NOTE: Hopefully polished will eventually handle transparent values so we don't have to check
const ifNotTransparent = (input, cb) =>
    input !== 'transparent' ? cb(input) : 'transparent'

export const lighten = (amount, variant) => props =>
    lightenColor(amount, props.theme.color[variant])

export const lightenFromProp = (amount, prop) => props =>
    ifNotTransparent(props[prop], v =>
        lightenColor(amount, props.theme.color[v] || v)
    )

export const darken = (amount, variant) => props =>
    darkenColor(amount, props.theme.color[variant])

export const darkenFromProp = (amount, prop) => props =>
    ifNotTransparent(props[prop], v =>
        darkenColor(amount, props.theme.color[v] || v)
    )

// Prevent PropType warning by removing unwanted props from a component
export const componentWithoutProps = (Component, ignoreProps = []) => props => {
    const filteredProps = { ...props }
    ignoreProps.forEach(prop => {
        if (prop in filteredProps) {
            delete filteredProps[prop]
        }
    })

    return <Component {...filteredProps} />
}
