import styled from 'styled-components'

// export const Container = styled.div`
//   background: rgba(178, 178, 178, 0.7);
//   text-align: center;
//   font-weight: 500;
//   transition: all 0.2s ease-in-out;
//   padding: 1rem;
//   &:hover {
//     transform: scale(1.1);
//   }
//   .fal {
//     font-size: 1.1rem;
//     font-weight: 500;
//   }
// `

export const BackToTopSection = styled.div`
    .ScrollUpButton__Toggled {
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }
`
