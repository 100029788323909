/**
 * Canon
 *
 * @package     mcb-front-end
 * @project     Ignite
 * @description Core actions to save the token payload
 *
 */

// -------------------------------------
//   Dependencies: core
// -------------------------------------
import * as actionTypes from './actionTypes'

/**
 * @name  setAuth
 * @desc  Set the stores auth details
 */
export const setAuth = payload => {

    return (dispatch, getState) => {
        if (getState().auth.auth) return null
        dispatch(saveAuth(payload))
    }
}
/**
 * @name  saveAuth
 * @desc  Dispatch the payload to the store
 */
export const saveAuth = payload => {

    return {
        type: actionTypes.UPDATE_AUTH,
        auth: payload
    }
}

/**
 * @name  isAuthenticating
 * @desc  Method to set the isAuthenticating state of "auth" redux state used by AuthService
 */
export const isAuthenticating = flag => {
    return (dispatch, getState) => {
        dispatch(() => ({
            type: actionTypes.IS_AUTHENTICATING,
            flag
        }))
    }
}
