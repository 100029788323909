import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { checkVisibility } from './core'

const AuthHOC = hocProps => (WrappedComponent, FallbackElement) => {
    class WithAuthorization extends Component {
        static propTypes = {
            userpermissions: PropTypes.array.isRequired
        }
        constructor(props) {
            super(props)

            this.state = {
                visible: false
            }
        }

        setVisibility = (componentPermissions, metadata) => {
            const { userpermissions } = this.props
            const { visible } = this.state
            const newVisibility = checkVisibility(
                userpermissions,
                componentPermissions
            )
            if (visible !== newVisibility) {
                this.setState({
                    visible: newVisibility
                })
            }
        }

        processVisibility = () => {
            const { metadata } = this.props
            let componentPermissions = []
            if (metadata) {
                componentPermissions = metadata.permissions
            } else {
                console.error(
                    'No id prop present for filtering of Auth Component'
                )
            }
            if (!componentPermissions || componentPermissions.length === 0) {
                this.setState({
                    visible: true
                })
            } else {
                this.setVisibility(componentPermissions, metadata)
            }
        }

        componentDidMount() {
            this.processVisibility()
        }

        componentDidUpdate(prevProps) {
            if (
                prevProps.userpermissions &&
                this.props.userpermissions &&
                prevProps.userpermissions.length === 0 &&
                this.props.userpermissions.length > 0
            ) {
                this.processVisibility()
            }
        }

        render() {
            const { visible } = this.state
            const { forwardedRef, ...rest } = this.props
            if (visible) {
                return <WrappedComponent ref={forwardedRef} {...rest} />
            }
            return typeof FallbackElement === 'function' ? (
                <FallbackElement />
            ) : (
                FallbackElement
            )
        }
    }

    const WithConnectedAuthorization = connect(
        state => {
            return {
                userpermissions: state.canonhub.accountAccess.data
                    ? state.canonhub.accountAccess.data.Permissions
                    : []
            }
        },
        null
    )(
        React.forwardRef((props, ref) => {
            return <WithAuthorization {...props} forwardedRef={ref} />
        })
    )

    const WithAuthorizationRefs = React.forwardRef((props, ref) => {
        return <WithConnectedAuthorization {...props} forwardedRef={ref} />
    })

    return WithAuthorizationRefs
}

export default AuthHOC
