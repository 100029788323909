import React, { Component } from 'react'
// import { TimelineMax } from 'gsap/umd/TweenMax'
//import TimelineMax from 'gsap/umd/TimelineMax'
import { gsap } from "gsap/dist/gsap"

class BubbleLoading extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.tl = gsap.timeline({ repeat: -1 })
        this.tl2 = gsap.timeline({ repeat: -1 })
    }

    componentDidMount() {
        this.tl
            .set('#c1', { autoAlpha: 0.7 })
            .to('#c1', 0.5, {
                scale: 0.2,
                x: '+=5',
                transformOrigin: '50% 50%'
            })
            .to('#c1', 0.5, { scale: 1, x: '-=5', transformOrigin: '50% 50%' })

        this.tl2
            .set('#c2', { autoAlpha: 0.7 })
            .to('#c2', 0.5, {
                scale: 0.2,
                x: '-=5',
                transformOrigin: '50% 50%'
            })
            .to('#c2', 0.5, { scale: 1, x: '+=5', transformOrigin: '50% 50%' })

        //split loading text
    }

    render() {
        return (
            <div>
                <svg
                    viewBox="0 0 16 16"
                    width="54px"
                    height="32px"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle id="c1" cx="8" cy="8" r="8" fill="#29B6F6" />
                    <circle id="c2" cx="12" cy="8" r="8" fill="#FF4081" />
                </svg>
            </div>
        )
    }
}

export default BubbleLoading
