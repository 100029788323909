import { addRoles } from 'utils/acl-permissions'
import { store } from '../redux/store.js'
import { PERMISSONS } from './constants'
export const addPermissions = roles => {
    store.dispatch(addRoles(roles))
    localStorage.setItem(PERMISSONS, JSON.stringify(roles))
}

export const clearPermissions = () => {
    localStorage.removeItem(PERMISSONS)
}
