import React from 'react'
import GenericError from './generic-error'
import ErrorCodes from 'utils/errorCodes'
import { withRouter } from 'react-router-dom'
//[sp] Important Notes : Once the state in the ErrorBoundary has been set it will always render the error message until the state changes (hasError to false).
// hence The child components (the Router component in this case) will not be rendered, even when the history changes.
//To resolve this, make use of the react-router withRouter higher order component, by wrapping the export of the ErrorBoundary to give it access to the history via the props

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false, errorObj: ErrorCodes.GENERIC }
        //In the ErrorBoundary constructor retrieve the history from the props and setup a handler to listen for changes to the current location using history.listen.
        //When the location changes (back button clicked etc.) if the component is in an error state, it is cleared enabling the children to be rendered again
        const { history } = props
        history.listen((location, action) => {
            if (this.state.hasError) {
                this.setState({
                    hasError: false
                })
            }
        })
    }

    componentDidCatch(error, info) {
        let errorObj = error.Type === 'UI' ? error : ErrorCodes.GENERIC

        // Display fallback UI
        this.setState({ hasError: true, errorObj })
    }
    // You can also log the error to an error reporting service
    //console.error(error, info)

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <GenericError errorObj={this.state.errorObj} />
        } else {
            return this.props.children
        }
    }
}

export default withRouter(ErrorBoundary)
