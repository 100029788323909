import {
    ISDEMO,
    ID_TOKEN_KEY,
    ACCESS_TOKEN_KEY,
    SUBJECT,
    EXPIRES_AT,
    CALLBACK,
    MEMBER_ID,
    SELECTED_ACCOUNT,
    ACCOUNT_FAVS,
    STORED_ACCOUNT
    // PERMISSONS
} from './constants'

import decode from 'jwt-decode'

import storage from 'localforage'
// ---------------------------------------------
//   Token handlers
// ---------------------------------------------
/**
 * @name clearIdToken
 * @desc
 */
export const clearIdToken = () => {
    localStorage.removeItem(ID_TOKEN_KEY)
}

export const clearSelectedAndFavAccounts = () => {
    localStorage.removeItem(SELECTED_ACCOUNT)
    localStorage.removeItem(ACCOUNT_FAVS)
}
/**
 * @name clearAccessToken
 * @desc
 */
export const clearAccessToken = exemptCallback => {
    localStorage.removeItem(ISDEMO)
    localStorage.removeItem(ACCESS_TOKEN_KEY)
    localStorage.removeItem(EXPIRES_AT)
    localStorage.removeItem(SUBJECT)

    //localStorage.removeItem(MEMBER_ID)
    //accept a flag and check if the callback localstorage needs to be removed or not
    if (!exemptCallback) localStorage.removeItem(CALLBACK)
    document.cookie.split('').forEach(function (c) {
        document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, '=expires=' + new Date().toUTCString() + 'path=/')
    })
    storage.clear()
}
/**
 * @name getParameterByName
 * @desc Helper function that will allow us to extract the access_token and id_token
 */
const getParameterByName = name => {
    let match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash)
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}
/**
 * @name setAccessToken
 * @desc Get and store access_token in local storage
 */
export const setAccessToken = t => {
    let accessToken = t || getParameterByName(ACCESS_TOKEN_KEY)
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
}
/**
 * @name setAccessToken
 * @desc Get and store access_token in local storage
 */
// Get and store id_token in local storage
export const setIdToken = () => {
    let idToken = getParameterByName(ID_TOKEN_KEY)
    localStorage.setItem(ID_TOKEN_KEY, idToken)
}
/**
 * @name getTokenExpirationTime
 * @desc
 */
const getTokenExpirationTime = encodedToken => {
    if (!encodedToken) return null
    const token = decode(encodedToken)
    if (!token.exp) {
        return null
    }
    if (
        token.exp < token.auth_time ||
        token.exp < token.iat ||
        token.exp < token.nbf
    )
        return null
    return token.exp
}
/**
 * @name isTokenExpired
 * @desc
 */
export const isTokenExpired = token => {
    if (!token) return true
    const expirationTime = getTokenExpirationTime(token)
    const expiryTime = Math.ceil(new Date().getTime() / 1000)
    return expirationTime < expiryTime
    //for testing purpose
    //return expirationTime < expiryTime + 3260
}
/**
 * @name getIdToken
 * @desc
 */
export const getIdToken = () => {
    return localStorage.getItem(ID_TOKEN_KEY)
}

/**
 * @name setSession
 * @desc Save auth data to local storage for later
 */
export const setSession = authResult => {
    let expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime()
    )

    localStorage.setItem(ACCESS_TOKEN_KEY, authResult.accessToken)
    localStorage.setItem(ID_TOKEN_KEY, authResult.idToken)
    localStorage.setItem(EXPIRES_AT, expiresAt)
    const idtoken = decode(authResult.idToken)
    localStorage.setItem(MEMBER_ID, idtoken.id)
    localStorage.setItem(SUBJECT, idtoken.sub)
}
