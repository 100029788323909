import * as actionTypes from '../actions/actionTypes.js'

const initialState = {
    searchOptions: {},
    pagingSettings: {}
}

const sel = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SEARCH_OPTIONS:
            return {
                ...state,
                searchOptions: action.searchOptions
            }
        case actionTypes.UPDATE_PAGING_SETTINGS:
            return {
                ...state,
                pagingSettings: action.pagingSettings
            }
        default: {
            return state
        }
    }
}

export default sel
