export const getLocalAccount = dfault => {
    const selectedAccount = localStorage.getItem('selectedAccount')
    const accountFavs = getAccountFavs('accountFavs')
    if (selectedAccount) return selectedAccount
    if (accountFavs && accountFavs.length) return accountFavs[0].Number
    return dfault
}
export const getAccountFavs = id => {
    const myFavs = localStorage.getItem(id)
    return JSON.parse(myFavs)
}
export const setAccountFavs = (accountNum, accountName) => {
    let myList = getAccountFavs('accountFavs') || []
    myList.unshift({ Number: accountNum, Name: accountName })
    localStorage.setItem('accountFavs', JSON.stringify(myList.slice(0, 5)))
}
export const getLocalStorage = id => {
    return localStorage.getItem(id)
}
export const setLocalStorage = (id, payload) => {
    localStorage.setItem(id)
}
