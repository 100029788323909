import moment from 'moment'

class DateUtils {
    static previousDate = (value, units, format) => {
        return moment()
            .add(value, units || 'days')
            .format(format || 'YYYY-MM-DD')
    }

    static isValid = checkDate => {
        return moment(checkDate).isValid()
    }

    static isValidBeforeToday = (dateFrom, dateTo) => {
        if (!DateUtils.isValid(dateFrom)) return false
        if (!DateUtils.isValid(dateTo)) return false
        const today = moment()
        if (!moment(dateFrom).isBefore(today)) return false
        // if (!moment(dateTo).isBefore(today)) return false
        if (!moment(dateFrom).isSameOrBefore(moment(dateTo))) return false
        return true
    }

    static isAustraliaHoliday = input => {


    }

    static isHoliday = input => {
        const locale = input.localeData()

        if (locale._holidays) {
            if (
                locale._holidays.indexOf(input.format(locale._holidayFormat)) >=
                0
            )
                return true
        }

        if (locale.holiday) {
            if (locale.holiday(input)) {
                return true
            }
            return false
        }

        return false
    }

    static isBusinessDay = input => {
        const locale = input.localeData()

        const defaultWorkingWeekdays = [1, 2, 3, 4, 5]
        //is always undefined?
        // console.log(locale._workingWeekdays)
        // const workingWeekdays = locale._workingWeekdays || defaultWorkingWeekdays
        //const workingWeekdays =  defaultWorkingWeekdays


        if (DateUtils.isHoliday(input)) return false
        //return workingWeekdays.indexOf(input.day()) >= 0
        return defaultWorkingWeekdays.indexOf(input.day()) >= 0

    }

    static businessDaysIntoMonth = input => {
        if (!input.isValid()) {
            return NaN
        }
        var businessDay = DateUtils.isBusinessDay(input)
            ? input
            : DateUtils.prevBusinessDay(input)
        var monthBusinessDays = DateUtils.monthBusinessDays(businessDay)
        var businessDaysIntoMonth
        // eslint-disable-next-line
        monthBusinessDays.map(function (day, index) {
            if (day.format('M/DD/YY') === businessDay.format('M/DD/YY')) {
                businessDaysIntoMonth = index + 1
            }
        })
        return businessDaysIntoMonth
    }

    static businessDiff = (input, param) => {
        var d1 = input.clone()
        var d2 = param.clone()
        var start = d1 < d2 ? d1 : d2
        var end = d2 > d1 ? d2 : d1

        var daysBetween = 0

        if (start.format('DD/MM/YYYY') === end.format('DD/MM/YYYY')) {
            return daysBetween
        }

        while (start < end) {
            if (DateUtils.isBusinessDay(start)) {
                daysBetween++
            }
            start.add(1, 'd')
        }

        return daysBetween
    }

    static businessAdd = (input, number, period) => {
        var day = input.clone()
        if (!day.isValid()) {
            return day
        }
        var signal = number < 0 ? -1 : 1
        var remaining = Math.abs(number)
        period = typeof period !== 'undefined' ? period : 'days'

        while (remaining) {
            day.add(signal, period)

            if (DateUtils.isBusinessDay(day)) {
                remaining--
            }
        }

        return day
    }

    static businessSubtract = (input, number, period) => {
        return DateUtils.businessAdd(input, -number, period)
    }

    static nextBusinessDay = input => {
        var loop = 1
        var limit = 7
        while (loop < limit) {
            if (DateUtils.isBusinessDay(input.add(1, 'd'))) {
                break
            }
            loop++
        }
        return input
    }

    static prevBusinessDay = function (input) {
        var loop = 1
        var limit = 7
        while (loop < limit) {
            if (DateUtils.isBusinessDay(input.subtract(1, 'd'))) {
                break
            }
            loop++
        }
        return input
    }

    static monthBusinessDays = (input, partialEndDate) => {
        if (!input.isValid()) {
            return []
        }
        var me = input.clone()
        var day = me.clone().startOf('month')
        var end = partialEndDate ? partialEndDate : me.clone().endOf('month')
        var daysArr = []
        var done = false
        while (!done) {
            if (DateUtils.isBusinessDay(day)) {
                daysArr.push(day.clone())
            }
            if (end.diff(day.add(1, 'd')) < 0) {
                done = true
            }
        }
        return daysArr
    }

    static monthNaturalDays = (input, fromToday) => {
        if (!input.isValid()) {
            return []
        }
        var me = input.clone()
        var day = fromToday ? me.clone() : me.clone().startOf('month')
        var end = me.clone().endOf('month')
        var daysArr = []
        var done = false
        while (!done) {
            daysArr.push(day.clone())
            if (end.diff(day.add(1, 'd')) < 0) {
                done = true
            }
        }
        return daysArr
    }

    static monthBusinessWeeks = (input, fromToday) => {
        if (!input.isValid()) {
            return []
        }
        var me = input.clone()
        var day = fromToday ? me.clone() : me.clone().startOf('month')
        var end = me.clone().endOf('month')
        var weeksArr = []
        var daysArr = []
        var done = false

        while (!done) {
            if (day.day() >= 1 && day.day() < 6) {
                daysArr.push(day.clone())
            }
            if (day.day() === 5) {
                weeksArr.push(daysArr)
                daysArr = []
            }
            if (end.diff(day.add(1, 'd')) < 0) {
                if (daysArr.length < 5) {
                    weeksArr.push(daysArr)
                }
                done = true
            }
        }
        return weeksArr
    }

    static monthNaturalWeeks = (input, fromToday) => {
        if (!input.isValid()) {
            return []
        }
        var me = input.clone()
        var day = fromToday ? me.clone() : me.clone().startOf('month')
        var end = me.clone().endOf('month')
        var weeksArr = []
        var daysArr = []
        var done = false

        while (!done) {
            daysArr.push(day.clone())
            if (day.day() === 6) {
                weeksArr.push(daysArr)
                daysArr = []
            }
            if (end.diff(day.add(1, 'd')) < 0) {
                if (daysArr.length < 7) {
                    weeksArr.push(daysArr)
                }
                done = true
            }
        }
        return weeksArr
    }
}

export default DateUtils
