import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Permissions } from 'utils/acl-permissions'

// function splitCamelCaseToString(s) {
//   return "fal fa-" + s.split(/(?=[A-Z])/).join('-').toLowerCase()
// }

//import { getPermissions, PERMISSIONS_ROUTES_ONLY } from 'app-config'

class BtnIcon extends Component {
    render() {
        const {
            metadata
        } = this.props

        // let iconClass = splitCamelCaseToString(this.props.btnIcon)
        const coreRender = () => {
            const {
                onClick,
                loading,
                id,
                newWindow,
                hasChevron,
                btnIcon,
                btnClass,
                disabled,
                btnTo,
                hideOnDisabled,
                btnText,
                title,
                isExternal = false
            } = this.props

            const iconClass = !loading ? `fal fa-${btnIcon}` : 'fal fa-sync fa-spin'

            const isDisabled = disabled
            const url = isDisabled ? '#' : btnTo
            const linkClass = isDisabled ? 'disabled ' + btnClass : btnClass
            //const isExternal = this.props.isExternal || false

            const isHidden = hideOnDisabled && isDisabled ? { display: 'none' } : null

            const showChevron = hasChevron
            if (isExternal) {
                return (
                    <a
                        title={title}
                        className={linkClass}
                        href={url}
                        style={isHidden}
                        id={id}
                        target={newWindow ? '_blank' : '_self'}
                    >
                        {(!showChevron) && (
                            <i className={iconClass} />
                        )}
                        {(showChevron) && (
                            <i className="fal fa-chevron-right mr-8" />
                        )}
                        {btnText}
                    </a>
                )
            }
            if (onClick) {
                return (
                    <a
                        role="button"
                        tabIndex="0"
                        title={title}
                        className={linkClass}
                        onClick={onClick}
                        onKeyPress={onClick}
                        style={isHidden}
                        id={id}
                    >
                        <i className={iconClass} />
                        {btnText}
                    </a>
                )
            } else {
                return (
                    <Link
                        title={title}
                        to={url}
                        className={linkClass}
                        style={isHidden}
                        id={id}
                    >
                        {showChevron && (
                            <i className="fal fa-chevron-right mr-8" />
                        )}
                        <i className={iconClass} />
                        {btnText}
                    </Link>
                )
            }
        }

        return (
            <Permissions
                routeOrSectionPermissions={metadata ? metadata.permissions : []}
                fallbackElement={null}
            >
                {coreRender()}
            </Permissions>
        )
    }
}
export default BtnIcon
