// Help resolve nested values for a given object prop, e.g. fields.nested.name
// Will handle simple values too, e.g. name
// Doesn't support brackets though
import { lifecycle } from 'recompose'
import DateUtils from './dateUtils'
import moment from 'moment'
import { MATERIAL_ICONS } from './constants'

export const getFormattedAddress = (address, overide) => {
    let newAddress = []

    if (address) {
        // SP Work around till we update the demo store the address object
        if (window.isDEMO && !overide) return address.Address
        for (var i = 1; i < 5; i++) {
            if (address[`AddressLine${i}`])
                newAddress.push(address[`AddressLine${i}`].toLowerCase())
        }

        if (address.Suburb) newAddress.push(`${address.Suburb.toLowerCase()},`)
        if (address.State) newAddress.push(address.State.toUpperCase())
        if (address.Postcode) newAddress.push(address.Postcode.toLowerCase())
        return newAddress.join(' ')
    }
    return ''
}

export const activeServiceOrder = d => {
    if (!d) return false
    if (!d.length) return false
    let active = false
    for (let i = 0; i < d.length; i++) {
        if (d[i].Status !== 'CANCELLED' && d[i].Status !== 'COMPLETED') {
            active = true
            break
        }
    }
    return active
}

export const isExpiredTechConnect = (lastStatusChanged) =>{
    const timeFormatInput = 'YYYY-MM-DDThh:mm:ss'
    const mLastStatusChangedDate = moment(lastStatusChanged, timeFormatInput).hour(17);
    const now = moment()
    return now.isAfter(mLastStatusChangedDate)
}

export const techConnectNz = serviceOrders => {
    if (!serviceOrders?.length) return false
    let enabled = true

    for (const serviceOrder of serviceOrders){
        if(serviceOrder.Status === 'WORKING' || serviceOrder.Status === 'COMPLETED'){
            if(isExpiredTechConnect(serviceOrder?.LastStatusChangedDate)) {
                enabled = false
                break
            }
        }
    }
    return enabled
}

//import AclPermissions from './acl-permissions'
export const getObjectValueByString = (name, obj) => {
    return name.split('.').reduce((res, key) => {
        if (res && typeof res === 'object') {
            return res[key]
        }

        return null
    }, obj)
}

export const withFooterData = lifecycle({
    componentDidMount() {
        if (this.props.fixedFooter) {
            const footer = document.querySelector('[name="mcbfooter"]')
            if (footer) {
                footer.style.display = 'none'
                // footer.style.position = 'fixed'
                // footer.style.bottom = '0'
                // footer.style.left = '0'
                // footer.style.width = '100%'
            }
        }
    },

    componentWillUnmount() {
        const footer = document.querySelector('[name="mcbfooter"]')
        if (footer) {
            footer.style.display = 'block'
            // footer.style.position = 'relative'
        }
    }
})

const debounce = (fn, delay) => {
    let timer = null
    return function(...args) {
        const context = this
        timer && clearTimeout(timer)
        timer = setTimeout(() => {
            fn.apply(context, args)
        }, delay)
    }
}

export { DateUtils, debounce }

// helper function get Font Awesome icon class name
export const getFontAwesomeIconName = extension => {
    const materialIcon = MATERIAL_ICONS.find(
        materialIcon => materialIcon.extension === extension
    )

    let iconsName
    if (materialIcon) {
        iconsName = materialIcon.icon
    } else {
        iconsName = 'file'
    }
    return iconsName
}
