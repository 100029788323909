/**
 * Canon
 *
 * @package     mcb-front-end
 * @project     Ignite
 * @desc        Combine reducers
 * @repo        ssh://git@10.150.20.213:7999/pp/columbus-front-end.git
 *
 */

// import { combineReducers } from 'redux'
import canonhub from './canonhub.js'
import auth from './auth.js'
import account from './account.js'
import search from './search.js'
import permissions from './acl-permissions'
import acl from './acl'
import metadata from './metadata'
import dsf from './dsf-reducer'

export default {
    account,
    auth,
    canonhub,
    search,
    permissions,
    acl,
    metadata,
    dsf
}
