import React from 'react'

const TermsConditionsContent = () => (
    <React.Fragment>
        <section>
            {/* <div className="heading">Terms of Use</div> */}
            <div className="para">
                The use of MyCanon Business is governed by the terms of use
                available{' '}
                <a
                    href="https://www.canon.com.au/support/mycanon-business/mycanon-business-terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    here
                </a>. Please accept to continue
            </div>
        </section>
    </React.Fragment>
)

export default TermsConditionsContent
