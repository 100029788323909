import axios from 'axios'
import { find } from 'lodash'

class HelpTooltipApi {
    static getMCBHelpData = node => {
        return axios
            .get(`https://mcb-demo-portal.firebaseio.com/seedData/help/.json`)
            .then(response => {
                return response.data
            })
            .then(data => {
                return data
            })
            .catch(error => {
                throw error
            })
    }
    // now redundant
    static findMCBHelpData = (tree, branch, fallback = '') => {
        if (!Object.keys(tree).length) return ''
        const tip = find(tree, branch)
        return !tip ? '' : tip[branch] || ''
    }
}

export default HelpTooltipApi

// new function to return from localStorage (rather than on the fly)
// TODO: could probable go into the store i suppose
export const helpTip = (branch, fallback = '') => {
    const tree = JSON.parse(localStorage.getItem('help'))
    if (!tree || !Object.keys(tree).length) return ''
    const tip = find(tree, branch)
    return !tip ? '' : tip[branch] || ''
}
