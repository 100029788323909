import Routes from './routes'
import React from 'react'
import './index.css'
import AnimateLoad from 'components/common/animate-load'

const Assets = () => (
    <div className="mcb__assets">
        <div className="">
            <Routes />
        </div>
    </div>
)

export default AnimateLoad(Assets)
