import React from 'react'
import axios from 'axios'
import config from 'config.json'
import { uniqueId } from 'lodash'

class WingmanApi {
    static fetchHelpNode = node => {
        if (window.DEBUG) console.time(`selfhelp ${node}`)
        return axios
            .get(
                `https://uatapi.canon.com.au/hub/v2/selfhelp?path=mfd/support/help/iradvc/nodes/${node}`
            )
            .then(response => {
                if (window.DEBUG) console.timeEnd(`selfhelp ${node}`)
                return response
            })
            .catch(error => {
                if (window.DEBUG) console.timeEnd(`selfhelp ${node}`)
                throw error
            })
    }

    static decodeWingman(node, nodeClick, noderoot) {
        if (!node) return null
        const listItems = Object.keys(node.view).map(key => {
            return this.decodeViewType(node.view[key], 'c' + key, nodeClick)
        })
        return (
            <div
                className={`wingman-items ${noderoot} type-${node.type} mb-16`}
                key={uniqueId('parent_')}
            >
                {listItems}
            </div>
        )
    }
    static decodeViewType = (d, k, nodeClick) => {
        if (!d) return null
        if (!d.type) return null
        let helpModel = d.model ? d.model : 'all' // check the device model against the model/pattern from the json
        let truthy = helpModel === 'all'
        if (!truthy) {
            let helpModelArray = helpModel.split('|')
            for (let i = 0, j = helpModelArray.length; i < j; i++) {
                if (
                    this.matchRuleShort(
                        this.props.model.toLowerCase(),
                        helpModelArray[i]
                    )
                )
                    truthy = true
            }
        }
        if (d.except && truthy) {
            // check for model exception in the model/pattern
            let helpModelArray = helpModel.split('|')
            for (let i = 0, j = helpModelArray.length; i < j; i++) {
                if (
                    this.matchRuleShort(
                        this.props.model.toLowerCase(),
                        helpModelArray[i]
                    )
                )
                    truthy = false
            }
        }
        if (!truthy) return null
        let nodeType = d.type
        if (nodeType === 'list' && d.ul) nodeType = 'list.ul'
        if (nodeType === 'list' && d.ol) nodeType = 'list.ol'
        switch (nodeType) {
            case 'text':
                if (d.h2 === 'Or describe the issue with your MFD') return //exception
                if (d.h2)
                    return (
                        <div
                            key={uniqueId('chat_')}
                            className={`bubble-left fs-small d-flex`}
                        >
                            <div className="bubble-message txt">{d.h2}</div>
                        </div>
                    )
                if (d.p)
                    if (Array.isArray(d.p)) {
                        return Object.keys(d.p).map(key => {
                            return (
                                <p
                                    className="txt"
                                    key={uniqueId('para_')}
                                    dangerouslySetInnerHTML={{
                                        __html: d.p[key]
                                    }}
                                />
                            )
                        })
                    } else {
                        return (
                            <p
                                className="txt"
                                key={uniqueId('para_')}
                                dangerouslySetInnerHTML={{ __html: d.p }}
                            />
                        )
                    }

                break
            case 'line':
                return <hr key={uniqueId('line_')} />
            case 'list.ul':
                return (
                    <ul className="items" key={uniqueId('ul_')}>
                        {Object.keys(d.ul).map(key => {
                            if (d.ul[key] && d.ul[key].text && d.ul[key].link) {
                                return (
                                    <li
                                        key={uniqueId('li_')}
                                        className="item posn__rel link button"
                                        onClick={() =>
                                            nodeClick(
                                                d.ul[key].link,
                                                d.ul[key].text
                                            )
                                        }
                                    >
                                        {/* <span className="fs-vsmall">[{Number(key) + 1}]</span>&nbsp;&nbsp; */}
                                        <span className="txt">
                                            {d.ul[key].text}
                                        </span>
                                    </li>
                                )
                            } else if (d.ul[key] && d.ul[key].text) {
                                return (
                                    <li
                                        key={uniqueId('li_')}
                                        className="item posn__rel"
                                    >
                                        {/* <span className="fs-vsmall">[{Number(key) + 1}]</span>&nbsp;&nbsp; */}
                                        <span className="txt">
                                            {d.ul[key].text}
                                        </span>
                                    </li>
                                )
                            } else {
                                return null
                            }
                        })}
                    </ul>
                )
            case 'list.ol':
                return (
                    <ol className="items flexboxy" key={uniqueId('ol_')}>
                        {Object.keys(d.ol).map(key => {
                            if (d.ol[key] && d.ol[key].text) {
                                return (
                                    <li
                                        key={uniqueId('li_')}
                                        className="item txt"
                                    >
                                        {d.ol[key].text}
                                        {d.ol[key].img && (
                                            <div key={uniqueId('div_')}>
                                                <img
                                                    src={
                                                        'https://www.canon.com.au/' +
                                                        d.ol[key].img
                                                    }
                                                    alt=""
                                                    key={uniqueId('img_')}
                                                />
                                            </div>
                                        )}
                                    </li>
                                )
                            } else {
                                return null
                            }
                        })}
                    </ol>
                )
            case 'img':
                // this.multiElementSplit(d, 'img')
                if (Array.isArray(d.img)) {
                    return Object.keys(d.img).map(key => {
                        return (
                            <div className="img-wrapper">
                                <img
                                    src={
                                        'https://www.canon.com.au/' + d.img[key]
                                    }
                                    alt=""
                                    key={uniqueId('img_')}
                                />
                            </div>
                        )
                    })
                } else {
                    return (
                        <img
                            src={'https://www.canon.com.au/' + d.img}
                            alt=""
                            key={uniqueId('img_')}
                        />
                    )
                }
            case 'button':
                return null
            // return <button key={k + 'b'}>{d.text}</button>
            // if (d.text === 'Book a tech') {
            //   return (
            //     <Button
            //       btnIcon="truck"
            //       btnClass="btn__1 fs-vsmall"
            //       btnTo={'tel:131313'}
            //       btnText="Book a service"
            //       key={k + 'btn-service'}
            //       onClick={this.props.bookAService}
            //       metadata={metadata.mcbforms_service_orders}
            //     />
            //   )
            // }
            // return (
            //   <Button
            //     btnIcon="phone"
            //     btnClass="btn__3 fs-vsmall"
            //     btnTo={d.link}
            //     btnText={d.text}
            //     key={k + 'btn-service'}
            //     isExternal="true"
            //   />
            // )

            case 'pdf':
                return (
                    <a
                        href={'https://www.canon.com.au/' + d.link}
                        target="_blank"
                        key={k + 'a'}
                        className="pdf"
                    >
                        <i className="fal fa-download mr-8" />
                        {d.text}
                    </a>
                )
            default:
                return null
        }
    }
}

export default WingmanApi
