import React, {Fragment} from 'react'
import LinkButton from 'components/common/buttons/link-button-icon.jsx'
import {AuthHOC} from 'utils/acl-permissions'
import {Permissions} from 'utils/acl-permissions'
import './tile.css'

const Tile = React.forwardRef((props, ref) => {
    const {links, isNZ} = props

    return (
        <div className="tile-101" ref={ref}>
            <div className="heading d-flex-jsb">
                <span className="fs-headline fw-regular">{props.title}</span>
                <i className={`fal fa-${props.icon}`}/>
            </div>
            <div className="links pt-16">
                <ul className="items ma-0 pa-0">
                    {Object.keys(links).map(key => {
                        const isExternal = links[key].href.indexOf('//') > 0
                        return (
                            <Fragment key={key}>
                                {!isExternal && (
                                    <Permissions
                                        routeOrSectionPermissions={links[key] ? links[key].permissions : []}
                                        fallbackElement={null}
                                    >
                                        <li className="item fw-regular mb-4 pb-4 bla">
                                            <LinkButton
                                                btnIcon=""
                                                btnClass=""
                                                btnTo={`/${props.root}${links[key].href}`}
                                                btnText={links[key].text}
                                                metadata={links[key]}
                                                hasChevron="true"
                                            />
                                        </li>
                                    </Permissions>
                                )}
                                {isExternal && (
                                    <Permissions
                                        routeOrSectionPermissions={
                                            links[key]
                                                ? links[key].permissions
                                                : []
                                        }
                                        fallbackElement={null}
                                    >
                                        <li className="item fw-regular mb-4 pb-4 s">
                                            <a
                                                href={links[key].href}
                                                target="_blank"
                                                title={links[key].toolTip}
                                            >
                                                <i className="fal fa-chevron-right mr-8"/>
                                                {links[key].text}
                                            </a>
                                        </li>
                                    </Permissions>
                                )}
                            </Fragment>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
})
export default AuthHOC()(Tile, null)
