import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {Container, ErrorSection, Content} from './styles'
import {store} from 'redux/store.js'
import {SELECTED_ACCOUNT} from 'auth/constants'
import history from 'history/history.js'
import {isLoggedIn} from 'auth/AuthService'
import {ISNZ} from '../../auth/constants'
import {AU} from 'utils/constants'
import {compose, lifecycle} from 'recompose'


const withFooterConfig = lifecycle({
    componentDidMount() {
        //If we are hitting the error component, then dont display the footer
        const footer = document.querySelector('[name="mcbfooter"]')
        if (footer) {
            footer.style.display = 'none'
        }
        // remove all the Header links except dashboard in case of error
        if (store.getState().account.selectedAccount) {
        }
        //clear off the selected account in case of any error
        localStorage.removeItem(SELECTED_ACCOUNT)
    }
})

const enhance = compose(withFooterConfig)

const RenderErrorContact = props => {

    const {Localization} = props

    return (
        <Fragment>
            {(!ISNZ) && (
                <ContactAU/>
            )}
            {(ISNZ) && (
                <ContactNZ/>
            )}
        </Fragment>
    )
}

const ContactAU = () => (
    <Fragment>
        or contact our Support Team on 13 13 83 (select option 2)
    </Fragment>
)
const ContactNZ = () => (
    <Fragment>
        or contact our Support Team email: <a href='mailto:mybusiness@canon.co.nz'>mybusiness@canon.co.nz</a>, ph: 0800
        222 666 (select option 1 then option 3)
    </Fragment>
)

const GenericError = enhance(props => {

    const {errorObj, AccountNumber, Localization} = props

    return (
        <Container>
            <ErrorSection>
                <i className={errorObj.Icon}/>
                <p>{errorObj.Header}</p>
                <Content>
                    <div style={{lineHeight: '1.7rem'}}>
                        {errorObj.message()}
                        {errorObj.Header !== 'Order Complete' && errorObj.Name !== 'User Access Denied' && (
                            <RenderErrorContact Localization={Localization}/>
                        )}
                    </div>
                </Content>
                {
                    (
                        AccountNumber &&
                        isLoggedIn() &&
                        errorObj.Code === '0001'

                    ) && (
                        <p>
                            <a onClick={
                                () => {
                                    setTimeout(() => {
                                        history.replace(`/${AccountNumber}`)
                                    }, 100)
                                }}>
                                Click here to return to your default account
                            </a>
                        </p>
                    )}
            </ErrorSection>
        </Container>
    )
})

const mapStateToProps = state => {
    if (!state.canonhub) return
    let AccountNumber = ''
    if (
        state.canonhub.account &&
        state.canonhub.account.data &&
        state.canonhub.account.data.Data &&
        state.canonhub.account.data.Data.length > 1
    ) AccountNumber = state.canonhub.account.data.Data[0].Number
    const {Localization} = state.canonhub.accountDetails.data || AU
    return {
        AccountNumber,
        Localization
    }
}

export default connect(mapStateToProps)(GenericError)
