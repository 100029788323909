import axios from 'axios'
import ApiConfig from 'config.json'

import {
    downloadAPIResponseJSON,
    changeRequestConfigToMockURL
} from './mockAPIWorker'

//[sp]
/** Axios Interceptor class for downloading the mockresponses from server and render them in case of mock mode. **/
class MockService {
    //The role name when encountered to turn into MOCK mode
    static MOCK_USER_ROLE = 'b2b-demo'

    /* [sp] 3 modes of mocking 
'mock' is used to intercept the actual calls and call the mock api instead
'live' mode is used when we want to call the actual calls
'download' is live mode plus download the corresponding api jsons as files into local directory and feed those to mock api
*/
    static MODES = {
        MOCK: 'mock',
        DOWNLOAD: 'download',
        LIVE: 'live'
    }

    static EXEMPT_MOCK_URLS = ['/selfhelp', '/gcp/']

    static configure = mode => {
        if (mode === MockService.MODES.LIVE) return

        // Add a response interceptor
        axios.interceptors.response.use(
            response => {
                if (mode === MockService.MODES.DOWNLOAD) {
                    downloadAPIResponseJSON(response)
                }
                return response
            },
            error => {
                return Promise.reject(error)
            }
        )
        // Add a request interceptor
        axios.interceptors.request.use(
            config => {
                if (mode === MockService.MODES.MOCK) {
                    //based on the PROD or UAT change the request accordingly
                    const baseurl = window.isPROD
                        ? ApiConfig.demoApi.replace('env', 'prod')
                        : ApiConfig.demoApi.replace('env', 'uat')
                    changeRequestConfigToMockURL(config, baseurl)
                }

                return config
            },
            error => {
                return Promise.reject(error)
            }
        )
    }
}

//[sp] in case of refresh scenario, check if isDemo from the localstorage and confifure accordingly
const isDemo = localStorage.getItem('isDemo')
if (isDemo) {
    MockService.configure(MockService.MODES.MOCK)
    window.isDEMO = true
}

export default MockService
