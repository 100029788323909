import React, { Component } from 'react'

import AppConfigApi from '../AppConfigApi'
import Breakpoint from 'styles/Breakpoint'

import FormFieldSelect from 'components/form-components/FormFieldSelect'
import Button from 'components/common/button'

import InPageLoading from 'components/common/inpage-loading'

import './index.css'

//TODO need to refactor this lot of repitition code - [SP]

class MetaConfiguration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingMetadata: false,
            loadingAcldata: false,
            submittingAcldata: false,
            selectedPermission: null,
            allowedRoutes: [],
            deniedRoutes: [],
            allowedSections: [],
            deniedSections: [],
            routes: [],
            sections: [],
            acls: [],
            config: {}
        }
    }

    componentDidMount() {
        this.setState({
            loadingMetadata: true,
            loadingAcldata: true
        })
        AppConfigApi.getMCBAcldata({})
            .then(acls => {
                this.setState({
                    acls,
                    loadingAcldata: false
                })
            })
            .catch(error => {
                console.error(error)
                this.setState({
                    acls: []
                })
            })

        AppConfigApi.getMCBMetadata({})
            .then(config => {
                let routes = []
                let sections = []

                for (const entry of Object.values(config)) {
                    routes.push({
                        label: entry.id,
                        value: entry.route
                    })
                    const entrySections = entry['sections']
                    if (entrySections) {
                        for (const entrySection of Object.values(
                            entrySections
                        )) {
                            sections.push({
                                label: entrySection.id,
                                value: entrySection.id
                            })
                        }
                    }
                }

                this.setState({
                    config,
                    routes,
                    sections,
                    loadingMetadata: false
                })
            })
            .catch(error => {
                console.error(error)
                this.setState({
                    config: {}
                })
            })
    }

    //TODO need to refactor this lot of repitition code - [SP]
    handleChange = selectedPermission => {
        let { acls, routes, sections } = this.state
        const acl = acls.find(e => e.permission === selectedPermission)

        let allowedRoutes = [],
            deniedRoutes = [],
            allowedSections = [],
            deniedSections = []
        if (acl && acl.filters) {
            if (acl.filters.routes) {
                if (
                    acl.filters.routes.allow &&
                    acl.filters.routes.allow.length > 0
                ) {
                    for (const filterAllowedRoute of acl.filters.routes.allow) {
                        const routeval = routes.find(
                            e => filterAllowedRoute === e.value
                        )
                        if (routeval) allowedRoutes.push(routeval)
                    }
                }
                if (
                    acl.filters.routes.deny &&
                    acl.filters.routes.deny.length > 0
                ) {
                    for (const filterDeniedRoute of acl.filters.routes.deny) {
                        const routeval = routes.find(
                            e => filterDeniedRoute === e.value
                        )
                        if (routeval) deniedRoutes.push(routeval)
                    }
                }
            }

            if (acl.filters.sections) {
                if (
                    acl.filters.sections.allow &&
                    acl.filters.sections.allow.length > 0
                ) {
                    for (const allowSection of acl.filters.sections.allow) {
                        const sectionval = sections.find(
                            e => e.value === allowSection
                        )
                        if (sectionval) allowedSections.push(sectionval)
                    }
                }
                if (
                    acl.filters.sections.deny &&
                    acl.filters.sections.deny.length > 0
                ) {
                    for (const denySection of acl.filters.sections.deny) {
                        const sectionval = sections.find(
                            e => e.value === denySection
                        )
                        if (sectionval) deniedSections.push(sectionval)
                    }
                }
            }
        } else if (!acl) {
            acls.push({
                permission: selectedPermission,
                filters: {
                    routes: {
                        allow: [],
                        deny: []
                    },
                    sections: {
                        allow: [],
                        deny: []
                    }
                }
            })
            this.setState({
                acls
            })
        }

        this.setState({
            selectedPermission,
            allowedRoutes,
            deniedRoutes,
            allowedSections,
            deniedSections
        })
        // console.log(`Option selected:`, selectedPermission)
    }

    handleAllowedRoutes = allowedRoutes => {
        let { acls, selectedPermission } = this.state
        acls = acls.map(acl => {
            if (acl.permission === selectedPermission) {
                if (!acl.filters) {
                    acl.filters = {
                        routes: {
                            allow: [],
                            deny: []
                        }
                    }
                }
                if (!acl.filters.routes)
                    acl.filters.routes = {
                        allow: [],
                        deny: []
                    }
                if (!acl.filters.routes.allow) acl.filters.routes.allow = []

                let allowedRoutesSet = new Set([...allowedRoutes])
                acl.filters.routes.allow = [...allowedRoutesSet]
            }
            return acl
        })

        this.setState({ acls, allowedRoutes })
    }

    handleDeniedRoutes = deniedRoutes => {
        let { acls, selectedPermission } = this.state
        acls = acls.map(acl => {
            if (acl.permission === selectedPermission) {
                if (!acl.filters) {
                    acl.filters = {
                        routes: {
                            allow: [],
                            deny: []
                        }
                    }
                }
                if (!acl.filters.routes)
                    acl.filters.routes = {
                        allow: [],
                        deny: []
                    }
                if (!acl.filters.routes.deny) acl.filters.routes.deny = []

                let deniedRoutesSet = new Set([...deniedRoutes])
                acl.filters.routes.deny = [...deniedRoutesSet]
            }
            return acl
        })
        this.setState({ acls, deniedRoutes })
    }

    handleAllowedSections = allowedSections => {
        let { acls, selectedPermission } = this.state
        acls = acls.map(acl => {
            if (acl.permission === selectedPermission) {
                if (!acl.filters) {
                    acl.filters = {
                        sections: {
                            allow: [],
                            deny: []
                        }
                    }
                }
                if (!acl.filters.sections)
                    acl.filters.sections = {
                        allow: [],
                        deny: []
                    }
                if (!acl.filters.sections.allow) acl.filters.sections.allow = []

                let allowedSectionsSet = new Set([...allowedSections])
                acl.filters.sections.allow = [...allowedSectionsSet]
            }
            return acl
        })

        this.setState({ acls, allowedSections })
    }

    handleDeniedSections = deniedSections => {
        let { acls, selectedPermission } = this.state
        acls = acls.map(acl => {
            if (acl.permission === selectedPermission) {
                if (!acl.filters) {
                    acl.filters = {
                        sections: {
                            allow: [],
                            deny: []
                        }
                    }
                }
                if (!acl.filters.sections)
                    acl.filters.sections = {
                        allow: [],
                        deny: []
                    }
                if (!acl.filters.sections.deny) acl.filters.sections.deny = []

                let deniedSectionsSet = new Set([...deniedSections])
                acl.filters.sections.deny = [...deniedSectionsSet]
            }
            return acl
        })

        this.setState({ acls, deniedSections })
    }

    render() {
        const {
            acls,
            routes,
            sections,
            // selectedPermission,
            loadingMetadata,
            loadingAcldata,
            submittingAcldata
        } = this.state

        const options = acls.map(e => {
            let newAcl = e
            newAcl.label = e.permission
            newAcl.value = e.permission
            return newAcl
        })

        return (
            <div className="mcb__configuration deviceold section-layout-1200">
                <section className="head">
                    <div className="fs-headline brdr__head posn__rel">
                        <Breakpoint name="desktop">
                            Access Control Permissions
                        </Breakpoint>
                    </div>
                </section>
                <div className="content">
                    <div className="left posn__rel">
                        <div className="headerSelect">
                            <FormFieldSelect
                                name="acl-permission"
                                type="select"
                                label=""
                                multi={false}
                                onChange={this.handleChange}
                                value={this.state.selectedPermission}
                                placeholder="Select a permission to configure"
                                options={options}
                                creatable
                            />
                        </div>

                        <div className="py-8" />
                        <div className="d-flex">
                            <div className="leftContent">
                                <label>Allowed Routes</label>
                                <FormFieldSelect
                                    name="acl-permission"
                                    type="select"
                                    multi={true}
                                    onChange={this.handleAllowedRoutes}
                                    value={this.state.allowedRoutes}
                                    placeholder="Select the allowed routes for the acl"
                                    options={routes}
                                    disabled={!this.state.selectedPermission}
                                />

                                <label>Denied Routes</label>
                                <FormFieldSelect
                                    name="acl-permission"
                                    type="select"
                                    multi={true}
                                    onChange={this.handleDeniedRoutes}
                                    value={this.state.deniedRoutes}
                                    placeholder="Select the denied routes for the acl"
                                    options={routes}
                                    disabled={!this.state.selectedPermission}
                                />
                            </div>
                            <div className="divider" />
                            <div className="leftContent">
                                <label>Allowed Sections</label>
                                <FormFieldSelect
                                    name="acl-permission"
                                    type="select"
                                    multi={true}
                                    onChange={this.handleAllowedSections}
                                    value={this.state.allowedSections}
                                    placeholder="Select the allowed sections for the acl"
                                    options={sections}
                                    disabled={!this.state.selectedPermission}
                                />
                                <label>Denied Sections</label>
                                <FormFieldSelect
                                    name="acl-permission"
                                    type="select"
                                    multi={true}
                                    onChange={this.handleDeniedSections}
                                    value={this.state.deniedSections}
                                    placeholder="Select the denied sections for the acl"
                                    options={sections}
                                    disabled={!this.state.selectedPermission}
                                />
                            </div>
                        </div>
                        <div className="py-16" />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Button
                                background="primary"
                                border="primary"
                                color="white"
                                padding="12px 28px"
                                size="lg"
                                disabled={!this.state.selectedPermission}
                                onClick={() => {
                                    this.setState({
                                        submittingAcldata: true
                                    })
                                    AppConfigApi.postMCBAcldata(this.state.acls)
                                        .then(data =>
                                            this.setState({
                                                submittingAcldata: false
                                            })
                                        )
                                        .catch(error => console.error(error))
                                }}
                            >
                                Save changes
                            </Button>
                        </div>
                        <div className="py-16" />
                    </div>
                </div>
                {loadingMetadata ||
                    loadingAcldata ||
                    (submittingAcldata && <InPageLoading />)}
            </div>
        )
    }
}

export default MetaConfiguration
