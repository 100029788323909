/**
 * Canon
 *
 * @package     mcb-front-end
 * @project     Ignite
 *
 */

// -------------------------------------
//   Dependencies
// -------------------------------------
/* --empty block-- */

// -------------------------------------
//   Page - Home
// -------------------------------------
/* --empty block-- */

// ---------------------------------------------
//   Export block
// ---------------------------------------------
import header from './header.js'
import { withRouter } from 'react-router-dom'
export default withRouter(header)
