// @flow
import React from 'react'
import {StyledLoader /*StyledSpinner*/} from './styles'
// import { compose, lifecycle, defaultProps } from 'recompose'
import {compose, defaultProps} from 'recompose'
import {withFooterData} from '../../../utils'

import BubbleLoading from './bubbleLoading'
//import { Wave } from 'react-animated-text'

// const foo = (str?: number): Object => {
//   if (str) {
//     return { str }
//   }
//   return { str: "default string" }
// }

const enhance = compose(
    defaultProps({
        backgroundColor: 'transparent',
        color: '#4c4c4b',
        size: '30px',
        thickness: '3px',
        position: 'absolute',
        paddingAround: '0px',
        fixedFooter: true
    }),
    withFooterData
)

type Props = {
    backgroundColor?: string,
    center?: boolean,
    color?: string,
    marginBottom?: string,
    marginTop?: string,
    message?: string,
    size?: string,
    thickness?: string,
    position?: string,
    paddingAround?: string,
    fixedFooter?: boolean,
    containerHeight?: string
}

const Loading = enhance(
    ({
         backgroundColor,
         center,
         color,
         marginBottom,
         marginTop,
         message,
         size,
         thickness,
         position,
         paddingAround,
         fixedFooter,
         containerHeight,
         smallText,
     }: Props) => {
        return (
            <StyledLoader
                center={center}
                marginBottom={marginBottom}
                marginTop={marginTop}
                size={size}
                backgroundColor={backgroundColor}
                position={position}
                paddingAround={paddingAround}
                containerHeight={containerHeight}
            >
                <BubbleLoading/>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    {message}
                    <div className="saving">
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                    </div>
                </div>
                {smallText && (
                    <div className="smallText">
                        {smallText}
                    </div>
                )}
            </StyledLoader>
        )
    }
)

// Loading.defaultProps = {
//   backgroundColor: "transparent",
//   color: "#4c4c4b",
//   size: "30px",
//   thickness: "3px",
//   position: "absolute",
//   paddingAround: "0px"
// }

export default Loading
