import {
    clearIdToken,
    clearAccessToken,
    clearSelectedAndFavAccounts
} from './tokenHandlers'
import { clearPermissions } from './aclHandlers'

import { store, persistor } from '../redux/store.js'

import * as actionCreators from 'redux/actions/index.js'
class PreAuth {
    static process = (isReLogin) => {
        //sri - always login with clean state, so that expired tokens are never used
        clearIdToken()
        if(!isReLogin) clearSelectedAndFavAccounts()
        //sri - dont clear the callback because it will be used for next redirect after sucessfull login
        clearAccessToken(true)
        clearPermissions()
        persistor.purge()
        store.dispatch(actionCreators.isAuthenticating(true))
    }
}

export default PreAuth
