import {Switch} from 'react-router'
import {Route} from 'react-router-dom'
import Loading from 'components/common/loading'
import Loadable from 'react-loadable'
import React from 'react'
import {metadata} from 'app-config'
import {GenericError} from 'components/error-pages'
import ErrorCodes from 'utils/errorCodes'

const Billing = Loadable({
    loader: () => import('./billing-listing/index.js'),
    loading: () => (
        <Loading
            center
            size="40px"
            thickness="1px"
            paddingAround="200px"
            message="Loading Billing Data"
        />
    )
})
const Payment = Loadable({
    loader: () => import('./payment/index.js'),
    loading: () => (
        <Loading
            center
            size="40px"
            thickness="1px"
            paddingAround="200px"
            message="Loading Payment Data"
        />
    )
})

const Routes = () => (
    <Switch>
        <Route exact path={metadata.billing.route} component={Billing}/>
        <Route exact path={metadata.payment.route} component={Payment}/>
        <Route
            render={props => {
                return <GenericError errorObj={ErrorCodes.NOT_FOUND}/>
            }}
        />
    </Switch>
)

export default Routes
