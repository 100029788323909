import Routes from './routes'
import React from 'react'
import './index.css'
import AnimateLoad from 'components/common/animate-load'

const AssetOrders = () => (
    <div className="mcb__asset__orders">
        <div className="dd-flex">
            <Routes />
        </div>
    </div>
)

export default AnimateLoad(AssetOrders)
