/**
 * Canon
 *
 * @package     mcb-front-end
 * @project     Ignite
 * @desc        Chatbox w/ Dialogflow
 * @link        https://hacks.mozilla.org/2012/05/dom-mutationobserver-reacting-to-dom-changes-without-killing-browser-performance/
 * @link        https://github.com/typicode/react-lodash
 * @link        https://medium.com/@heatherbooker/how-to-auto-scroll-to-the-bottom-of-a-div-415e967e7a24
 * @link        https://codepen.io/devstreak/pen/dMYgeO styling scroll bars
 * @link        https://flaviocopes.com/speech-synthesis-api/ speech synthesis api
 * @link        https://mdn.github.io/web-speech-api/speech-color-changer/script.js
 * @link        https://www.google.com/intl/en/chrome/demos/speech.html
 * @link        https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesis/speaking
 * @link        https://developer.mozilla.org/en-US/docs/Web/API/Web_Speech_API/Using_the_Web_Speech_API#Demo
 * @link        https://nzbin.github.io/three-dots/  3 dots loading
 *
 */

// -------------------------------------
//   Dependencies: core
// -------------------------------------
import React, {Component} from 'react'
import './chatbot.css'
import ChatBot from './dialogFlow.js'
import {uniqueId} from 'lodash'
import WingmanApi from 'components/common/wingman/wingman.js'
import parse from 'html-react-parser'
import {connect} from 'react-redux'
import {cloneDeep} from 'lodash'
import {gsap} from "gsap/dist/gsap"

const isConstructor = value => {
    try {
        new new Proxy(value, {
            construct() {
                return {}
            }
        })()
        return true
    } catch (err) {
        return false
    }
}

let _timeoutID
let someElement, observer
let speechSynthUtterance =
    'speechSynthesis' in window ? new SpeechSynthesisUtterance() : '' // gotta do it here else state will complain
// let recognition =
//   'webkitSpeechRecognition' in window ? new webkitSpeechRecognition() : ''
var SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition
let recognition = isConstructor(SpeechRecognition)
    ? new SpeechRecognition()
    : null

// console.log('webkitSpeechRecognition' in window)
class Chatbot extends Component {
    // ---------------------------------------------
    //   Constructor block
    // ---------------------------------------------
    /**
     * @name constructor
     * @desc the constructor for the component class.
     * @param {Object} props - the properties passed to the component.
     */
    constructor(props) {
        super(props)
        this.state = {
            expand: false,
            chatMessages: [],
            wingmanResponse: '',
            postVal: '',
            chatMsgs: [],
            testWing: [],
            SessionId: '',
            AccessToken: '',
            isReady: false,
            isThinking: false,
            isMic: true,
            isSpeak: false,
            fatman: '',
            firstTime: false,
            isManagingFocus: false
        }
        this.textInput = React.createRef()
        this.fetchWingman = this.fetchWingman.bind(this)
        this.handleNodeChange = this.handleNodeChange.bind(this)
        this.handleQuickSelect = this.handleQuickSelect.bind(this)
        this.chatbotRequest = this.chatbotRequest.bind(this)
        this.handleMicClick = this.handleMicClick.bind(this)
        this.handleSpeakClick = this.handleSpeakClick.bind(this)
        this.handleRecognition = this.handleRecognition.bind(this)
        this.fatmanRef = React.createRef()
        //this.myTween = gsap({ paused: true })
        this.chatbotRef = null
    }

    /**
     * @name componentDidMount
     * @desc the function called once the component mounted
     */
    componentDidMount() {
        document.body.classList.add('chatbot')
        // const voiceschanged = () => {
        //   console.log(`Voices #: ${speechSynthesis.getVoices().length}`)
        //   speechSynthesis.getVoices().forEach(voice => {
        //     console.log(voice.name, voice.lang)
        //   })
        // }
        // speechSynthesis.onvoiceschanged = voiceschanged
        if (speechSynthUtterance) {
            speechSynthUtterance.lang = 'en-AU'
            speechSynthUtterance.name = 'Karen'
        }
        if (recognition) {
            recognition.lang = 'en-US'
            recognition.continuous = false
            recognition.interimResults = false
            recognition.onstart = this.handleRecognition.bind(this)
            recognition.onerror = this.handleRecognition.bind(this)
            recognition.onend = this.handleRecognition.bind(this)
            recognition.onresult = event => this.handleRecognition(event)
        }

        this.myTween = gsap.to(this.chatbotRef, 0.35, {
            bottom: 0,
            delay: 1,
            opacity: 1
        })
        // this.myTween.play()
    }

    handleMicClick(event) {
        if (this.state.isMic) {
            this.handleInitRecognition()
        } else {
            recognition.stop()
        }
    }

    handleRecognition(event) {
        // console.log(s)
        // console.log(s.results)
        // console.log(event.type)
        switch (event.type) {
            case 'start':
                this.setState({
                    isMic: false,
                    isThinking: true
                })
                break
            case 'result':
                if (
                    event &&
                    event.results &&
                    event.results[0][0] &&
                    event.results[0][0].transcript
                ) {
                    this.addNewChatMessage(
                        <div
                            key={uniqueId('chat_')}
                            className={`bubble-right fs-small d-flex`}
                        >
                            <div className="bubble-message">
                                {event.results[0][0].transcript}
                            </div>
                        </div>
                    )
                    this.chatbotRequest(event.results[0][0].transcript)
                } else {
                    this.addNewChatMessage(
                        <div
                            key={uniqueId('chat_')}
                            className={`bubble-left fs-small d-flex`}
                        >
                            <div className="bubble-message">
                                Sorry, didn't quite catch that.
                            </div>
                        </div>
                    )
                }

                break
            case 'end':
                this.setState({
                    isMic: true,
                    isThinking: false
                })
                recognition.stop()
                break
            default:
        }
    }

    handleInitRecognition() {
        // recognition = new SpeechRecognition() || null
        recognition && recognition.start()
    }

    // componentDidUpdate(prevProps) {
    //   // Typical usage (don't forget to compare props):
    //   console.log(
    //     this.props.info.Member.FirstName,
    //     prevProps.info.Member.FirstName,
    //     this.props.info.Member.FirstName !== prevProps.info.Member.FirstName
    //   )
    //   if (this.props.info.Member.FirstName !== prevProps.info.Member.FirstName) {
    //     this.addNewChatMessage(
    //       <div key={uniqueId('chat_')} className={`bubble-left fs-small d-flex`}>
    //         <div className="bubble-message">{`Hi ${
    //           this.props.info.Member.FirstName
    //         } I'm a chatbot, how can i help?`}</div>
    //       </div>
    //     )
    //   }
    // }
    /**
     * @name scrollToBottom
     * @desc scroll to bottom of chatwindow
     */
    scrollToBottom() {
        someElement.scrollTop = someElement.scrollHeight
        // someElement.scrollLeft = someElement.scrollWidth
    }

    /**
     * @name componentWillUnmount
     * @desc the function called before the component is unmounted.
     */
    componentWillUnmount() {
        if (observer) observer.disconnect()
        document.body.classList.remove('chatbot')
    }

    /**
     * @name clickHandler
     * @desc open/close the chatbot window
     */
    clickHandler() {
        //TODO: disable the observer when chat is minimized
        if (!this.state.isReady) {
            someElement = document.querySelector('.chat-container')
            observer = new MutationObserver(this.scrollToBottom)
            observer.observe(someElement, {childList: true})
            this.addNewChatMessage(
                <div
                    key={uniqueId('chat_')}
                    className={`bubble-left fs-small d-flex`}
                >
                    <div className="bubble-message">{`Hi ${this.props.info.Member
                        ? this.props.info.Member.FirstName
                        : ''
                    }, I'm a chatbot, how can i help?`}</div>
                </div>
            )
        }
        this.setState({
            expand: !this.state.expand,
            isReady: true
        })
    }

    /**
     * @name handleInputEnter
     * @desc handle input from a user
     * @param {Object} event - the text input
     */
    handleInputEnter(event) {
        const keyCode = event.keyCode || event.which
        if (keyCode === 13) {
            this.addNewChatMessage(
                <div
                    key={uniqueId('chat_')}
                    className={`bubble-right fs-small d-flex`}
                >
                    <div className="bubble-message">{event.target.value}</div>
                </div>
            )
            this.chatbotRequest(event.target.value)
            this.textInput.current.value = ''
            this.textInput.current.focus()
        }
    }

    chatbotRequest(q) {
        const query = `${q}&sessionId=${this.state.SessionId}&accessToken=${this.state.AccessToken
        }&platform=MCB`
        this.setState({
            isThinking: true
        })
        ChatBot.getResponse(query).then(data => {
            if (data.Status === 'OK') {
                const response = data.Payload.DialogflowQueryResponse
                if (response.AccessToken && response.SessionId) {
                    this.setState({
                        AccessToken: response.AccessToken,
                        SessionId: response.SessionId
                    })
                }
                const chatResponse =
                    this.htmlParse(response.QueryResult.FulfillmentText) || null
                if (
                    this.state.isSpeak &&
                    speechSynthUtterance &&
                    chatResponse
                ) {
                    speechSynthUtterance.lang = 'en-AU'
                    speechSynthUtterance.name = 'Karen'
                    speechSynthUtterance.text = chatResponse.text
                    window.speechSynthesis.speak(speechSynthUtterance)
                }
                this.addNewChatMessage(
                    <div
                        key={uniqueId('chat_')}
                        className={`bubble-left fs-small d-flex`}
                    >
                        <div className="bubble-message">
                            {response.QueryResult
                                ? chatResponse.jsx
                                : 'chat offline'}
                        </div>
                    </div>
                )
            }
            this.setState({
                isThinking: false
            })
        })
    }

    /**
     * @name addNewChatMessage
     * @desc append chat window
     * @param {Element} message - the element to append to the chatwindow
     */
    addNewChatMessage(message) {
        const el = this.state.chatMsgs
        el.push(message)
        this.setState({
            chatMsgs: el
        })
    }

    /**
     * @name fetchWingman
     * @desc fetch json (node) from the wingman endppint
     * @param {String} node - the node element
     */

    extractContent(html) {
        return new DOMParser().parseFromString(html, 'text/html')
            .documentElement.textContent
    }

    fetchWingman(node) {
        // console.log(node)
        const res = WingmanApi.fetchHelpNode(node)
        res.then(response => {
            if (response.status === 200 && response.data.payload.content) {
                this.setState({
                    wingmanResponse: response.data.payload.content
                })
                const decode = WingmanApi.decodeWingman(
                    response.data.payload.content,
                    this.handleNodeChange,
                    node
                )
                // const el = this.state.chatMsgs
                // const conCats = el.concat(decode)
                this.setState(
                    {
                        chatMsgs: cloneDeep(this.state.chatMsgs).concat(decode),
                        fatman: cloneDeep(decode)
                        // testWing: [...this.state.testWing, decode]
                        // testWing: decode
                    },
                    () => {
                        //TODO: include the speech text in the json payload.. rather than trying to decode from the html
                        const plainText = this.fatmanRef.current.querySelectorAll(
                            '.txt'
                        )
                        const textArray = []
                        plainText.forEach(el => {
                            textArray.push(el.innerText)
                        })
                        // console.log(textArray)
                        if (
                            this.state.isSpeak &&
                            speechSynthUtterance &&
                            textArray
                        ) {
                            speechSynthUtterance.lang = 'en-AU'
                            speechSynthUtterance.name = 'Karen'
                            speechSynthUtterance.text = textArray.join('. ')
                            window.speechSynthesis.speak(speechSynthUtterance)
                        }
                    }
                )
            }
        }).catch(error => {
            console.log(error)
        })
    }

    /**
     * @name handleNodeChange
     * @desc click handler when user clicks a wingman link/navigation
     * @desc also add same message (user input) to the RHS
     * @param {String} node - the node element
     * @param {text} text - the text of what they clicked on
     */
    handleNodeChange(node, text) {
        this.fetchWingman(node)
        this.addNewChatMessage(
            <div
                key={uniqueId('chat_')}
                className={`bubble-right fs-small d-flex`}
            >
                <div className="bubble-message">{text}</div>
            </div>
        )
    }

    /**
     * @name htmlParse
     * @desc safely handle the html/json from wingman
     * @param {String} html - the json response
     */
    htmlParse(html) {
        let elements = html
        try {
            elements = elements.replace(
                /__ACTMGR__/g,
                `<br /><strong>${this.props.info.AccountManager.FirstName} ${this.props.info.AccountManager.LastName
                }</strong><br/>e: ${this.props.info.AccountManager.Email
                }<br/>p: ${this.props.info.AccountManager.Phone}<br/>m: ${this.props.info.AccountManager.Mobile
                }`
            ) //who is my account manager
            elements = elements.replace(
                /__ACTNUM__/g,
                `${this.props.info.Number}`
            )
            elements = elements.replace(
                /__PAYNUM__/g,
                `${this.props.info.BPayReference}`
            )
            elements = elements.replace(
                /__PAYTERM__/g,
                `${this.props.info.PaymentTerms}`
            )
        } catch (error) {
            console.log(error)
        }

        if (elements.indexOf('__WINGMAN__') >= 0) {
            // elements = 'Lets see if we can work out the issue'
            // this.fetchWingman('iradvc')
            if (window.DEBUG) console.log('cbot: wingman', elements)
            const textResponse = elements.split('__')
            if (
                textResponse.length > 4 &&
                textResponse[3] &&
                textResponse[3].toLowerCase().indexOf('|') >= 0
            ) {
                elements = 'This is what i found out'
                this.fetchWingman(textResponse[3].toLowerCase())
            } else {
                elements = 'Lets see if we can work out the issue'
                this.fetchWingman('iradvc')
            }
        }
        return {jsx: parse(elements), text: elements}
    }

    handleQuickSelect(event) {
        this.addNewChatMessage(
            <div
                key={uniqueId('chat_')}
                className={`bubble-right fs-small d-flex`}
            >
                <div className="bubble-message">{event.target.value}</div>
            </div>
        )
        this.chatbotRequest(event.target.value)
    }

    handleSpeakClick(event) {
        if (
            this.state.isSpeak &&
            speechSynthUtterance &&
            window.speechSynthesis.speaking
        ) {
            window.speechSynthesis.cancel()
        }
        this.setState({
            isSpeak: !this.state.isSpeak
        })
    }

    _onBlur() {
        _timeoutID = setTimeout(() => {
            if (this.state.isManagingFocus) {
                this.setState({
                    isManagingFocus: false,
                });
                this.clickHandler()
            }
        }, 0);
    }

    _onFocus() {
        clearTimeout(_timeoutID);
        if (!this.state.isManagingFocus) {
            this.setState({
                isManagingFocus: true,
            });
        }
    }

    // ---------------------------------------------
    //   Render block
    // ---------------------------------------------
    /**
     * @name render
     * @desc the render function for the component.
     */
    render() {
        // const { info } = this.props
        return (
            <div
                // onBlur={this._onBlur.bind(this)}
                // onMouseLeave={this._onBlur.bind(this)}
                onFocus={this._onFocus.bind(this)}
                className={
                    this.state.expand ? 'mcb__chatbot expand' : 'mcb__chatbot'
                }
                ref={div => (this.chatbotRef = div)}
            >
                <div className="border-top"/>
                <div
                    className="title text-center pa-8 pl-16 pr-16 fs-small"
                    onClick={this.clickHandler.bind(this)}
                    onKeyPress={this.clickHandler.bind(this)}
                >
                    <i role="button" tabIndex="0" className="fal fa-robot mr-8"/>Chatbot
                    <span
                        className="stage d-inline-block"
                        style={{opacity: this.state.isThinking ? 1 : 0}}
                    >
                        <div className="dot-carousel"/>
                    </span>
                </div>

                <section className="pa-16 chat-container posn__rel">
                    {this.state.chatMsgs}
                </section>
                <div className="fatman posn__abs" ref={this.fatmanRef}>
                    {this.state.fatman}
                </div>
                {this.state.expand && (
                    <div className="chat-options posn__rel">
                        <div className="chat-question d-flex-aic d-flex-jc">
                            <div className="quick-chat posn__rel">

                                <select role="button" tabIndex="0"
                                        className="posn__abs quick-chat-select"
                                        onChange={this.handleQuickSelect}
                                >
                                    <option value="" disabled>
                                        Chatbot FAQ's
                                    </option>
                                    <option value="What is chatbot">
                                        What is chatbot
                                    </option>
                                    <option value="I want to Get Service & Support">
                                        I want to Get Service & Support
                                    </option>
                                    <option value="How do i troubleshoot my device">
                                        How do i troubleshoot my device
                                    </option>
                                    <option value="I need a technician">
                                        I need a technician
                                    </option>
                                    <option value="What is my account number">
                                        What is my account number
                                    </option>
                                    <option value="What time is the help desk open">
                                        What time is the help desk open
                                    </option>
                                </select>

                                <span className="fal fa-chevron-down hamburger pa-16"/>
                            </div>
                            <input
                                type="text"
                                placeholder="Ask a question"
                                onKeyUp={this.handleInputEnter.bind(this)}
                                ref={this.textInput}
                                className="chat-input"
                            />
                        </div>
                    </div>

                )}


            </div>
        )
    }
}

const mapStateToProps = (state, props) => {

    const member = state.canonhub?.memberInfo

    const info =
        state.canonhub.accountDetails?.data
            ? {
                BPayReference:
                state.canonhub.accountDetails?.data?.BPayReference,
                Id: state.canonhub.accountDetails.data.Id,
                Company: state.canonhub.accountDetails.data.Name,
                Number: state.canonhub.accountDetails.data.Number,
                PaymentTerms: state.canonhub.accountDetails.data.PaymentTerms,
                AccountManager:
                state.canonhub.accountDetails.data.AccountManager,
                Member: {
                    FirstName: member?.data?.FirstName,
                    LastName: member?.data?.LastName,
                    Id: member?.data?.Id,
                    Email: member?.data?.Email
                }
            }
            : ''
    return {
        info
    }
}

export default connect(
    mapStateToProps,
    {}
)(Chatbot)
