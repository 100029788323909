import styled, { css } from 'styled-components'
import { colorFromProp } from '../../styles/styled-components/helpers'

export const IconWrapper = styled.span`
    display: inline-block;
    position: relative;
    width: ${props => props.size};
    height: ${props => props.size};
    margin-right: ${props => props.marginRight};
    margin-left: ${props => props.marginLeft};
    vertical-align: ${props => props.verticalAlign};
    color: inherit;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        ${props =>
            props.color &&
            css`
                * {
                    ${props.colorMode
                        ? css`
                              ${props.colorMode}: ${colorFromProp('color')(
                                  props
                              )};
                          `
                        : css`
                              fill: ${colorFromProp('color')(props)};
                              stroke: ${colorFromProp('color')(props)};
                          `} &[fill='none'] {
                        fill: none;
                    }

                    &[stroke='none'] {
                        stroke: none;
                    }
                }
            `};
    }
`
