// @flow

import React, { Component } from 'react'
import {
    ActionContainer,
    Content,
    EmailContent,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Shadow
} from './styles'

type Props = {
    children: Node,
    closeOnClickOutside: boolean,
    closeOnEscapeClick: boolean,
    isOpen: boolean,
    onCancel: () => {},
    onClose: () => {},
    onConfirm: () => {},
    renderFooter?: Object => Node,
    renderHeader?: Object => Node,
    showClose?: boolean,
    type: string,
    width: string,
    hideHeader: boolean
}

type State = {
    isOpenLocal: boolean
}

const ESCAPE_KEY = 27

class Modal extends Component<Props, State> {
    static defaultProps = {
        closeOnClickOutside: true,
        closeOnEscapeClick: true,
        showClose: true,
        width: 'auto'
    }

    componentDidMount() {
        if (this.props.closeOnEscapeClick) {
            window.addEventListener('keydown', this.handleKeyDown)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown)
    }

    handleKeyDown = (e: any) => {
        if (!this.props.isOpen) {
            return
        }

        // escape
        if (e.which === ESCAPE_KEY) {
            this.closeHandler(e)
        }
    }

    closeHandler = (e: SyntheticInputEvent<HTMLElement>) => {
        this.props.onClose()
    }

    confirmHandler = (e: SyntheticInputEvent<HTMLElement>) => {
        this.props.onConfirm()
    }

    cancelHandler = (e: SyntheticInputEvent<HTMLElement>) => {
        this.props.onCancel()
    }

    handleOutsideClick = (e: SyntheticInputEvent<HTMLElement>) => {
        if (!this.props.closeOnClickOutside || e.target !== e.currentTarget) {
            return
        }

        e.preventDefault()
        this.closeHandler(e)
    }

    renderFooter(renderProps: Object) {
        if (typeof this.props.renderFooter === 'function') {
            return this.props.renderFooter(renderProps)
        }

        return null
    }

    renderHeader(renderProps: Object) {
        if (typeof this.props.renderHeader === 'function') {
            return this.props.renderHeader(renderProps)
        }

        return null
    }

    render() {
        // eslint-disable-next-line
        const { type, isOpen, showClose, width, hideHeader } = this.props

        const showActionButtons = type === 'transactional'
        const renderActions = {
            cancelHandler: this.cancelHandler,
            confirmHandler: this.confirmHandler
        }
        //<Shadow isOpen={isOpen} onFocus onLostPointerCapture={this.closeHandler} onClick={this.closeHandler}>
        return (
            <Shadow isOpen={isOpen} >
                <ModalContent isOpen={isOpen} width={width} className="modal">
                    {!hideHeader && (
                        <ModalHeader>
                            {this.renderHeader(renderActions)}
                        </ModalHeader>
                    )}{showActionButtons ?
                        <Content showActionButtons>{this.props.children}</Content>
                        :
                        <EmailContent>{this.props.children}</EmailContent>}
                    {showActionButtons && (
                        <ModalFooter>
                            <ActionContainer>
                                {this.renderFooter(renderActions)}
                            </ActionContainer>
                        </ModalFooter>
                    )}
                    {showClose && (
                        <i
                            className="fal fa-times"
                            aria-hidden="true"
                            onClick={this.closeHandler}
                            onKeyPress={this.closeHandler}
                            role="button"
                            tabIndex="0"
                        />
                    )}
                </ModalContent>
            </Shadow >
        )
    }
}

export default Modal
