import { CALLBACK } from './constants'

// ---------------------------------------------
//   Callback handlers
// ---------------------------------------------
/**
 * @name canCallBack
 * @desc Store the window location to return to from a bookmarked location
 */
export const canCallBack = pathname => {
    const cbURL = localStorage.getItem(CALLBACK) || null
    if (cbURL && cbURL === 'pending') {
        deleteCallBack()
        return null // do nothing if there is a pending redirect
    }
    localStorage.setItem(CALLBACK, window.location.pathname)
}
/**
 * @name deleteCallBack
 * @desc Remove the callback localStorage
 */
export const deleteCallBack = () => {
    localStorage.removeItem(CALLBACK)
}
/**
 * @name willCallBack
 * @desc Check to see if there is a pending callback
 */
export const willCallBack = () => {
    const cbURL = localStorage.getItem(CALLBACK) || null
    if (cbURL === 'pending') {
        deleteCallBack()
        return null // do nothing if there is a pending redirect
    }
    localStorage.setItem(CALLBACK, 'pending')
    return cbURL
}
