/**
 * Canon
 *
 * @package     mcb-front-end
 * @project     Ignite
 * @desc        Main authentication thru STS. Uses code from auth0.com
 *
 */

// -------------------------------------
//   Dependencies: core
// -------------------------------------
import auth0 from 'auth0/index.js'
import axios from 'axios'

import * as actionCreators from 'redux/actions/index.js'
import { store, persistor } from '../redux/store.js'

import PreAuth from './preAuth'
import PostAuth from './postAuth'

import {
	clearIdToken,
	clearAccessToken,
	getIdToken,
	isTokenExpired,
	clearSelectedAndFavAccounts
} from './tokenHandlers'
import { deleteCallBack } from './callBackHandlers'
import { clearPermissions } from './aclHandlers'
import {
	CLIENT_ID,
	CLIENT_DOMAIN,
	ISPROD,
	CORE_URL,
	SCOPE,
	REDIRECT,
	OPENID_CONNECT_MULTIVALUES_RESPONSE_TYPE,
	AUTOSIGN_COOKIE,
	ACR_SFDC,
	RELOGIN_FLAG,
	SELECTED_ACCOUNT
} from './constants'

var auth = new auth0.WebAuth({
	clientID: CLIENT_ID,
	domain: CLIENT_DOMAIN,
	overrides: {
		__tenant: ISPROD ? 'secure' : 'secure',
		__token_issuer: CORE_URL,
		__jwks_uri: `${CORE_URL}/.well-known/jwks`
	}
})

// ---------------------------------------------
//   Authentication handlers
// ---------------------------------------------
/**
 * @name login
 * @desc Redirects user to federated login (STS in our case)
 */
export function login(idp, isReLogin = false) {
	//[sp] invoke the PreAuth Process to clear tokens and purge peristed redux state and set IsAuthenticating flag to true
	PreAuth.process(isReLogin)
	auth.authorize({
		responseType: OPENID_CONNECT_MULTIVALUES_RESPONSE_TYPE,
		redirectUri: REDIRECT,
		scope: SCOPE,
		acr_values: idp ? ACR_SFDC : ''
	})
	if (window.DEBUG) console.log('logn: login', auth)
	//sri - The whole process of authetication has started so set the auth.isAuthenticating state to false.
}
/**
 * @name handleAuthentication
 * @desc Checks the returned package from STS against the sent NONCE and STATE
 */
export function handleAuthentication() {
	auth.parseHash((err, authResult) => {
		if (window.DEBUG) console.log('auth: handle', authResult)
		if (authResult && authResult.accessToken && authResult.idToken) {
			PostAuth.process(authResult)
		} else if (err) {
			console.error(err)
			logout()
		}
	})
}

/**
 * @name logout
 * @desc Clear localStorage and logout session thru STS
 */
export function logout() {
	store.dispatch(actionCreators.setAuth({}))
	clearIdToken()
	clearAccessToken()
	clearPermissions()
	clearSelectedAndFavAccounts()
	// history.replace('/')
	persistor.purge()
	deleteCallBack()
	clearLSAuth()
	//To remove the cookie ,expire it to the
	document.cookie = AUTOSIGN_COOKIE + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.canon.com.au; path=/'
	const redirect =
		'https://' +
		CLIENT_DOMAIN +
		'/endsession?post_logout_redirect_uri=' +
		window.location.origin +
		'&client_id=' +
		CLIENT_ID
	window.location.href = encodeURI(redirect)
}

export function reLoginSSO(accountNumber) {
	store.dispatch(actionCreators.setAuth({}))
	clearIdToken()
	clearAccessToken()
	clearPermissions()
	
	persistor.purge()
	deleteCallBack()
	clearLSAuth()
	//To remove the cookie ,expire it to the
	document.cookie = AUTOSIGN_COOKIE + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.canon.com.au; path=/'

	const redirect =
		'https://' +
		CLIENT_DOMAIN +
		'/endsession?post_logout_redirect_uri=' +
		window.location.origin +
		'&client_id=' +
		CLIENT_ID

	//call redirect as AJAX to get session logout in STS
	axios
    .get(redirect)
    .then(() => {
			login(ACR_SFDC, true)
    })
    .catch(error => {
        if (window.DEBUG) console.timeEnd(redirect)
				if (window.DEBUG) console.log(error)

				const redirect =
						'https://' +
						CLIENT_DOMAIN +
						'/endsession?post_logout_redirect_uri=' +
						window.location.origin + "/" + accountNumber + "?idp=SFDC" +
						'&client_id=' +
						CLIENT_ID
				
				
					window.location.href = encodeURI(redirect)
    })	

	
	
}

/**
 * @name clearLSAuth
 * @desc Clears localStorage associated with the 3rd partys auth0.com
 */
export function clearLSAuth() {
	for (var key in localStorage) {
		if (key.indexOf('com.auth0') === 0) {
			localStorage.removeItem(key)
		}
	}
}
/**
 * @name changePassword
 * @desc Redirect user to STS change password
 */
export function changePassword() {
	const sub = localStorage.getItem('sub')
	if (sub) {
		let redirect =
			'https://' + CLIENT_DOMAIN + '/password/change?returnUrl=' + window.location.href + '&userid=' + sub
		redirect = redirect.replace('/connect', '')
		window.location.href = encodeURI(redirect)
	}
}

export function autoSignInOnLand() {
	const idToken = getIdToken()
	const isTokenExp = isTokenExpired(idToken)
	//[sp] If token is expired / or if not present check for the autosign cookie. If present try doing a autosign in by calling the login method
	if (isTokenExp) {
		if (document.cookie.indexOf(AUTOSIGN_COOKIE) > -1) {
			//do a autologin , since the STS would be holding the XVRF Session token to auto authenticate the request
			// login()
		}
	}
}
/**
 * @name requireAuth
 * @desc
 */
export function requireAuth(nextState, replace) {
	if (!isLoggedIn()) {
		replace({ pathname: '/' })
	}
}
/**
 * @name isLoggedIn
 * @desc
 */
export function isLoggedIn() {
	const idToken = getIdToken()
	const isTokenExp = isTokenExpired(idToken)

	return !!idToken && !isTokenExp
}
