import AuthHOC from './auth-hoc'

import Permissions from './component'
import { checkVisibility } from './core'

import { addRoles, clearRoles, removeRoles } from './actions'

export {
    AuthHOC,
    Permissions,
    addRoles,
    clearRoles,
    removeRoles,
    checkVisibility
}
