import React from 'react'
import MediaQuery from 'react-responsive'
import { css } from 'styled-components'

export const breakpoints = {
    desktop: '(min-width: 800px)',
    mobileTablet: '(max-width: 799px)',
    mobile: '(max-width: 680px)',
    tablet: '(min-width: 681px) and (max-width: 800px)',
    tabletDesktop: '(min-width: 681px)',
    screen: '(min-width: 1400px)',
    averageMobile: '(max-width: 480px)',
    extraSmallMobile: '(max-width: 320px)'
}

const Breakpoint = ({ children, name }) => {
    const breakpoint = name || 'desktop'
    return <MediaQuery query={breakpoints[breakpoint]}>{children}</MediaQuery>
}

export const media = {
    mobileTablet: (...args) => css`
        @media (max-width: 1024px) {
            ${css(...args)};
        }
    `,
    desktop: (...args) => css`
        @media (min-width: 1025px) {
            ${css(...args)};
        }
    `,
    mobile: (...args) => css`
        @media (max-width: 680px) {
            ${css(...args)};
        }
    `,
    screen: (...args) => css`
        @media (min-width: 1400px) {
            ${css(...args)};
        }
    `,
    tablet: (...args) => css`
        @media (min-width: 681px) and (max-width: 1024px) {
            ${css(...args)};
        }
    `,
    extraSmallMobile: (...args) => css`
        @media (max-width: 320px) {
            ${css(...args)};
        }
    `
}

export default Breakpoint

// USAGE
// <Breakpoint name="desktop">desktop</Breakpoint>
// <Breakpoint name="mobileTablet">Mobile</Breakpoint>
