import {Switch} from 'react-router'
import {Route} from 'react-router-dom'
import Loading from 'components/common/loading'
import Loadable from 'react-loadable'
import React from 'react'
import {metadata} from 'app-config'
import {GenericError} from 'components/error-pages'
import ErrorCodes from 'utils/errorCodes'

const AssetOrders = Loadable({
    loader: () => import('./asset-orders-listing/index.js'),
    loading: () => (
        <Loading
            center
            size="40px"
            thickness="1px"
            paddingAround="200px"
            message="Loading Asset Orders"
        />
    )
})

const AssetOrder = Loadable({
    loader: () => import('./asset-order/index.js'),
    loading: () => (
        <Loading
            center
            size="40px"
            thickness="1px"
            paddingAround="200px"
            message="Loading Asset Order"
        />
    )
})


const Routes = () => (
    <Switch>
        <Route
            exact
            path={metadata.assetorders.route}
            component={AssetOrders}
        />
        <Route exact path={metadata.assetorder.route} component={AssetOrder}/>
        <Route
            render={props => {
                return <GenericError errorObj={ErrorCodes.NOT_FOUND}/>
            }}
        />
    </Switch>
)

export default Routes
