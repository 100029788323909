import React, { Component } from 'react'
import { connect } from 'react-redux'
import './home.css'
import LazyLoad from 'lazy-load'
import { gsap } from "gsap/dist/gsap"
import { login } from 'auth'
import AnimateLoad from 'components/common/animate-load'
import { ISNZ } from '../../auth/constants'

class Home extends Component {

    constructor(props) {
        super(props)
        if (props) {
            this.state = {}
        }
        this.cards = []
        this.tl = gsap.timeline({ paused: true })
        this.cycle = 0
    }

    componentDidMount() {
        if (this.props.account) {
        }
        LazyLoad.configureLazyLoadImages()
        this.tl.to(
            this.cards,
            1,
            {
                stagger: {
                    autoAlpha: 1,
                    repeat: 2,
                    yoyo: true,
                    repeatDelay: 20,
                    ease: 'easeInOut'
                }
            },
            21
        ).play()
        // console.log(ISNZ)
        this.setState({
            registerLink: ISNZ ? 'https://www.canon.co.nz/business/register' : 'https://solutions.canon.com.au/register',
            videoLink: ISNZ ? 'https://www.youtube.com/embed/yC3uyCeeZsI' : 'https://www.youtube.com/embed/f4fcyEimMP0?list=PLdjusQgp4g92l5YHr4_QSunzq1uyp-XJM'

        })
        this.cycle = setInterval(this.handleHeroAnimation, 75000)
        document.body.classList.add('home')
    }

    componentWillUnmount() {
        if (this.tl.isActive) {
            this.tl.pause()
            this.tl.kill()
        }
        clearInterval(this.cycle)
        document.body.classList.remove('home')
    }

    render() {
        return (
            <div className="mcb__home">
                <section className="hero posn__rel section-layout-1200 d-flex-aic clearfix">
                    <div
                        className="back-img posn__abs"
                        style={{ backgroundImage: "url('/assets/img/hero-home-1.jpg')" }}
                    />
                    <div
                        className="back-img posn__abs img-hide"
                        style={{ backgroundImage: "url('/assets/img/hero-home-1.jpg')" }}
                        ref={img => (this.cards[0] = img)}
                    />
                    <div
                        className="back-img posn__abs img-hide"
                        style={{ backgroundImage: "url('/assets/img/hero-home-2.jpg')" }}
                        ref={img => (this.cards[1] = img)}
                    />
                    <div
                        className="back-img posn__abs img-hide"
                        style={{ backgroundImage: "url('/assets/img/hero-home-3.jpg')" }}
                        ref={img => (this.cards[2] = img)}
                    />
                    <div className="back-grad posn__abs" />
                    <div className="cols__1--2 px-16 hero-content">
                        <h1>MyCanon Business</h1>
                        <p className="my-16">
                            Manage your accounts with ease from our simple
                            online support hub.
                        </p>
                        <div className="rect-line mb-8" />
                        <div className="fs-title pt-16">Your Business,</div>
                        <div className="fs-title pt-4">Your Time, Your Way</div>
                        <div className="buttons pt-32">
                            <a rel="noopener noreferrer"
                                role="button"
                                tabIndex="0"
                                className="fs-small btn__1-r mr-32 px-32 py-4"
                                onClick={() => login()}
                                onKeyPress={() => login()}
                            >
                                Login
                            </a>
                            <a
                                href={this.state.registerLink}
                                className="fs-small btn__1-r px-32 py-4"
                            >
                                Register
                            </a>
                        </div>
                    </div>
                </section>
                <section className="banner-w-icons posn__rel section-layout-1200 clearfix py-16">
                    <ul className="items text-center fs-small d-flex-jsb">
                        <li className="item">
                            <img
                                src="/assets/img/icon-usage-history.svg"
                                alt=""
                                className=""
                            />
                            <div>View asset usage history</div>
                        </li>
                        <li className="item">
                            <img
                                src="/assets/img/icon-techconnect.svg"
                                alt=""
                            />
                            <div>Schedule & track your technician requests</div>
                        </li>
                        <li className="item">
                            <img
                                src="/assets/img/icon-usage-charge.svg"
                                alt=""
                                className=""
                            />
                            <div>View & pay invoices</div>
                        </li>
                        <li className="item">
                            <img
                                src="/assets/img/icon-consumables.svg"
                                alt=""
                            />
                            <div>Order consumables</div>
                        </li>
                        <li className="item">
                            <img
                                src="/assets/img/icon-troubleshoot.svg"
                                alt=""
                                className=""
                            />
                            <div>View & troubleshoot assets</div>
                        </li>
                    </ul>
                </section>
                <section className="video-content posn__rel section-layout-1200">
                    <div className="text-center fs-title pt-16 pb-32">
                        See what you can do with MyCanon Business & TechConnect
                    </div>
                    <iframe
                        title="mcb"
                        width="100%"
                        height="600"
                        src={this.state.videoLink}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="pt-16 pb-32"
                    />
                </section>
                <section className="banner-techconnect clearfix py-32">
                    <div className="section-layout-1200 d-flex-jc py-32">
                        <div className="lhs">
                            <img
                                src="/assets/img/icon-techconnect-white.svg"
                                alt=""
                                className="icon-tc"
                            />
                        </div>
                        <div className="rhs">
                            <div className="fs-title">
                                Experience{' '}
                                <img
                                    src="/assets/img/icon-techconnect-wtext.svg"
                                    alt=""
                                    className="icon-tc-text"
                                />
                            </div>
                            <p>
                                A handy tool that lets you book and receive
                                status updates on your tech services
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        account: state.account.selectedAccount
    }
}

export default AnimateLoad(
    connect(
        mapStateToProps,
        null
    )(Home)
)
