/**
 * Canon
 *
 * @package     mcb-front-end
 * @project     Ignite
 * @repo
 *
 */

// -------------------------------------
//   Dependencies: core
// -------------------------------------
import React, {Component} from 'react'
import {connect} from 'react-redux'
import history from 'history/history.js'
import {willCallBack} from 'auth'
import Loading from 'components/common/loading/index.js'
import ErrorCodes from 'utils/errorCodes'
import {getLocalAccount} from 'components/common/local-storage/helper.js'

import './singlesignon.css'

const DashboardLoading = () => (
    <div className="dashboard">
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Waiting for account data"
            />
        </div>
    </div>
)

class SSO extends Component {

    render() {
        return <DashboardLoading/>
    }
}

// eslint-disable-next-line
const mapStateToProps = (state, ownProps) => {
    // Sri - We have a redux state named isAuthenticating which is set to true once the authorize process starts by auth0 and set to false by handleaauthentication cb after the /callback call
    // only after the whole authentication is done , redirect to the corresponding pages either the vistied page or the default route dashboard page.

    let canRedirect =
        !state.auth.isAuthenticating && // whole process of auth0 is done
        state.canonhub?.account?.data && // the account data has been retrived and set to state.
        state?.auth?.auth?.accessToken && // the authdata has been retrived and set
        state?.canonhub?.memberInfo?.data // All the member related information and permission relating to the user has been retrieved

    //[sp] check if there is an error during the account api call process, in that case throw error
    if (!state?.canonhub?.account?.loading && state?.canonhub?.account?.error) {
        canRedirect = false
        throw ErrorCodes.GENERIC
    } else if (!state?.canonhub?.account?.loading && state?.canonhub?.account?.data) {
        if (
            !state?.canonhub?.account?.data?.Data ||
            state?.canonhub?.account?.data?.Data?.length === 0
        ) {
            canRedirect = false
            throw ErrorCodes.NOT_AUTHORIZED
        }
    }

    if (canRedirect) {
        //Sri- first check if this call for a redirect, in case the callback key in the localstorage is not empty and redirect to that link
        const callBackURL = willCallBack()
        //sri - remove the callback url irrespectivce of what
        localStorage.removeItem('callback')
        if (callBackURL && (callBackURL !== '/signout' || callBackURL !== '/accesserror' || callBackURL !== '/accesserrorsts')) history.replace(callBackURL)
        //MH temp fix | MCB-820:users redirected to /signout on signin after SSO changes
        else if (state.canonhub.account.data.Data.length > 0) {
            //else if there is no callback url in localstorage then refirect to the dashboard landing page- default redirect
            //[ch] temporary solution before hooking up with persist:root
            // localStorage.setItem(
            //   'member_dashboard',
            //   '/dashboard/' + state?.canonhub?.memberInfo.data.Accounts[0].Number
            // )
            setTimeout(() => {
                history.replace(
                    `/${getLocalAccount(
                        state.canonhub.account.data.Data[0].Number
                    )}`
                )
            }, 100)
        } else {
            history.replace('/')
        }
    }
    return {}
}

export default connect(
    mapStateToProps,
    {}
)(SSO)
