import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {handleAuthentication} from 'auth'
import {
    Assets,
    MeterReading,
    Landing,
    Users,
    Contacts,
    Billing,
    Orders,
    Service,
    Contact,
    ContactUs,
    Safety,
    SafetyBulk,
    SafetyTemplates,
    SafetyFormTemplate,
    NetworkTemplates,
    NetworkFormTemplate,
    SafetyForm,
    Network,
    NetworkForm,
    NetworkBulk,
    GenerateReports,
    ReportsDocuments,
    ServiceDirect
} from 'loader'
import PrivateRoute from './private-route'
import SingleSignOnCallBack from 'features/auth/sso/index.js' //SSO thru STS callback
import Home from 'features/home'
import {ErrorBoundary} from 'components/error-pages'
import {metadata} from 'app-config'
import {GenericError} from 'components/error-pages'
import ErrorCodes from 'utils/errorCodes'
import TermsAndConditions from 'components/common/terms-conditions/terms-conditions.js'

const handleNewAuthentication = (nextState, replace) => {
    //if (/access_token|id_token|error/.test(nextState.location.hash)) {
    handleAuthentication()
    //}
}

/* [sp] - couldn't figure out best place other than here to have these HOC wrapper. 
Having generically in the render prop of the react router is causing the CompoenentAttached to the router mount always the router prop changes.
- Also This is will be the best place to have nested HOC wrappers */
const AssetsWrapper = TermsAndConditions(Assets)
const MeterReadingWrapper = TermsAndConditions(MeterReading)
const LandingWrapper = TermsAndConditions(Landing)
const UsersWrapper = TermsAndConditions(Users)
const ContactsWrapper = TermsAndConditions(Contacts)
const GenerateReportsWrapper = TermsAndConditions(GenerateReports)
const ReportsDocumentsWrapper = TermsAndConditions(ReportsDocuments)
const BillingWrapper = TermsAndConditions(Billing)
const OrdersWrapper = TermsAndConditions(Orders)
const ContactWrapper = TermsAndConditions(Contact)
const ContactUsWrapper = TermsAndConditions(ContactUs)
//const ServiceWrapper = TermsAndConditions(Service)
const ServiceWrapper = Service
const ServiceDirectWrapper = ServiceDirect
const SafetyWrapper = TermsAndConditions(Safety)
const SafetyFormWrapper = TermsAndConditions(SafetyForm)
const SafetyBulkWrapper = TermsAndConditions(SafetyBulk)
const SafetyTemplatesWrapper = TermsAndConditions(SafetyTemplates)
const SafetyFormTemplateWrapper = TermsAndConditions(SafetyFormTemplate)
const NetworkTemplatesWrapper = TermsAndConditions(NetworkTemplates)
const NetworkFormTemplateWrapper = TermsAndConditions(NetworkFormTemplate)

const NetworkWrapper = TermsAndConditions(Network)
const NetworkFormWrapper = TermsAndConditions(NetworkForm)
const NetworkBulkWrapper = TermsAndConditions(NetworkBulk)

const Routes = () => (
    <ErrorBoundary>
        {/** [sp] Generic error boundary for all routes/ app using the componentDidCatch lifecyle call in the ErrorBoundary component **/}
        <Switch>
            <Route
                path={metadata.callback.route}
                exact
                render={props => {
                    handleNewAuthentication(props)
                    return <SingleSignOnCallBack {...props} />
                }}
            />{' '}
            
            <PrivateRoute
                path={metadata.users.route}
                component={UsersWrapper}
                permissions={metadata.users.permissions}
            />
            <PrivateRoute
                path={metadata.contacts.route}
                component={ContactsWrapper}
                permissions={metadata.users.permissions}
            />
            <PrivateRoute
                path={metadata.generatereports.route}
                component={GenerateReportsWrapper}
                permissions={metadata.generatereports.permissions}
            />
            <PrivateRoute
                path={metadata.reportsDocuments.route}
                component={ReportsDocumentsWrapper}
                permissions={metadata.reportsDocuments.permissions}
            />
            <PrivateRoute
                path={metadata.submitMeterReadings.route}
                component={MeterReadingWrapper}
                permissions={metadata.submitMeterReadings.permissions}
            />
            <PrivateRoute
                path={metadata.assets.route}
                component={AssetsWrapper}
                permissions={metadata.assets.permissions}
            />
            <PrivateRoute
                path={metadata.orders.route}
                component={OrdersWrapper}
                permissions={metadata.orders.permissions}
            />
            <PrivateRoute
                path={metadata.contact.route}
                component={ContactWrapper}
                permissions={metadata.contact.permissions}
            />
            <PrivateRoute
                path={metadata.contactus.route}
                component={ContactUsWrapper}
                permissions={metadata.contact.permissions}
            />
            <PrivateRoute
                path={metadata.billing.route}
                component={BillingWrapper}
                permissions={metadata.billing.permissions}
            />
            {/*
			SP - Service route is no longer authenticated
			becuase the unauthenticated salted service order route is a nested child of service,
			instead check for private routes/ t&c's  at service feature routes
			<PrivateRoute
				path={metadata.service.route}
				component={ServiceWrapper}
				permissions={metadata.service.permissions}
			/>*/}
            <Route
                path={metadata.service.route}
                component={ServiceWrapper}
                permissions={metadata.service.permissions}
            />
            <Route
                exact
                path={metadata.serviceDirect.route}
                component={ServiceDirectWrapper}
                permissions={metadata.serviceDirect.permissions}
            />
            <Route
                path={metadata.service.route}
                component={ServiceWrapper}
                permissions={metadata.service.permissions}
            />
            <PrivateRoute
                path={metadata.safetyformlist.route}
                component={SafetyWrapper}
                permissions={metadata.safetyformlist.permissions}
            />
            <PrivateRoute
                path={metadata.safetyformbulk.route}
                component={SafetyBulkWrapper}
                permissions={metadata.safetyformbulk.permissions}
                exact
            />
            <PrivateRoute
                path={metadata.safetyformtemplates.route}
                component={SafetyTemplatesWrapper}
                permissions={metadata.safetyformtemplates.permissions}
                exact
            />
            <PrivateRoute
                path={metadata.networkformtemplates.route}
                component={NetworkTemplatesWrapper}
                permissions={metadata.networkformtemplates.permissions}
                exact
            />
            <PrivateRoute
                path={metadata.editbulksafetyform.route}
                component={SafetyBulkWrapper}
                permissions={metadata.editbulksafetyform.permissions}
                exact
            />
            <PrivateRoute
                path={metadata.networkformlist.route}
                component={NetworkWrapper}
                permissions={metadata.networkformlist.permissions}
            />
            <PrivateRoute
                path={metadata.networkformbulk.route}
                component={NetworkBulkWrapper}
                permissions={metadata.networkformbulk.permissions}
                exact
            />
            <PrivateRoute
                path={metadata.editbulknetworkform.route}
                component={NetworkBulkWrapper}
                permissions={metadata.editbulknetworkform.permissions}
                exact
            />
            <PrivateRoute
                path={metadata.safetyform.route}
                component={SafetyFormWrapper}
                permissions={metadata.safetyform.permissions}
            />
            <PrivateRoute
                path={metadata.safetyformtemplate.route}
                component={SafetyFormTemplateWrapper}
                permissions={metadata.safetyformtemplate.permissions}
            />
            <PrivateRoute
                path={metadata.networkformtemplate.route}
                component={NetworkFormTemplateWrapper}
                permissions={metadata.networkformtemplate.permissions}
            />
            <PrivateRoute
                path={metadata.networkform.route}
                component={NetworkFormWrapper}
                permissions={metadata.networkform.permissions}
            />
            <PrivateRoute
                path={metadata.accesserror.route}
                exact
                component={() => (
                    <GenericError errorObj={ErrorCodes.ACCESS_DENIED}/>
                )}
                permissions={metadata.accesserror.permissions}
            />
            <PrivateRoute
                path={metadata.accesserrorsts.route}
                exact
                component={() => (
                    <GenericError errorObj={ErrorCodes.ACCESS_DENIED_STS}/>
                )}
                permissions={metadata.accesserrorsts.permissions}
            />
            <Route
                path={metadata.ordercomplete.route}
                exact
                component={() => (
                    <GenericError errorObj={ErrorCodes.ORDER_COMPLETE}/>
                )}
                // permissions={metadata.ordercomplete.permissions}
            />
            <PrivateRoute
                path={metadata.landing.route}
                exact
                component={LandingWrapper}
                permissions={metadata.landing.permissions}
            />
            {/* new unsecured route */}
            <Route path="/" exact component={Home}/>
            <Route
                render={props => {
                    return <GenericError errorObj={ErrorCodes.NOT_FOUND}/>
                }}
            />
        </Switch>
    </ErrorBoundary>
)
export default Routes
