/**
 * Canon
 *
 * @package     mcb-front-end
 * @project     Ignite
 * @repo
 * @desc        Default setup of the store (redux)
 *
 */

// -------------------------------------
//   Dependencies: core
// -------------------------------------
import { applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers/index.js'
import { persistStore } from 'redux-persist'
import StoreManager from './store-manager'

// -------------------------------------
//   Local constants: yes not really necesssary
// -------------------------------------
const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              // SP - should not be fired more than once after hot reloading - Was resetting the whole app state to its initial state
              // Prevent recomputing reducers for `replaceReducer`
              shouldHotReload: false
          })
        : compose

StoreManager.registerReducers(reducers)
const store = StoreManager.createStore(composeEnhancers(applyMiddleware(thunk)))
const persistor = persistStore(store)

export { store, persistor }
