//Check if atleast one of the route level or component level permission matches to what is present as part of permissions for the user
export const checkVisibility = (
    userPermissions,
    routeOrComponentPermissions
) => {
    if (
        !routeOrComponentPermissions ||
        routeOrComponentPermissions.length === 0
    )
        return true
    else if (!userPermissions || userPermissions.length === 0) return false
    return routeOrComponentPermissions.some(val =>
        userPermissions.includes(val)
    )
}

export default {
    checkVisibility
}
