import Loading from './components/common/loading'
import Loadable from 'react-loadable'
import React from 'react'
import Assets from 'features/assets'
import Billing from 'features/billing'
import Landing from 'features/landing'
import Orders from 'features/orders'

const Users = Loadable({
    loader: () => import('features/users'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Users"
            />
        </div>
    )
})
const Contacts = Loadable({
    loader: () => import('features/contacts'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Contacts"
            />
        </div>
    )
})
const Contact = Loadable({
    loader: () => import('features/contact'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Users"
            />
        </div>
    )
})
const ContactUs = Loadable({
    loader: () => import('features/contact'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Users"
            />
        </div>
    )
})
const Safety = Loadable({
    loader: () => import('features/safety'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Safety Form Data"
            />
        </div>
    )
})
const Network = Loadable({
    loader: () => import('features/network'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Network Form Data"
            />
        </div>
    )
})
const SafetyForm = Loadable({
    loader: () => import('features/safety/safety-form'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Safety Form Data"
            />
        </div>
    )
})
const NetworkForm = Loadable({
    loader: () => import('features/network/network-form'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Network Form Data"
            />
        </div>
    )
})
const NetworkBulk = Loadable({
    loader: () => import('features/network/network-listing/bulk-listing'),

    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Network Form Data"
            />
        </div>
    )
})
const MeterReading = Loadable({
    loader: () => import('features/meterReading'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading MeterReading Form Data"
            />
        </div>
    )
})

const SafetyBulk = Loadable({
    loader: () => import('features/safety/safety-listing/bulk-listing'),

    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Safety Form Data"
            />
        </div>
    )
})
const SafetyTemplates = Loadable({
    loader: () => import('features/safety/safety-listing/template-listing'),

    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Safety Form Templates"
            />
        </div>
    )
})
const SafetyFormTemplate = Loadable({
    loader: () => import('features/safety/safety-form/safety-template'),

    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Safety Form Template"
            />
        </div>
    )
})

const NetworkTemplates = Loadable({
    loader: () => import('features/network/network-listing/template-listing'),

    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Network Form Templates"
            />
        </div>
    )
})
const NetworkFormTemplate = Loadable({
    loader: () => import('features/network/network-form/network-template'),

    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Network Form Template"
            />
        </div>
    )
})

const Service = Loadable({
    loader: () => import('features/service'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Services"
            />
        </div>
    )
})

const ServiceDirect = Loadable({
    loader: () => import('features/service/tech-access-forms'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Installer Documentation"
            />
        </div>
    )
})

const ReportsDocuments = Loadable({
    loader: () => import('features/reports/reportsDocuments'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading View Reports Documents"
            />
        </div>
    )
})

const GenerateReports = Loadable({
    loader: () => import('features/reports/generateReports'),
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                center
                size="40px"
                thickness="1px"
                paddingAround="200px"
                message="Loading Generate Reports"
            />
        </div>
    )
})

export {
    Assets,
    MeterReading,
    Landing,
    Users,
    Contacts,
    Billing,
    Orders,
    Service,
    Contact,
    ContactUs,
    Safety,
    SafetyForm,
    SafetyBulk,
    SafetyTemplates,
    SafetyFormTemplate,
    NetworkForm,
    Network,
    NetworkBulk,
    GenerateReports,
    ReportsDocuments,
    ServiceDirect,
    NetworkTemplates,
    NetworkFormTemplate
}
