import {metadata} from 'app-config'

const tiles = [
    {
        title: 'Account',
        icon: 'file-invoice',
        links: [
            {href: '/users', ...metadata.users},
            {href: '/contacts', ...metadata.contacts},
            {href: '/billing', ...metadata.billing}
        ]
    },
    {
        title: 'Service & Support',
        icon: 'handshake',
        links: [
            {
                href: '/service/orders/request',
                ...metadata.serviceOrderRequest
            },
            {href: '/service/orders', ...metadata.serviceOrders},
            {
                href: '/service/relocations/form',
                ...metadata.relocationRequestForm
            },
            {href: '/safetyformlist', ...metadata.safetyformlist},
            {href: '/networkformlist', ...metadata.networkformlist}
        ]
    },
    {
        title: 'Orders',
        icon: 'truck',
        links: [
            {
                href: '/orders/consumables/request',
                ...metadata.consumablesRequest
            },
            {href: '/orders/consumables/', ...metadata.consumables},
            {href: '/orders/assets/', ...metadata.assetorders}
        ]
    },
    {
        title: 'Assets',
        icon: 'box',
        links: [
            {href: '/assets', ...metadata.assets},
            {href: 'https://www.canon.com.au/business/support/certificates%20of%20currency', ...metadata.documentsInsurance},
            {href: 'https://solutions.canon.co.nz/professional-indemnity-liability', ...metadata.professionalIndemnityLiabilityNZ},
            {href: 'https://solutions.canon.co.nz/public-and-products-liability', ...metadata.publicAndProductsLiabilityNZ},
            {
                href: '/assets/meter-reader',
                ...metadata.submitMeterReadings
            },
            {
                href: 'https://readings.canon.co.nz/',
                ...metadata.submitMeterReadingsNZ
            },
            {href: '/assets/status', ...metadata.assetStatus},
        ]
    },
    {
        title: 'News & Resources',
        icon: 'lightbulb',
        links: [
            {
                href: 'https://www.canon.com.au/businessinsights',
                ...metadata.businessInsightsAU
            },
            {
                href: 'https://www.canon.co.nz/businessinsights',
                ...metadata.businessInsightsNZ
            },
            {
                href: 'https://www.youtube.com/playlist?list=PLdjusQgp4g92l5YHr4_QSunzq1uyp-XJM',
                ...metadata.trainingAU
            },
            {
                href: 'https://www.youtube.com/playlist?list=PLTzLyJTlVEnZgWSDzBGog80dgSa6YbL86',
                ...metadata.trainingNZ
            },
            {
                href: 'https://print.canon.co.nz/print/',
                ...metadata.subscribeNZ
            },
            {
                href: 'https://solutions.canon.com.au/l/819603/2020-06-17/gtyq/819603/23317/Good_Hygiene_A3_POSTER.pdf',
                text: 'Download COVID-19 Printer Hygiene Poster',
                permissions: [
                    "HygienePosterAU.Read"
                ]
            },
            {
                href: 'http://mybusiness.canon.co.nz/training',
                ...metadata.productTrainingNZ
            },
            {
                href: 'https://canonmcb.blob.core.windows.net/extras/GoodHygiencePoster.pdf',
                text: 'Download COVID-19 Printer Hygiene Poster',
                permissions: [
                    "HygienePosterNZ.Read"
                ]
            },
            {
                href: 'https://www.canon.co.nz/business/offers',
                text: 'Subscribe to receive new content and offers',
                permissions: [
                    "HygienePosterNZ.Read"
                ]
            },
        ]
    },
    {
        title: 'Reports & Documents',
        icon: 'file-invoice',
        links: [
            {
                href: '/reports/generatereports',
                ...metadata.generatereports
            },
            {
                href: '/reports/view-reportsDocuments',
                ...metadata.reportsDocuments
            }
        ]
    }
]

export default tiles
