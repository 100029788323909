// CANON HUB: ACCOUNTS
export const UPDATE_ACCOUNTS = 'accts::update'
export const UPDATE_ACCOUNTS_DETAILS = 'accts::update-details'
export const UPDATE_ACCOUNTS_ACCESS = 'accts::update-access'
export const UPDATE_SELECTED_ACCOUNT = 'accts::update-selectedaccount'
export const CLEAR_ACCOUNTS = 'accts::clear'
export const STORE_SELECTED_ACCOUNT = 'accts::store-selected'

export const UPDATE_SELECTED_DEVICE = 'accts::update-selecteddevice'

export const UPDATE_SEARCH_OPTIONS = 'accts::update-search-options'
export const UPDATE_PAGING_SETTINGS = 'accts::update-paging-settings'

export const UPDATE_HEADER_ACCOUNT_LIST_REQUEST =
    'accts::update-header-accountlist::request'
export const UPDATE_HEADER_ACCOUNT_LIST_SUCCESS =
    'accts::update-header-accountlist::success'
export const UPDATE_HEADER_ACCOUNT_LIST_FAILURE =
    'accts::update-header-accountlist::failure'

// CANON HUB: SERVICE ORDERS
export const UPDATE_SELECTED_SERVICEORDER = 'accts::update-selectedserviceorder'

// MEMBER INFO. NOT DOCUMENTED IN CH/SWAGGER
export const UPDATE_MEMBER_INFO = 'member::update-info'

// AUTHENTICATE
export const UPDATE_AUTH = 'auth::update'
export const IS_AUTHENTICATING = 'auth::is-authenticating'

// ABORT A FETCH. USED TO CACHE CALLS AGAINST CANONHUB
export const ABORT_FETCH_HUB = 'fetch::abort::hub'

//METADATA
export const UPDATE_METADATA = 'config::metadata'

//ACL
export const UPDATE_ACL = 'config::acl'

//DSF
export const UPDATE_DSF_SUCCESS = 'config::dsf-success'
export const UPDATE_DSF_REQUEST = 'config::dsf-request'
export const UPDATE_DSF_ERROR = 'config::dsf-error'
