import React from 'react'
import './billing.css'
import Routes from './routes'
import AnimateLoad from 'components/common/animate-load'

const Billing = () => (
    <div className="mcb__billing">
        <div className="dd-flex section-layout-1200">
            <Routes />
        </div>
    </div>
)

export default AnimateLoad(Billing)
