import axios from 'axios'
import config from 'config.json'

class TermsAndConditionsApi {
    static fetchAcceptTermsAndConditions = memberId => {
        let baseUrl = `${config.api}members/${memberId}/terms?terms=MCB`
        if (window.DEBUG) console.log(baseUrl)
        if (window.DEBUG) console.time(baseUrl)
        return axios
            .get(baseUrl)
            .then(response => response.data)
            .then(data => {
                if (window.DEBUG) console.timeEnd(baseUrl)
                return data
            })
            .catch(error => {
                if (window.DEBUG) console.timeEnd(baseUrl)
                throw error
            })
    }
    static putAcceptTermsAndConditions = memberId => {
        let baseUrl = `${config.api}members/${memberId}/terms`
        if (window.DEBUG) console.log(baseUrl)
        if (window.DEBUG) console.time(baseUrl)
        return axios
            .put(baseUrl, { TermsName: 'MCB' })
            .then(response => response.data)
            .then(data => {
                if (window.DEBUG) console.timeEnd(baseUrl)
                return data
            })
            .catch(error => {
                if (window.DEBUG) console.timeEnd(baseUrl)
                throw error
            })
    }
}

export default TermsAndConditionsApi
