import * as actionTypes from '../actions/actionTypes.js'
import {demoDsfDevices} from "../../features/assets/asset-status/asset-status.helper";

const initialState = {
    token: null,
    devices: [],
    lastFetch: 0,
    tenantId: null,
    loading: false,
    error: null,
}


const dsf = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DSF_SUCCESS:

            const dsfDevices = action.data?.response?.devices?.length ? action.data?.response?.devices.map((device) => ({
                deviceSerialNo: device.Serial,
                deviceName: `${device.Serial} ${device?.Address?.Suburb}-${device?.Address?.State}`,
                location: `${device?.Address?.AddressLine1 ?? ''} ${device?.Address?.AddressLine2 ?? ''} ${device?.Address?.AddressLine3 ?? ''} ${device?.Address?.AddressLine4 ?? ''} ${device?.Address?.Suburb ?? ''} ${device?.Address?.Postcode ?? ''} ${device?.Address?.State ?? ''}`.replace(/ +(?= )/g, '')
            })) : []


            const tempState = {
                ...state,
                token: action.data?.response?.token?.length ? action.data?.response?.token : state.token,
                devices: dsfDevices,
                loading: false,
                error: null,
                lastFetch: Date.now(),
            }

            return tempState

        case actionTypes.UPDATE_DSF_REQUEST:

            return {
                ...state,
                loading: true,
            }

        case actionTypes.UPDATE_DSF_ERROR:
            return {
                ...state,
                loading: false,
                error: action.data?.response,
            }
        default: {
            return state
        }
    }
}

export default dsf
