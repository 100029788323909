import * as actionTypes from '../actions/actionTypes.js'

const initialState = {
    metadata: {}
}

const metadata = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_METADATA:
            return {
                ...action.data
            }
        default: {
            return state
        }
    }
}

export default metadata
