import styled, { css } from 'styled-components'
import { color } from '../../../styles/styled-components/helpers'
import { buttonIncognito } from '../../../styles/styled-components/common'
import { fadeIn, fadeOut } from '../../../styles/styled-components/animations'

export const Shadow = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    z-index: ${props => props.theme.modal.zIndex - 1};
    transition: opacity 0.4s, height 0s 0.4s, width 0s 0.4s;
    transition-timing-function: ease-out;
    background-color: ${props => props.theme.modal.backdropColor};
    pointer-events: none;
    visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};

    ${props =>
        props.isOpen &&
        css`
            width: 100%;
            height: 100%;
            opacity: 1;
            transition: opacity 0.4s ease-out;
            pointer-events: all;
        `};
`

export const ModalContent = styled.div`
    width: ${props => props.width};
    max-width: 80%;

    min-height: 200px;
    max-height: 80%;
    margin: 0 auto;
    position: relative;
    min-width: 350px;
    overflow: hidden;
    visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
    animation: ${props => (props.isOpen ? fadeIn : fadeOut)} 0.3s
        ${props => props.theme.easing['cubic-out']};
    transition: visibility 0.3s ${props => props.theme.easing['cubic-out']};
    background-color: ${props => props.theme.color.white};
    border-radius: 4px;
    .fa-times {
        position: absolute;
        top: 5px;
        right: 12px;
        font-size: 1.2rem !important;
        color: #4c4c4b;
        opacity: 0.7;
    }
    .fa-times:hover {
        opacity: 1;
        cursor: pointer;
    }
`

export const EmailContent = styled.div`
    padding: 1rem;
    overflow-y: auto;
    padding-bottom: '41px'};
`

export const Content = styled.div`
    padding: 1rem;
    overflow-y: auto;
    padding-bottom: ${props => (props.showActionButtons ? '60px' : '40px')};
`

export const ModalHeader = styled.div`
    border-bottom: 1px solid ${props => props.theme.color.border};
    padding: 1rem 1rem 0px;
`

export const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1rem 1rem;
    justify-content: flex-end;

    button {
        margin: 0 10px;
        min-width: 100px;
    }
    
    .fullButton {
        height: 100%;
        width: 100%;
    }
`

export const ModalFooter = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: ${props => props.theme.color.white};
`

export const CloseButton = styled.button.attrs({
    type: 'button'
})`
    ${buttonIncognito} position: absolute;
    right: 20px;
    top: 20px;
    color: ${color('white')};
`
