import React, {Component} from 'react'
import './App.css'
import Header from 'components/common/header_v2/index.js'
import Footer from 'components/common/footer/index.js'
import createHistory from 'history/createBrowserHistory';
import Routes from 'routes'
import Chatbot from 'features/chatbot/index.js'
import {CircleArrow as ScrollUpButton} from 'components/back-to-top'

const history = createHistory();

class App extends Component {
    render() {
        return (
            <div className="app">
                <Header history={history}/>
                <Routes/>
                <Chatbot/>
                <ScrollUpButton/>
                <Footer/>
            </div>
        )
    }
}

export default App
