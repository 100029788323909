import axios from 'axios'
// import config from 'config.json'

class ChatBot {
    static getResponse = query => {
        //       .get(`http://172.17.136.6:5001/chatbot?queryText=${query}`)
        return axios
            .get(`https://uatapi.canon.com.au/hub/v2/gcp/query?q=${query}`, {
                heads: {
                    Source: 'chatbot'
                }
            })
            .then(response => {
                return response.data
            })
            .then(data => {
                return data
            })
            .catch(error => {
                throw error
            })
    }
}

export default ChatBot

//

// let options = {
//   method: 'GET',
//   headers: {
//     'Content-Type': 'application/json; charset=utf-8',
//     Authorization:
//       'Bearer ya29.c.ElrOBnYIYdwI3g-9spIypAuXoGGcpfumN8idxIjf7lb-LUL8OVAEPG9dXcTX4lu0IwMcuOS-uL4lF4hhxNsC5mmj3eQ4bKwKsuX8Z4E4-xIgeA2Kx-Sd3AcE-88'
//   },
//   data:
//     '{"queryInput":{"text":{"text":"book a service","languageCode":"en"}},"queryParams":{"timeZone":"Australia/Sydney"}}',
//   url:
//     'https://dialogflow.googleapis.com/v2beta1/projects/canvas-dev-abeda/agent/sessions/be0ec7a3-e26b-1533-49b1-93dade57f48d:detectIntent'
// }
// axios
//   .get(
//     'https://dialogflow.googleapis.com/v2beta1/projects/canvas-dev-abeda/agent/sessions/be0ec7a3-e26b-1533-49b1-93dade57f48d:detectIntent',
//     {
//       queryInput: {
//         text: {
//           text: 'book a servce',
//           languageCode: 'en'
//         },
//         queryParams: {
//           timeZone: 'Australia/Sydney'
//         }
//       }
//     }
//   )
//   .then(function(response) {
//     console.log(response)
//   })
//   .catch(function(error) {
//     console.log(error)
//   })
//   .then(function() {
//     // always executed
//   })
// return axios({
//   options
// })
//   .then(response => {
//     console.log(response)
//     return response.data
//   })
//   .catch(error => {
//     throw error
//   })
// return axios
//   .get(`https://dialogflow.googleapis.com/v2beta1/projects/canvas-dev-abeda/agent/sessions/be0ec7a3-e26b-1533-49b1-93dade57f48d:detectIntent`, query)
//   .then(response => {
//     console.log(response)
//     return response.data
//   })
//   .then(data => {
//     return data
//   })
//   .catch(error => {
//     throw error
//   })

// const thing = {
//  queryInput: { text: { text: 'book a service', languageCode: 'en' } },
//  queryParams: { timeZone: 'Australia/Sydney' }
// }
// var request = require('request');

// var headers = {
//     'Content-Type': 'application/json; charset=utf-8',
//     'Authorization': 'Bearer ya29.c.ElrOBnYIYdwI3g-9spIypAuXoGGcpfumN8idxIjf7lb-LUL8OVAEPG9dXcTX4lu0IwMcuOS-uL4lF4hhxNsC5mmj3eQ4bKwKsuX8Z4E4-xIgeA2Kx-Sd3AcE-88'
// }

// var dataString = '{"queryInput":{"text":{"text":"book a service","languageCode":"en"}},"queryParams":{"timeZone":"Australia/Sydney"}}'

// var options = {
//     url: 'https://dialogflow.googleapis.com/v2beta1/projects/canvas-dev-abeda/agent/sessions/be0ec7a3-e26b-1533-49b1-93dade57f48d:detectIntent',
//     method: 'POST',
//     headers: headers,
//     body: dataString
// }

// let options = {
//  method: 'GET',
//  headers: {
//   'Content-Type': 'application/json; charset=utf-8',
//   'Authorization': 'Bearer ya29.c.ElrOBnYIYdwI3g-9spIypAuXoGGcpfumN8idxIjf7lb-LUL8OVAEPG9dXcTX4lu0IwMcuOS-uL4lF4hhxNsC5mmj3eQ4bKwKsuX8Z4E4-xIgeA2Kx-Sd3AcE-88'
// },
//  data: '{"queryInput":{"text":{"text":"book a service","languageCode":"en"}},"queryParams":{"timeZone":"Australia/Sydney"}}',
//  url: 'https://dialogflow.googleapis.com/v2beta1/projects/canvas-dev-abeda/agent/sessions/be0ec7a3-e26b-1533-49b1-93dade57f48d:detectIntent'
// }

// request(options, callback)

// axios({
//  baseURL: 'https://api.shutterstock.com/v2/images/search?query=donkey&page=1&per_page=1',
//  auth: { username: 'CLIENT_ID', password: 'CLIENT_SECRET'},
//  params: { query: 'donkey', page: '1', per_page: '1' }
// }).then(res => console.log(res));

// curl -H "Content-Type: application/json; charset=utf-8"  -H
// "Authorization: Bearer ya29.c.ElrOBnYIYdwI3g-9spIypAuXoGGcpfumN8idxIjf7lb-LUL8OVAEPG9dXcTX4lu0IwMcuOS-uL4lF4hhxNsC5mmj3eQ4bKwKsuX8Z4E4-xIgeA2Kx-Sd3AcE-88"  -d
// "{\"queryInput\":{\"text\":{\"text\":\"book a service\",\"languageCode\":\"en\"}},\"queryParams\":{\"timeZone\":\"Australia/Sydney\"}}"
// ""
