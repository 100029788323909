import * as actionTypes from '../actionTypes.js'
import axios from 'axios'
import config from 'config.json'
import {TIME_LIMT_FOR_FETCH} from 'utils/constants'
import {RELOGIN_FLAG, STORED_ACCOUNT} from '../../../auth/constants.js'
import {DEVICE_DETAILS_ARRAY} from "../../../features/assets/asset-status/asset-status.helper";

const getUrl = payload => {
    switch (payload.type) {


        case actionTypes.UPDATE_ACCOUNTS:
            return config.accountsApi

        case actionTypes.UPDATE_SELECTED_SERVICEORDER:
            return `${config.accountsApi}${payload.accountNumber}/serviceOrders/${payload.serviceOrder}/?createdFrom=2018-01-01`

        case actionTypes.UPDATE_MEMBER_INFO: {
            return `${config.api}members/${payload.memberId}?businessUser=true`
        }

        default:
            return null
    }
}

const lastFetch = (payload, state) => {
    switch (payload.type) {

        case actionTypes.UPDATE_ACCOUNTS:
            return state.account.lastFetch

        case actionTypes.UPDATE_SELECTED_SERVICEORDER:
            return state.deviceServiceOrders.lastFetch
        case actionTypes.UPDATE_MEMBER_INFO:
            return state.memberInfo.lastFetch

        default:
            return 0
    }
}

const getDataPath = (payload, data) => {
    switch (payload.type) {
        case actionTypes.UPDATE_ACCOUNTS:
            return data.Payload.Account

        case actionTypes.UPDATE_SELECTED_SERVICEORDER:
            return data.Payload.ServiceOrder

        case actionTypes.UPDATE_MEMBER_INFO:
            return data.Payload.Member

        default:
            return data
    }
}

export const getCanonHub = payload => {

    return (dispatch, getState) => {
        const timeSinceLastFetch = lastFetch(payload, getState().canonhub)
        const axPost = !!payload.params
        const tempUrl = getUrl(payload)
        const url = window.isPROD ? tempUrl.replace('uat', '') : tempUrl
        const isDataStale = payload.force ? true : Date.now() - timeSinceLastFetch > TIME_LIMT_FOR_FETCH // 5minutes in ms

        if (window.DEBUG) {
            console.log('chub:', payload)
            console.log(axPost ? 'post:' : 'get:', url, 'stale:', isDataStale)
        }

        if (isDataStale) {

            dispatch({
                type: payload.type,
                data: {
                    lastFetch: Date.now(),
                    loading: true
                }
            })

            if (axPost) {
                if (window.DEBUG) console.time(url)
                return axios
                    .post(url, payload.params)
                    .then((response) => {
                        console.timeEnd(url)
                        //CALL THE API
                        dispatch(
                            {
                                type: payload.type,
                                data: {
                                    data: getDataPath(payload, response.data),
                                    lastFetch: Date.now(),
                                    loading: false
                                }
                            }
                        )
                    })
                    .catch((error) => {
                        if (window.DEBUG) console.timeEnd(url)
                        dispatch(
                            {
                                type: payload.type,
                                data: {
                                    error: error.data,
                                    lastError: Date.now(),
                                    loading: false
                                }

                            }
                        )
                    })
            }
            //NOT AX POST
            else {
                if (window.DEBUG) console.time(url)
                return axios
                    .get(url)
                    .then((response) => {
                        if (window.DEBUG) console.timeEnd(url)
                        if (response?.data?.Error?.Code !== 10106) {
                            localStorage.removeItem(RELOGIN_FLAG);
                            localStorage.removeItem(STORED_ACCOUNT)
                        }

                        dispatch(
                            {
                                type: payload.type,
                                data: {
                                    data: response?.data?.Error?.Code ? null : getDataPath(payload, response.data),
                                    lastFetch: Date.now(),
                                    loading: false,
                                    pageNo: payload.pageNo,
                                    hubError: response?.data?.Error?.Code
                                }

                            }
                        )
                    })
                    .catch((errorResponse) => {
                        if (window.DEBUG) console.timeEnd(url)
                        // need to do something here when get 403 error. this means the api end point is forbidden (not auth)
                        dispatch(
                            {
                                type: payload.type,
                                data: {
                                    error: errorResponse?.data,
                                    lastError: Date.now(),
                                    loading: false
                                }
                            }
                        )
                    })
            }
        } else {
            // DISPATCH LOADING STATUS
            dispatch(
                {
                    type: actionTypes.ABORT_FETCH_HUB,
                    data: {
                        lastFetch: Date.now(),
                        loading: true
                    }
                }
            )
        }
    }
}

//MH - Own action for getting account details - above getCanonHub used for everything is too confusing as now we have a different object
export const getAccountDetails = props => {

    return (dispatch, getState) => {
        const timeSinceLastFetch = getState().canonhub.accountDetails.lastFetch

        const tempUrl = `${config.accountsApi}${props.accountNumber}`
        const url = window.isPROD ? tempUrl.replace('uat', '') : tempUrl
        const isDataStale = props.force ? true : Date.now() - timeSinceLastFetch > TIME_LIMT_FOR_FETCH // 5minutes in ms

        if (window.DEBUG) {
            console.log('chub:', props)
        }


        if (isDataStale) {
            dispatch({
                type: actionTypes.UPDATE_ACCOUNTS_DETAILS,
                data: {
                    lastFetch: Date.now(),
                    loading: true
                }
            })
            dispatch({
                type: actionTypes.UPDATE_ACCOUNTS_ACCESS,
                data: {
                    lastFetch: Date.now(),
                    loading: true
                }
            })

            if (window.DEBUG) console.time(url)
            return axios
                .get(url)
                .then((response) => {
                    if (window.DEBUG) console.timeEnd(url)

                    //remove dsf session store devices if local does not match payload otherwise persist
                    if (localStorage.getItem(STORED_ACCOUNT) !== response.data.Payload.Account.Number) sessionStorage.removeItem(DEVICE_DETAILS_ARRAY)

                    dispatch(
                        {
                            type: actionTypes.UPDATE_ACCOUNTS_DETAILS,
                            data: {
                                data: response.data.Payload.Account,
                                lastFetch: Date.now(),
                                loading: false,
                                pageNo: props.pageNo
                            }
                        }
                    )
                    dispatch(
                        {
                            type: actionTypes.UPDATE_ACCOUNTS_ACCESS,
                            data: {
                                data: response.data.Payload.Access,
                                lastFetch: Date.now(),
                                loading: false,
                                pageNo: props.pageNo
                            }
                        }
                    )
                })
                .catch((error) => {
                    if (window.DEBUG) console.timeEnd(url)
                    // need to do something here when get 403 error. this means the api end point is forbidden (not auth)

                    dispatch(
                        {
                            type: actionTypes.UPDATE_ACCOUNTS_DETAILS,
                            data: {
                                error: error.data,
                                lastError: Date.now(),
                                loading: false
                            }
                        }
                    )
                    dispatch(
                        {
                            type: actionTypes.UPDATE_ACCOUNTS_ACCESS,
                            data: {
                                error: error.data,
                                lastError: Date.now(),
                                loading: false
                            }
                        }
                    )
                })

        } else {
            // DISPATCH LOADING STATUS
            dispatch(
                {
                    type: actionTypes.ABORT_FETCH_HUB,
                    data: {
                        lastFetch: Date.now(),
                        loading: true
                    }
                }
            )
        }
    }
}

//MH - Own action for getting account details - above getCanonHub used for everything is too confusing as now we have a different object
export const updateAccountList = props => {

    return (dispatch, getState) => {
        const timeSinceLastFetch = getState().canonhub.accountList.lastFetch

        // const tempUrl = `${config.accountsApi}${props.accountNumber}`
        // const url = window.isPROD ? tempUrl.replace('uat', '') : tempUrl

        const pageNo = props.pageNo ? 'pageNo=' + props.pageNo : 'pageNo=1'
        const pageSize = '&pageSize=10'
        const search = props.search ? '&search=' + props.search : ''
        const tempUrl = `${config.accountsApi}?${pageNo}${pageSize}${search}`
        const url = window.isPROD ? tempUrl.replace('uat', '') : tempUrl

        const isDataStale = props.force ? true : Date.now() - timeSinceLastFetch > TIME_LIMT_FOR_FETCH // 5minutes in ms

        if (window.DEBUG) {
            console.log('chub:', props)
        }

        if (isDataStale) {
            dispatch({
                type: actionTypes.UPDATE_HEADER_ACCOUNT_LIST_REQUEST,
                data: {
                    lastFetch: Date.now(),
                    loading: true
                }
            })

            if (window.DEBUG) console.time(url)
            return axios
                .get(url)
                .then((response) => {
                    console.log(response)
                    if (window.DEBUG) console.timeEnd(url)

                    dispatch(
                        {
                            type: actionTypes.UPDATE_HEADER_ACCOUNT_LIST_SUCCESS,
                            data: {
                                data: response.data.Payload.Account,
                                lastFetch: Date.now(),
                                loading: false,
                                pageNo: props.pageNo
                            }
                        }
                    )
                })
                .catch((error) => {
                    if (window.DEBUG) console.timeEnd(url)
                    // need to do something here when get 403 error. this means the api end point is forbidden (not auth)
                    dispatch(
                        {
                            type: actionTypes.UPDATE_HEADER_ACCOUNT_LIST_FAILURE,
                            data: {
                                error: error.data,
                                lastError: Date.now(),
                                loading: false
                            }
                        }
                    )
                })

        } else {
            // DISPATCH LOADING STATUS
            dispatch(
                {
                    type: actionTypes.ABORT_FETCH_HUB,
                    data: {
                        lastFetch: Date.now(),
                        loading: true
                    }
                }
            )
        }
    }
}
