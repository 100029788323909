// @flow

import React from 'react'
import SVGInline from 'react-svg-inline'

import { IconWrapper } from './styles'

import * as svgs from './assets'

type Props = {
    color?: string,
    colorMode?: string,
    marginLeft?: string | number,
    marginRight?: string | number,
    size?: string,
    svg: string,
    verticalAlign?: string
}

const Icon = ({ svg, ...props }: Props) => (
    <IconWrapper {...props}>
        <SVGInline svg={svgs[svg]} />
    </IconWrapper>
)

Icon.defaultProps = {
    marginLeft: 0,
    marginRight: 0,
    verticalAlign: 'top'
}

// Export all icons in a single object and then use an icon like so: <Icon.plus />
const allIcons = {}

Object.keys(svgs).forEach(svg => {
    allIcons[svg] = props => <Icon {...props} svg={svg} />
    allIcons[svg].displayName = `Icon.${svg}`
})

allIcons.customSVG = (props: Props) => (
    <IconWrapper {...props}>
        <SVGInline svg={props.svg} />
    </IconWrapper>
)

allIcons.customSVG.displayName = `Icon.customSVG`

export default allIcons
