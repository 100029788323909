/**
 * Canon
 *
 * @package   mcb-front-end
 * @project   Ignite
 * @link      https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template/README.md#making-a-progressive-web-app
 *
 */

// -------------------------------------
//   Dependencies: Core
// -------------------------------------
//Babel polyfill for IE11 - need to move to webpack config if eject is done to CRA
import 'babel-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Router} from 'react-router-dom'
import App from './App'
import './index.css'
import registerServiceWorker from './registerServiceWorker'
// import { unregister } from './registerServiceWorker'
import {store, persistor} from './redux/store.js'
import history from './history/history.js'
import packageJSON from '../package.json'

import {ThemeProvider} from 'styled-components'
import theme from './styles/styled-components/theme'
// eslint-disable-next-line
import {PersistGate} from 'redux-persist/integration/react'
import {configureInterceptors} from 'interceptors'
import HelpTooltipApi from 'components/common/help/help-tooltip.js'
import ScrollToTop from 'components/common/scroll-to-top'
import {ToastProvider} from 'react-toast-notifications'

// ---------------------------------------------
//   HTTP INTERCEPTOR FOR AXIOS - REQUEST
// ---------------------------------------------
configureInterceptors()

// ---------------------------------------------
//   APP CONSTRUCT
// ---------------------------------------------
const app = (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
                <ThemeProvider theme={theme}>
                    <ToastProvider autoDismissTimeout={12000}>
                        <ScrollToTop>
                            <App/>
                        </ScrollToTop>
                    </ToastProvider>
                </ThemeProvider>
            </Router>
        </PersistGate>
    </Provider>
)
ReactDOM.render(app, document.getElementById('root'))
registerServiceWorker()
//unregister()
// hello world
HelpTooltipApi.getMCBHelpData()
    .then(helpContent => {
        localStorage.setItem('help', JSON.stringify(helpContent))
    })
    .catch(error => {
        console.error(error)
    })
//unregister()

// if (request.url.indexOf('api.ai') > 0) {
// chatbot api
// request.headers = {
//   ...request.headers,
//   Accept: 'application/json',
//   Authorization: `Bearer ${token}`,
//   Source: 'development'
// }
// }

// if (window.location.hostname.indexOf('mybusiness') >= 0) {
//   request.url = request.url.replace('uat', '')
// }
