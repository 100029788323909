import * as actionTypes from '../actions/actionTypes.js'
// import { updateObj } from '../utility.js';

const initialState = {
    auth: '',
    isAuthenticating: false
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.IS_AUTHENTICATING:
            return {
                ...state,
                isAuthenticating: action.flag
            }
        case actionTypes.UPDATE_AUTH:
            return {
                ...state,
                auth: action.auth
            }
        default: {
            return state
        }
    }
}

export default auth
