import {
    RRP_ADD_PERMISSION,
    RRP_REMOVE_PERMISSION,
    RRP_CLEAR
} from 'utils/acl-permissions/constants'

import { PERMISSONS } from 'auth/constants'

const initialState = JSON.parse(localStorage.getItem(PERMISSONS)) || []

const aclPermissions = (state = initialState, action = {}) => {
    switch (action.type) {
        case RRP_ADD_PERMISSION:
            return [...state, ...action.roles]
        case RRP_REMOVE_PERMISSION:
            return state.filter(role => role !== action.role)
        case RRP_CLEAR:
            return []
        default:
            return state
    }
}

export default aclPermissions
