export default {
    color: {
        white: '#fff',
        black: '#0d1a27',
        base1: '#202020',
        base2: '#4b4b4b',
        base3: '#8a8a8a',
        grey1: '#f6f6f6',
        grey2: '#e0e0e0',
        grey3: '#bababa',
        grey4: '#656a6e',
        success: '#28c93f',
        warning: '#f7c14c',
        error: '#d33e31',
        disabled: '#f0f0f0',
        blue: '#e8f2fa',
        navy: '#24354f',
        shadow: 'rgba(0, 0, 0, 0.15)',
        'shadow-dark': 'rgba(0, 0, 0, 0.35)',
        primary: '#cc0000',
        secondary: '#0f6599',
        focus: '#b1b1b1'
    },
    drawer: {
        zIndex: 100,
        backdropColor: 'rgba(30, 39, 48, .55)'
    },
    column: {
        spacing: '10px'
    },
    forms: {
        backgroundColor: 'white',
        borderColor: 'grey2',
        borderColorFocus: 'focus',
        borderRadius: '3px',
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftWidth: '1px',
        color: 'currentColor',
        errorFontSize: '0.8rem',
        errorFontWeight: '500',
        fontSize: '0.8rem',
        height: '40px',
        labelFontSize: '0.9rem',
        labelFontWeight: 'normal',
        lineHeight: 'normal',
        paddingHorizontal: '.5rem',
        paddingVertical: '0.25rem',
        placeholderColor: 'base2',
        placeholderOpacity: 0.7
    },
    modal: {
        zIndex: 100,
        backdropColor: 'rgba(30, 39, 48, .55)'
    },
    button: {
        borderRadius: '3px',
        fontSize: '.8rem',
        fontSizeLg: '1rem',
        fontSizeSm: '.7rem',
        fontWeight: '500',
        letterSpacing: 'normal',
        lineHeight: 'normal',
        paddingHorizontal: '1.25rem',
        paddingHorizontalLg: '4rem',
        paddingHorizontalSm: '0.5rem',
        paddingVertical: '0.5rem',
        paddingVerticalLg: '1rem',
        paddingVerticalSm: '0.2rem',
        textTransform: 'none'
    },
    heading: {
        h1: '2rem',
        h2: '1.8rem',
        h3: '1.6rem',
        h4: '1.4rem',
        h5: '1.2rem',
        h6: '1rem',
        fontWeight: 400
    },
    browserCompatibility: {
        zIndex: 1000
    },
    easing: {
        cubic: 'cubic-bezier(.9, .12, .3, .98)',
        'cubic-out': 'cubic-bezier(.1, .12, .13, .98)',
        'cubic-in': 'cubic-bezier(.98, .1, .12, .69)',
        'ease-out': 'cubic-bezier(.45, .45, 0, .99)',
        'ease-in': 'cubic-bezier(.99, 0, .45, .45)'
    },
    boxShadow: {
        '1': '0px 6px 10px 0px rgba(0, 0, 0, 0.15)',
        '2': '0 -3px 5px -1px rgba(0, 0, 0, .2)',
        '3': '0 3px 5px -1px rgba(0, 0, 0, .15)',
        filter: '0px 3px 8px -1px rgba(0, 0, 0, 0.2)'
    }
}
