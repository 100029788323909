import FileSaver from 'file-saver'
import MockService from './index'

//TODO need to find a way for the url string replacers.

const serialize = (obj) => {
	if (Array.isArray(obj)) {
		return JSON.stringify(obj.map((i) => serialize(i)))
	} else if (typeof obj === 'object' && obj !== null) {
		return Object.keys(obj).sort().map((k) => `${k}:${serialize(obj[k])}`).join('|')
	}

	return obj
}

const fetchAscii = (obj) => {
	if (!obj) return 0
	let convertedObj = 0

	for (let i = 0; i < obj.length; i++) {
		let asciiChar = obj.charCodeAt(i)

		convertedObj += asciiChar
	}

	return convertedObj
}

const getUrlVars = (url) => {
	let vars = []
	const questionMarkIndex = url.indexOf('?')

	if (questionMarkIndex === -1)
		return {
			url,
			vars: null
		}
	var hashes = url.slice(questionMarkIndex + 1).split('&')
	for (var i = 0; i < hashes.length; i++) {
		const objectValues = hashes[i].split('=')
		const obj = {}
		obj[objectValues[0]] = objectValues[1]
		vars.push(obj)
	}

	return {
		url: url.split('?')[0],
		vars
	}
}

const transformParmstoAsciiCheckSum = (params) => {
	let asciiParamsSum = 0
	var seralizedParamsString = serialize(params)
	seralizedParamsString = seralizedParamsString.replace(/['"]+/g, '')
	asciiParamsSum = fetchAscii(seralizedParamsString)

	// // for (let paramsObj of params) {
	// // 	if (paramsObj) {
	// // 		// eslint-disable-next-line
	// // 		Object.keys(paramsObj).forEach(function(key) {
	// // 			asciiParamsSum += fetchAscii(key) + fetchAscii(paramsObj[key])
	// // 		})
	// // 	}
	// }

	return asciiParamsSum
}

const replaceAll = (target, search, replacement) => {
	return target.replace(new RegExp(search, 'g'), replacement)
}

export const downloadAPIResponseJSON = (response) => {
	let stringifiedResponse = JSON.stringify(response.data)
	let uglyUrl = response.config.url.split('.canon.com.au/')[1]
	let urlVars = getUrlVars(uglyUrl)
	let method = response.config.method
	//let paramsObj = response.config.params
	let asciiParamsSum = urlVars.vars ? transformParmstoAsciiCheckSum(urlVars.vars) : 0
	let blob = new Blob([ stringifiedResponse ], { type: 'application/json' })
	let fileName = asciiParamsSum ? `${method}_${urlVars.url}_${asciiParamsSum}` : `${method}_${urlVars.url}`
	FileSaver.saveAs(blob, `${fileName}.json`)
}

const urlVarsFix = (url) => {
	if (url.indexOf('?') < 0) return ''
	let rtnString = url.split('?')[1]
	rtnString = replaceAll(rtnString, '=', '_')
	rtnString = replaceAll(rtnString, '&', '_')
	return '_' + rtnString
}

const urlVarsFix2 = (url) => {
	if (url.indexOf('?') < 0) return ''
	let rtnString = url.split('?')[1]
	return '_' + window.btoa(rtnString)
}

const urlVarsFix3 = (urlvars) => {
	if (!urlvars) return ''
	let rtnString = ''
	let searchTerm
	// console.warn(urlvars)
	for (let obj in urlvars) {
		const key = Object.keys(urlvars[obj])
		switch (String(key)) {
			case 'pageNo':
				rtnString += '_pg' + urlvars[obj][key]
				break
			case 'pageSize':
				rtnString += '_ps' + urlvars[obj][key]
				break
			case 'businessUser':
				rtnString += '_bususr'
				break
			case 'createdFrom':
				rtnString += '_date'
				break
			case 'searchFields':
				searchTerm =
					String(urlvars[obj][key]).toLowerCase().indexOf('date') >= 0 ? 'date' : String(key).toLowerCase
				break
			case 'searchTerms':
				rtnString += searchTerm === 'date' ? '_date' : '_' + urlvars[obj][key]
				break
			default:
			// do nothing
		}
	}
	/* current url params
    businessUser: "true"
    createdFrom: "2018-12-02"
    includeDeviceDetails: "true"
    serviceOrderStatus: "UNASSIGNED"
    serviceOrderStatus: "ASSIGNED"
    serviceOrderStatus: "ACCEPTED"
    serviceOrderStatus: "ACCEPTED"
    pageNo: "1"
    pageSize: "15"
    searchFields: "invoiceDate,invoiceDate"
    searchTerms: "2019-01-01,2018-07-01"    
    searchFields: "serial"
    searchTerms: "DZT51593"
    operators: "lt,gt"
    junction: "and"
    orderBy: "cdssStatus"
    directions: "asc"
  */
	return rtnString
}

export const changeRequestConfigToMockURL = (config, mockServerUrl) => {
	if (config.url.includes('.canon.com.au/')) {
		if (MockService.EXEMPT_MOCK_URLS.some((v) => config.url.includes(v))) {
			if (window.DEBUG) console.log('mock: ignoring url change request')
			return
		}
		let uglyUrl = config.url.split('.canon.com.au/')[1]
		uglyUrl = replaceAll(uglyUrl, '/', '_')
		//let url = replaceAll(uglyUrl, '\\?', '_')
		let urlVars = getUrlVars(uglyUrl)

		// let paramsObj = config.params
		const exemptChecksumUrl = 'hub_v3_accounts_456789_orders_assets'

		let asciiParamsSum =
			urlVars.vars && urlVars.url ===  0


		// asset orders uses same urls for both install and direct deliveries - u cannot exempt checksum there.
		//[ch] temp disable the checksum as its changing to often with HUB changes
		let transformURl = asciiParamsSum
			? `${config.method}_${urlVars.url}_${asciiParamsSum}`
			: `${config.method}_${urlVars.url}`

		config.url = `${mockServerUrl}/${transformURl}${urlVarsFix3(urlVars.vars)}.json`
		config.method = 'get'

		//[sp]in case of mock url dont send the authorization header
		config.headers.Authorization = null
		config.params = null
	}
}
