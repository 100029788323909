// @flow

import React from 'react'

import Icon from '../../Icon'
import Loading from '../../common/loading'

import { AnchorButton, ButtonContent, LinkButton, NormalButton } from './styles'

type Props = {
    background: string,
    border?: string,
    borderRadius?: string,
    children: Node | string,
    className?: string,
    color: string,
    disabled?: boolean,
    hoverProps?: {
        background?: string,
        border?: string,
        color?: string
    },
    href?: string,
    icon?: string,
    iconProps?: Object,
    loading?: boolean,
    onClick?: () => void,
    padding?: string,
    round?: boolean,
    size: 'sm' | 'md' | 'lg',
    target?: string,
    to?: string,
    type: 'button' | 'submit' | 'reset',
    width?: string
}

const Button = ({
    background,
    border,
    borderRadius,
    children,
    className,
    color,
    disabled,
    hoverProps,
    href,
    icon,
    iconProps,
    loading,
    onClick,
    padding,
    round,
    size,
    target,
    to,
    type,
    width
}: Props) => {
    let ButtonComponent = NormalButton

    if (href) {
        ButtonComponent = AnchorButton
    }

    if (to) {
        ButtonComponent = LinkButton
    }

    const IconComponent = icon ? Icon[icon] : null

    const buttonIconProps = {
        size: '14px',
        marginRight: '7px',
        ...iconProps
    }

    return (
        <ButtonComponent
            background={background}
            border={border || background}
            borderRadius={borderRadius}
            className={className}
            color={color}
            disabled={disabled}
            hoverProps={hoverProps}
            href={href}
            onClick={onClick}
            padding={padding}
            round={round}
            to={to}
            width={width}
            size={size}
            target={target}
            type={type}
        >
            {/*loading &&
        
          <Loading
          center
          color="currentColor"
          backgroundColor="transparent"
          size="26px"
          thickness="1px"
          fixedFooter={false}
          containerHeight="inherit"
        />
        */}
            <ButtonContent hide={false} round={round}>
                {IconComponent && <IconComponent {...buttonIconProps} />}
                {children}
            </ButtonContent>
        </ButtonComponent>
    )
}

Button.defaultProps = {
    background: 'primary',
    color: 'white',
    iconProps: {},
    size: 'md',
    type: 'button',
    width: 'auto'
}

export default Button
