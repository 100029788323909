// eslint-disable-next-line
import styled, { keyframes } from 'styled-components'

export const fadeIn = keyframes`
  from {
    transform: scale(0.85)
    opacity: 0
  }

  to {
    transform: scale(1)
    opacity: 1
  }
`

export const fadeOut = keyframes`
  from {
    transform: scale(1)
    opacity: 1
  }

  to {
    transform: scale(0.85)
    opacity: 0
  }
`
