import {Switch} from 'react-router'
import {Route} from 'react-router-dom'
import Loading from 'components/common/loading'
import Loadable from 'react-loadable'
import React from 'react'
import {metadata} from 'app-config'

import {GenericError} from 'components/error-pages'
import ErrorCodes from 'utils/errorCodes'
import PrivateRoute from "routes/private-route";

const Assets = Loadable({
    loader: () => import('./asset-listing/index.js'),
    loading: () => (
        <Loading
            center
            size="40px"
            thickness="1px"
            paddingAround="200px"
            message="Loading Assets"
        />
    )
})
const Asset = Loadable({
    loader: () => import('./asset/index.js'),
    loading: () => (
        <Loading
            center
            size="40px"
            thickness="1px"
            paddingAround="200px"
            message="Loading Asset Data"
        />
    )
})

const AssetStatus = Loadable({
    loader: () => import('./asset-status'),
    loading: () => (
        <Loading
            center
            size="40px"
            thickness="1px"
            paddingAround="200px"
            message="Loading Asset Status"
        />
    )
})

const AssetDetails = Loadable({
    loader: () => import('./asset-status/asset-details'),
    loading: () => (
        <Loading
            center
            size="40px"
            thickness="1px"
            paddingAround="200px"
            message="Loading Asset Details"
        />
    )
})


const Routes = () => {
    return (
        <Switch>
            <Route exact path={metadata.assets.route} component={Assets}/>
            <PrivateRoute
                exact
                path={metadata.assetStatus.route}
                component={AssetStatus}
                permissions={metadata.assetStatus.permissions}
            />
            <PrivateRoute
                exact
                path={metadata.assetIdStatus.route}
                component={AssetDetails}
                permissions={metadata.assetIdStatus.permissions}
            />
            <Route exact path={metadata.asset.route} component={Asset}/>
            <Route
                render={props => {
                    return <GenericError errorObj={ErrorCodes.NOT_FOUND}/>
                }}
            />
        </Switch>
    )
}

export default Routes
