// @flow

import React, { Component } from 'react'
import 'react-select/dist/react-select.css'

import { StyledSelect, StyledCreatableSelect } from './styles'
// import CustomRenderOption from './customOptionRender'

type SelectProps = {
    clearable: boolean,
    creatable: boolean,
    disabled: boolean,
    searchable: boolean,
    id: string,
    multi: boolean,
    name: string,
    onBlur: (SyntheticInputEvent<HTMLInputElement>) => void,
    onChange: (string | string[]) => void,
    options: string | Object[],
    placeholder: string,
    required: boolean,
    value: string,
    height: string
}

class FormFieldSelect extends Component<SelectProps> {
    static defaultProps = {
        options: [],
        type: 'text',
        height: '40px',
        searchable: true
    }

    handleChange = (value: Object | Object[]) => {
        const {onChange} = this.props
        if (Array.isArray(value)) {
            const values = value.map(v => v.value)
            onChange(values)
            return
        }
        onChange(value ? value.value : '')
    }

    render() {
        const {
            clearable,
            creatable,
            disabled,
            id,
            multi,
            name,
            onBlur,
            onChange,
            options,
            placeholder,
            required,
            value,
            height,
            searchable
        } = this.props

        const SelectComponent = creatable ? StyledCreatableSelect : StyledSelect

        return (
            <SelectComponent
                clearable={clearable}
                disabled={disabled}
                id={id}
                multi={multi}
                name={name}
                onChange={this.handleChange}
                onBlur={onBlur}
                options={options}
                required={required}
                placeholder={placeholder}
                value={value}
                height={height}
                searchable={searchable}
            />
        )
    }
}

export default FormFieldSelect
