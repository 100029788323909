import axios from 'axios'
// import config from 'config.json'

class AppConfigApi {
    static getMCBMetadata = payLoad => {
        return axios
            .get(`https://mcb-metadata.firebaseio.com/metadata.json`, payLoad)
            .then(response => {
                return response.data
            })
            .then(data => {
                return data
            })
            .catch(error => {
                throw error
            })
    }

    static getMCBAcldata = payLoad => {
        return axios
            .get(`https://mcb-metadata.firebaseio.com/acls.json`, payLoad)
            .then(response => {
                return response.data
            })
            .then(data => {
                return data
            })
            .catch(error => {
                throw error
            })
    }

    static postMCBAcldata = acls => {
        return axios
            .put(`https://mcb-metadata.firebaseio.com/acls.json`, acls)
            .then(response => {
                return response.data
            })
            .then(data => {
                return data
            })
            .catch(error => {
                throw error
            })
    }
}

export default AppConfigApi
