export const ID_TOKEN_KEY = 'id_token'
export const ACCESS_TOKEN_KEY = 'access_token'
export const ISDEMO = 'isDemo'
export const CALLBACK = 'callback'
export const MEMBER_ID = 'member_id'
export const SUBJECT = 'sub'
export const EXPIRES_AT = 'expires_at'
export const PERMISSONS = 'permissions'
export const MEMBER_INFO = 'loggedInMemberDetails'
export const ACCOUNT_INFO = 'memberAccountDetails'
export const SELECTED_ACCOUNT = 'selectedAccount'
export const ACCOUNT_FAVS = 'accountFavs'
export const RELOGIN_FLAG = 'reLoginFlag'
export const STORED_ACCOUNT = 'storedAccount'
export const OPENID_CONNECT_MULTIVALUES_RESPONSE_TYPE = 'token id_token'
export const ISPROD = window.isPROD || window.location.host.indexOf('mybusiness') >= 0
    ? true
    : false
export const ISNZ = window.isNZ || window.location.host.indexOf('co.nz') >= 0
    ? true
    : false
export const CLIENT_ID = ISPROD
    ? 'canon.mycanonbusiness.prod'
    : 'canon.mycanonbusiness.uat'
export const CLIENT_DOMAIN = ISPROD
    ? 'secure.canon.com.au/core/connect'
    : 'uatsecure.canon.com.au/core/connect'

export const AUTOSIGN_COOKIE = ISPROD
    ? 'autosignin.canon.com.au'
    : 'uat.autosignin.canon.com.au'

export const REDIRECT = window.location.origin + '/callback'
export const SCOPE =
    'openid profile email phone member roles business webapi access'

export const ACR_SFDC = 'idp:SFDC'
export const SFDC = 'SFDC' // idsrv
export const ACR_STS = 'idp:STS'

//CANT USE WINDOW.LOCATION PROTOCOL SINCE IN UAT WE ARE STAGING ON NON SECURE
export const CORE_URL = ISPROD
    ? 'https://secure.canon.com.au/core'
    : 'https://uatsecure.canon.com.au/core'
