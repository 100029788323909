import * as actionTypes from './actionTypes'

// these top 3 are now probable redundant
export const setSelectedAccount = payload => {
    if (payload) {
        localStorage.setItem('selectedAccount', payload)
        return {
            type: actionTypes.UPDATE_SELECTED_ACCOUNT,
            selectedAccount: payload
        }
    }
}

export const setSelectedDevice = payload => {
    return {
        type: actionTypes.UPDATE_SELECTED_DEVICE,
        selectedDevice: payload
    }
}

export const setSelectedServiceOrder = payload => {
    return {
        type: actionTypes.UPDATE_SELECTED_SERVICEORDER,
        selectedServiceOrder: payload
    }
}

export const setSelected = obj => {
    if (obj.tyle === actionTypes.UPDATE_SELECTED_ACCOUNT) {
        localStorage.setItem('selectedAccount', obj.selectedAccount)
    }
    return obj
}
