import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loading from 'components/common/loading'

import { checkVisibility } from './core'

class Authorization extends Component {
    static propTypes = {
        routeOrSectionPermissions: PropTypes.array,
        userPermissions: PropTypes.array,
        children: PropTypes.node.isRequired,
        fallbackElement: PropTypes.node
    }
    static defaultProps = {
        routeOrSectionPermissions: [],
        fallbackElement: null
    }
    constructor(props) {
        super(props)

        this.state = {
            visible: false
        }
    }

    UNSAFE_componentWillMount() {
        this.setVisibility()
    }

    componentDidUpdate() {
        this.setVisibility()
    }

    setVisibility() {
        const { userPermissions, routeOrSectionPermissions } = this.props
        const { visible } = this.state

        const newVisibility =
            routeOrSectionPermissions.length === 0
                ? true
                : checkVisibility(userPermissions, routeOrSectionPermissions)
        if (visible !== newVisibility) {
            this.setState({
                visible: newVisibility
            })
        }
    }

    render() {

        const { children, fallbackElement, accessLoading, detailsLoading } = this.props
        const { visible } = this.state

        if(accessLoading || detailsLoading){
            return(
                <Loading
                    center
                    size="40px"
                    thickness="1px"
                    paddingAround="200px"
                    message="Checking User Permissions"
                />
            )
        }

        if (visible) {
            return children
        }
        return fallbackElement
    }
}

const mapStateToProps = (state, ownProps) => {

    const {accountAccess,accountDetails } = state.canonhub

    const accessLoading = accountAccess ? accountAccess.loading : true
    const detailsLoading = accountDetails ? accountDetails.loading : true

    return {
    userPermissions: (accountAccess?.data)
            ? accountAccess.data.Permissions
            : [],
        accessLoading,
        detailsLoading
    }
}

export default connect(
    mapStateToProps,
    null
)(Authorization)
