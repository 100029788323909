import { store } from 'redux/store.js'

export const PERMISSIONS_ROUTES_ONLY = 'Routes'
export const PERMISSIONS_SECTIONS_ONLY = 'Sections'

//[SP] Costly operation if the config is too large - need to rethink if moved to BE API endpoint TODO
export const getPermissions = (idRef, flag) => {
    const ACLS = store.getState().acl
    let allowPermissions = {
        routes: [],
        sections: []
    }
    let denyPermissions = {
        routes: [],
        sections: []
    }
    if (ACLS && ACLS.length > 0) {
        for (const acl of ACLS) {
            const { permission } = acl
            if (!flag || flag === PERMISSIONS_ROUTES_ONLY) {
                if (acl.filters && acl.filters.routes) {
                    if (
                        acl.filters.routes.allow &&
                        acl.filters.routes.allow.find(e => e === idRef)
                    ) {
                        allowPermissions.routes.push(permission)
                    } else if (
                        acl.filters.routes.deny &&
                        acl.filters.routes.deny.find(e => e === idRef)
                    ) {
                        denyPermissions.routes.push(permission)
                    }
                }
            }
            if (!flag || flag === PERMISSIONS_SECTIONS_ONLY) {
                if (acl.filters && acl.filters.sections) {
                    if (
                        acl.filters.sections.allow &&
                        acl.filters.sections.allow.find(e => e === idRef)
                    ) {
                        allowPermissions.sections.push(permission)
                    } else if (
                        acl.filters.sections.deny &&
                        acl.filters.sections.deny.find(e => e === idRef)
                    ) {
                        denyPermissions.sections.push(permission)
                    }
                }
            }
        }
    }

    return {
        allowPermissions,
        denyPermissions
    }
}
