import styled, {keyframes} from 'styled-components'

const rotate = keyframes`
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
`

export const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  opacity: 0.7;
  flex-direction: column;
  margin: 0 auto;
  background-color: ${(props) => props.backgroundColor || 'inherit'};
  ${props =>
          props.center &&
          `position: ${props.position};
    width: 100%;
    height: ${props.containerHeight || '400px'}
    top: 0;
    left: 0;
    
    
  `};

  .saving {
  }

  .saving span {
    font-size: 1.2em;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  .smallText {
    font-size: 12px;
  }

  .saving span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .saving span:nth-child(3) {
    animation-delay: 0.4s;
  }

  .saving span:nth-child(4) {
    animation-delay: 0.6s;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`

export const StyledSpinner = styled.div`
  display: inline-block;
  width: ${props => props.size};
  height: ${props => props.size};
  transform-origin: center;
  margin-right: ${props => (props.hasMessage ? '1em' : null)};
  animation: ${rotate} 0.5s infinite linear;
  border: ${props => props.thickness} solid ${props => props.color};
  border-radius: 50%;
  border-right-color: ${props => props.backgroundColor};
  border-top-color: ${props => props.backgroundColor};
`
