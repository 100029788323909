import React, {Fragment} from 'react'

export default {
    GENERIC: {
        Type: 'UI',
        Name: 'Generic error',
        Code: '9999',
        Header: 'Oops! Something went wrong',
        Icon: 'fal fa-exclamation-circle',
        message: () => (
            <Fragment>
                You can return to the <a href="/">Home</a> page and retry the
                same action{' '}
            </Fragment>
        )
    },
    ACCESS_DENIED: {
        Type: 'UI',
        Name: 'Access Denied',
        Code: '0001',
        Header: 'Access Denied',
        Icon: 'fal fa-ban',
        message: () => (
            <Fragment>
                Your user profile has not been given permissions to access this
                page. Please contact the MyCanon Business Portal Administrator
                in your company to arrange access for you
            </Fragment>
        )
    },
    NOT_AUTHORIZED: {
        Type: 'UI',
        Name: 'Not Authorized',
        Code: '0002',
        Header: 'Not Authorised',
        Icon: 'fal fa-ban',
        message: () => (
            <Fragment>
                Your user profile is currently not linked to any company
                account. Please contact the MyCanon Business Portal
                Administrator in your company to arrange access for you{' '}
            </Fragment>
        )
    },
    ACCESS_DENIED_STS: {
        Type: 'UI',
        Name: 'User Access Denied',
        Code: '0003',
        Header: 'Access Denied',
        Icon: 'fal fa-ban',
        message: () => (
            <Fragment>
                Your user profile has not been given access to MyCanon Business.
                Please contact the MyCanon Business Portal Admin
                in your company to arrange access for you.
            </Fragment>
        )
    },
    NOT_FOUND: {
        Type: 'UI',
        Name: 'Not found',
        Code: '9998',
        Header: 'Not Found',
        Icon: 'fal fa-ban',
        message: () => (
            <Fragment>
                There is no resource found with the requested URL. You can
                return to the <a href="/">Home</a> page {' '}
            </Fragment>
        )
    },
    ORDER_COMPLETE: {
        Type: 'UI',
        Name: 'Order Complete',
        Code: '9997',
        Header: 'Order Complete',
        Icon: 'fal fa-clipboard-check',
        message: () => (
            <Fragment>
                This installation is complete{' '}
            </Fragment>
        )
    },
    NO_BILLING_NZ: {
        Type: 'UI',
        Name: 'This function is temporarily unavailable',
        Code: '9997',
        Header: 'Billing & Charge History',
        Icon: 'fal fa-exclamation-circle',
        message: () => (
            <Fragment>
                This function is temporarily unavailable. Please email us at <a href="mailto:creditcontrol@canon.co.nz"
                                                                                target="_blank">creditcontrol@canon.co.nz</a> {' '}
            </Fragment>
        )
    }
}
