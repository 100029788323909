import MockService from 'mock-service'
import {ACCOUNT_INFO, ISDEMO, MEMBER_INFO} from './constants'
import {store} from '../redux/store.js'
import * as actionTypes from 'redux/actions/actionTypes.js'
import {setSession} from './tokenHandlers'
import * as actionCreators from 'redux/actions/index.js'
import {configureHotJar} from 'hotjar'


class PostAuth {
    static process = (authResult) => {
        if (window.DEBUG) console.log('auth: post', authResult)

        //[sp] if the role is demo then call the change the mode to mock by setting the mock interceptor
        const {idTokenPayload} = authResult

        configureHotJar(idTokenPayload.email)

        let memberId

        //[sp] set the interceptor if the role of the user is demo "b2b-demo"
        // MockService.configure(MockService.MODES.DOWNLOAD) // use this to save api/json calls to download folder
        if (idTokenPayload.role && idTokenPayload.role.includes(MockService.MOCK_USER_ROLE)) {
            localStorage.setItem(ISDEMO, true)
            MockService.configure(MockService.MODES.MOCK)
            window.isDEMO = true
            memberId = '15075916'
        } else {
            memberId = idTokenPayload.id
        }

        //remove member info
        sessionStorage.removeItem(MEMBER_INFO)
        sessionStorage.removeItem(ACCOUNT_INFO)

        store
            .dispatch(
                actionCreators.getCanonHub({
                    type: actionTypes.UPDATE_MEMBER_INFO,
                    memberId,
                    force: true
                })
            )
            .then(() => {
                store.dispatch(actionCreators.getCanonHub({type: actionTypes.UPDATE_ACCOUNTS}))
            })
            .catch((e) => {
                throw new Error(`Error retrieving the Member Info - ${e}`)
            })

        store.dispatch(actionCreators.setAuth(authResult))
        setSession(authResult)

        //sri- The whole process of authetication is done so set the auth.isAuthenticating state to false.
        store.dispatch(actionCreators.isAuthenticating(false))
        //sri - Move the redirection logic to SSO component since its the best place to do the route redirection rather at this call back.
    }
}

export default PostAuth
