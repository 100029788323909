import React from 'react'
import {login, logout, isLoggedIn, changePassword} from 'auth'
import Links from 'features/landing/tile.json.js'
import LinkButton from 'components/common/buttons/link-button-icon.jsx'
import {AuthHOC} from 'utils/acl-permissions'

const SubMenu = ({
                     linksSubmenus,
                     toggleLinksSubmenu,
                     linkKey,
                     accountNum
                 }) => {
    return (
        <div
            className={`mb-8 ${Links[linkKey].title} submenu-${!!linksSubmenus[
                Links[linkKey].title
                ]}`}
            key={linkKey}
        >
            <div
                role="button" tabIndex="0"
                onKeyPress={() => toggleLinksSubmenu(Links[linkKey].title)}
                onClick={() => toggleLinksSubmenu(Links[linkKey].title)}
                className="links-submenu-header"
            >
                <i className="fal fa-chevron-down mr-8 fs-vsmall"/>
                {Links[linkKey].title}
            </div>
            <ul className="items ma-0 pa-0 pl-16">
                {Object.keys(Links[linkKey].links).map(key2 => {
                    const isExternal = Links[linkKey].links[key2].href.indexOf('//') > 0
                    return (
                        <li className="item" key={key2}>
                            {!isExternal && (
                                <LinkButton
                                    btnIcon=""
                                    btnClass="fs-vsmall link hamburger-link"
                                    btnTo={`/${accountNum}${Links[linkKey].links[key2].href}`}
                                    btnText={Links[linkKey].links[key2].text}
                                    metadata={Links[linkKey].links[key2]}
                                    hasChevron="true"
                                />
                            )}
                            {isExternal && (
                                <LinkButton
                                    btnIcon=""
                                    btnClass="fs-vsmall link hamburger-link"
                                    btnTo={Links[linkKey].links[key2].href}
                                    btnText={Links[linkKey].links[key2].text}
                                    metadata={Links[linkKey].links[key2]}
                                    hasChevron={true}
                                    isExternal={true}
                                    newWindow={true}
                                />

                            )}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const SubMenuWithAuth = AuthHOC()(SubMenu, null)

const HamburgerMenu = ({
                           handleLinksHamburgerMenuClick,
                           toggleLinksSubmenu,
                           showLinksSubmenu,
                           linksSubmenus,
                           accountNum
                       }) => {

    return (
        <div className="hamburger-menu posn__rel">
            <a
                role="button"
                tabIndex="0"
                className="fal fa-bars curs-p home-click"
                onKeyPress={() => handleLinksHamburgerMenuClick()}
                onClick={() => handleLinksHamburgerMenuClick()}
            />
            {showLinksSubmenu && (
                <div className="links-submenu posn__abs dropdown">
                    <div className="pl-16 pt-16 pr-16">
                        {Object.keys(Links).map(key => {
                            let permissions = new Set()

                            Links[key].links.forEach(link => {
                                if (link.permissions)
                                    link.permissions.forEach(permission => {
                                        permissions.add(permission)
                                    })
                            })
                            return (
                                <SubMenuWithAuth
                                    key={key}
                                    linksSubmenus={linksSubmenus}
                                    toggleLinksSubmenu={toggleLinksSubmenu}
                                    linkKey={key}
                                    metadata={{
                                        permissions: [...permissions]
                                    }}
                                    accountNum={accountNum}
                                />
                            )
                        })}
                    </div>
                    <div className="divider ma-16"/>
                    <div className="pa-0 pl-16 pr-16 pb-16">
                        {(isLoggedIn() && accountNum) && (
                            <div>
                                <a
                                    className="envelope curs-p"
                                    href={`/${accountNum}/contact`}
                                >
                                    <i className="fal fa-envelope mr-8"/>Contact us
                                </a>
                            </div>
                        )}
                        {isLoggedIn() && (
                            <a
                                role="button"
                                tabIndex="0"
                                onClick={() => changePassword()}
                                onKeyPress={() => changePassword()}
                                className="password curs-p pt-8"
                            >
                                <i className="fal fa-unlock mr-8 pt-8"/>Change
                                password
                            </a>
                        )}
                        {!isLoggedIn() && (
                            <div
                                onKeyPress={() => login()}
                                role="button"
                                tabIndex="0"
                                className="login curs-p"
                                onClick={() => login()}
                            >
                                <i className="fal fa-sign-in mr-8 pt-8"/>Login
                            </div>
                        )}
                        {isLoggedIn() && (
                            <div
                                onKeyPress={() => logout()}
                                role="button"
                                tabIndex="0"
                                className="login curs-p"
                                onClick={() => logout()}
                            >
                                <i className="fal fa-sign-out mr-8 pt-8"/>Logout
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default HamburgerMenu
