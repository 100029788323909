import { css } from 'styled-components'

export const aspectRatio = (x, y) => css`
    position: relative;

    &::after {
        display: block;
        width: 100%;
        height: 100%;
        padding-top: ${100 * (y / x)}%;
        content: '';
        z-index: -1;
    }
`

export const inputPlaceholder = (...args) => css`
    &::-webkit-input-placeholder {
        ${css(...args)};
    }

    &:-moz-placeholder {
        ${css(...args)};
    }

    &::-moz-placeholder {
        ${css(...args)};
    }

    &:-ms-input-placeholder {
        ${css(...args)};
    }
`

// TODO: Make this configurable
export const breakpoints = {
    xs: '(min-width: 0px)',
    sm: '(min-width: 480px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 980px)',
    xl: '(min-width: 1200px)'
}

const getMediaBreakpoints = () => {
    const media = {}

    Object.keys(breakpoints).forEach(breakpoint => {
        media[breakpoint] = (...args) => css`
            @media ${breakpoints[breakpoint]} {
                ${css(...args)};
            }
        `
    })

    return media
}

export const media = getMediaBreakpoints()
