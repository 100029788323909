export const language = "en-AU" //todo en-AU doesnt work
export const locale = "en-AU"
export const timezone = "Australia/Sydney" //local by default set to undefined

export const DEVICE_DETAILS_ARRAY = 'dsf_devices'
export const commonDSFProps = {
    locale: locale,
    timezone: timezone,
    language: language,
}

export const DEVICE_DETAILS = 'deviceDetails'


export const demoDsfDevices = [
    {
        deviceSerialNo: "2GS00200",
        deviceName: "IR-ADV C3530i",
        location: "MQP, NSW"
    },
    {
        deviceSerialNo: "2GS01822",
        deviceName: "IR-ADV C3530i",
        location: "Clayton,VIC"
    }
];

export const DEVICE_LIST_ID = '#device-list'
export const SUMMARY_STATUS_ID = '#status-summary'
export const BACKGROUND_COLOR = '#f6f6f6'

export const tabs = [
    {
        title: 'Asset Summary Status',
        id: SUMMARY_STATUS_ID
    },
    {
        title: 'Asset Status List',
        id: DEVICE_LIST_ID,
    }
]

//todo check if we can move these out if they do not change
export const securityDiagnosisSettings = {
    isSecuritySettingsCheck: true,
    isSecuritySoftwareIntegrityVerification: true,
    isSecurityUserAuthenticationLockout: true,
    isSecurityUserLoginDuringMonitoringTime: true,
    securityUserLoginDuringMonitoringTimeStart: "13:00",
    securityUserLoginDuringMonitoringTimeEnd: "21:00"
}

export const extraSettings = {
    'inspection-history-display-flag': false,
    // 'customer-support-display-flag': 'true',
    // 'customer-support-histories': '[]',
    'security-diagnosis-settings': JSON.stringify(securityDiagnosisSettings),
}

