import React, {Component} from 'react'
import 'react-select/dist/react-select.css'

import {StyledSelect, StyledCreatableSelect} from './styles'

type SelectProps = {
    clearable: boolean,
    creatable: boolean,
    disabled: boolean,
    id: string,
    multi: boolean,
    name: string,
    onBlur: (SyntheticInputEvent<HTMLInputElement>) => void,
    onChange: (string | string[]) => void,
    options: string | Object[],
    placeholder: string,
    required: boolean,
    value: string
}

class AccountSelect extends Component<SelectProps> {
    static defaultProps = {
        options: [],
        type: 'text'
    }

    handleChange = (value: Object | Object[]) => {
        if (Array.isArray(value)) {
            const values = value.map(v => v.value)
            this.props.onChange(values)
            return
        }

        this.props.onChange(value ? value.value : '')
    }

    render() {
        const {
            clearable,
            creatable,
            disabled,
            id,
            multi,
            name,
            onBlur,
            options,
            placeholder,
            required,
            value
        } = this.props

        const SelectComponent = creatable ? StyledCreatableSelect : StyledSelect

        return (
            <SelectComponent
                clearable={clearable}
                disabled={disabled}
                id={id}
                multi={multi}
                name={name}
                onChange={this.handleChange}
                onBlur={onBlur}
                options={options}
                required={required}
                placeholder={placeholder}
                value={value}
            />
        )
    }
}

export default AccountSelect
