import * as actionTypes from './actionTypes'

// these top 3 are now probable redundant
export const setSearchOptions = payload => {
    return {
        type: actionTypes.UPDATE_SEARCH_OPTIONS,
        searchOptions: payload
    }
}

export const setPagingSetting = payload => {
    return {
        type: actionTypes.UPDATE_PAGING_SETTINGS,
        pagingSettings: payload
    }
}
