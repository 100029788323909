import axios from "axios";
import {UPDATE_DSF_SUCCESS, UPDATE_DSF_REQUEST, UPDATE_DSF_ERROR} from "./actionTypes";
import config from 'config.json'
import {TIME_LIMT_FOR_FETCH} from "../../utils/constants";


export const getDSFDevices = ({accountNumber, tokenOnly}) => {


    return (dispatch, getState) => {

        const timeSinceLastFetch = getState().dsf.lastFetch

        const DSF_URL = `${config.accountsApi}${accountNumber}/devices/dsf${tokenOnly ? '?tokenOnly=true' : ''}`

        dispatch({type: UPDATE_DSF_REQUEST})
        let isDataStale = Date.now() - timeSinceLastFetch > TIME_LIMT_FOR_FETCH // 5minutes in ms

        if (isDataStale) {
            return axios
                .get(DSF_URL)
                .then(response => {

                    if (response?.data?.Payload?.Token) {
                        dispatch({
                            type: UPDATE_DSF_SUCCESS,
                            data: {
                                response: {
                                    devices: response?.data?.Payload?.Devices,
                                    token: response?.data?.Payload?.Token,
                                },
                                lastFetch: Date.now()
                            },
                        })
                    } else {
                        dispatch({type: UPDATE_DSF_ERROR, action: {data: {response: 'Empty token'}}})
                    }

                })
                .catch((error) => {
                    console.error(error)
                    dispatch({type: UPDATE_DSF_ERROR, error})
                })
        }
    }
}
