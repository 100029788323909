import styled, { css } from 'styled-components'
import { inputPlaceholder } from './mixins'
import { color } from './helpers'
import { media } from 'styles/Breakpoint'

export const ModalFooterSection = styled.div`
    position: absolute;
    height: 3em;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.color.white};
    background-color: ${props => props.theme.color.primary};
    font-weight: 500;
    cursor: pointer;
`

export const ModalFooterSectionBlack = styled.div`
    position: absolute;
    height: 3em;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.color.white};
    background-color: #333;
    font-weight: 500;
    cursor: pointer;
`

export const ModalContentSection = styled.div`
    display: flex;
    padding: 0.2em 0.7em;
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: 1.5;
`

//form input styles
export const FormFileStyle = css`
    cursor: pointer;
    display: flex;
    filter: alpha(opacity=0);
    opacity: 0;
    right: 0;
    text-align: right;
    min-height: 100%;
    position: relative;
    top: 0;
    width: 179px;
    left: -179px;
`

export const fieldStyles = css`
    appearance: none;
    background-color: ${props =>
    color(props.theme.forms.backgroundColor)(props)};
    border-radius: ${props => props.theme.forms.borderRadius};
    border-top-width: ${props => props.theme.forms.borderTopWidth};
    border-right-width: ${props => props.theme.forms.borderRightWidth};
    border-bottom-width: ${props => props.theme.forms.borderBottomWidth};
    border-left-width: ${props => props.theme.forms.borderLeftWidth};
    box-shadow: none;
    color: solid ${props => color(props.theme.forms.color)(props)};
    font-size: ${props => props.theme.forms.fontSize};
    height: ${props => props.theme.forms.height};
    line-height: ${props => props.theme.forms.lineHeight};
    padding: ${props =>
    `${props.theme.forms.paddingVertical} ${props.theme.forms.paddingHorizontal
    }`};
    transition-duration: 0.23s;
    transition-property: border-color, color;
    transition-timing-function: ease-out;
    width: 100%;
    -webkit-text-fill-color: currentColor;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:focus {
        border-color: ${props =>
    color(props.theme.forms.borderColorFocus)(props)};
    }

    ${props => inputPlaceholder`
    color: ${color(props.theme.forms.placeholderColor)(props)};
    opacity: ${color(props.theme.forms.placeholderOpacity)(props)};
  `} ${props =>
    props.disabled &&
    css`
            background-color: ${color('disabled')};
            cursor: not-allowed;
        `};
`

export const buttonIncognito = css`
    background: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    font: inherit;
    margin: 0;
    padding: 0;
    text-transform: none;
    transition: color 0.3s;
`

// Forms releated styles
export const FormContentCard = styled.section`
  .header {
    border-bottom: 1px rgba(76, 76, 75, 0.1) solid;
    margin-bottom: 0.7em;
    padding-bottom: 0.3em;
    font-size: 1.1rem;
    font-weight: bolder;
  }
  .Select {
    font-size: 14px;
    
  }
  .Select--multi .Select-value{
      font-weight:500;
      
    }
  .content {
    ol{
      line-height:2;
    }
    .notes {
      width: 85%;
      margin: 0 auto;
      padding: 10px 0;
      font-size: 14px;
    }
    background-color: white;
    padding: ${props => (props.padding ? props.padding : '30px')};
    // box-shadow: ${props => props.theme.boxShadow[3]};
    form {
      width: 85%;
      margin: 0 auto;
    }
    textarea {
      height: 64px;
    }
    .captcha {
      width: 100%;
    }
    
    .date {
      width: 100%;
      .react-datepicker__day--selected {
        font-weight: 500;
        background-color: ${props => props.theme.color.primary};
      }
      .react-datepicker-wrapper {
        input {
          height: 32px;
          padding: 8px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-color: #fff;
          border-radius: 3px;
          border: solid #e0e0e0;
          border-top-width: 1px;
          border-right-width: 1px;
          border-bottom-width: 1px;
          border-left-width: 1px;
          box-shadow: none;
          color: solid currentColor;
          font-size: 0.8rem;
          height: 40px;
          line-height: normal;
          padding: 0.25rem 0.5rem;
          -webkit-transition-duration: 0.23s;
          transition-duration: 0.23s;
          -webkit-transition-property: border-color, color;
          transition-property: border-color, color;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
          width: 100%;
          -webkit-text-fill-color: currentColor;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }
  ${media.mobileTablet`
      .content{
        padding:18px;
      }
      
    `};
`

export const FieldBlockBox = styled.div`
  display: block;
  justify-content: ${props => (props.center ? 'center' : 'space-between')};
  width: 100%;
  margin: 0 auto;
  .lite-label{
    display: flex;
    width: 40%;
    align-items: center;
    margin-bottom: 0.7rem;
  }
  .label {
    padding-top: 12px;
    display: flex;
    width: 40%;
    align-items: center;
  }
  .consumableQty{
    width:20%;
  }
  .consumableLabel {
    flex-direction: column;
    align-items: flex-start;
    width:50%;
    .note {
      padding: 3px 0;
      font-size: 12px;
      opacity: 0.8;
      font-weight: 400;
    }
  }
  .price {
    padding-top: 12px;
    font-size: 14px;
    font-weight: 400;
    // color: ${props => props.theme.color.primary};
    display: flex;
    width: 20%
    align-items: flex-start;
  }
  .field {
    width: 60%;
  }
  `

export const FieldFlexBox = styled.div`
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'space-between')};
  width: 100%;
  margin: 0 auto;
  .lite-label{
    display: flex;
    width: 40%;
    align-items: center;
    margin-bottom: 0.7rem;
  }
  .label {
    padding-top: 12px;
    display: flex;
    width: 430px;
    align-items: center;
  }
  .consumableQty{
    width:20%;
  }
  .consumableLabel {
    flex-direction: column;
    align-items: flex-start;
    width:50%;
    .note {
      padding: 3px 0;
      font-size: 12px;
      opacity: 0.8;
      font-weight: 400;
    }
  }
  .price {
    padding-top: 12px;
    font-size: 14px;
    font-weight: 400;
    // color: ${props => props.theme.color.primary};
    display: flex;
    width: 20%
    align-items: flex-start;
  }
  .field {
    width: 60%;
  }
`
