/**
 * Canon
 *
 * @package     mcb-front-end
 * @project     Ignite
 * @desc        Generic browser history class to allow redirects programmatically
 * @repo
 * @link        https://auth0.com/blog/react-router-4-practical-tutorial/
 * @time        21 May 2018 @ 08:20
 *
 */
//
// -------------------------------------
//   Dependencies: core
// -------------------------------------
//
import { createBrowserHistory } from 'history'
export default createBrowserHistory()
