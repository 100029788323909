import React, { Component } from 'react'
import TermsAndConditionsApi from './terms-conditions-api'
import Loading from 'components/common/loading'
import Modal from 'components/common/modal'
import Button from 'components/common/buttons/link-button-icon.jsx'
import TermsConditionsContent from './terms-conditions-content'
import history from 'history/history.js'
import './terms-conditions.css'

const TermsAndConditions = WrappedComponent => {
    return class extends Component {
        constructor() {
            super()
            this.state = {
                acceptTAndCFlag: true,
                loadingTAndCFlag: false
            }
        }

        componentDidMount() {
            const memberId = localStorage.getItem('member_id')
            const acceptTAndCFlagStoreValue = localStorage.getItem(
                `${memberId}-T&C`
            )
            const acceptTAndCFlag = acceptTAndCFlagStoreValue
                ? acceptTAndCFlagStoreValue === 'true'
                : false
            if (!acceptTAndCFlag) {
                this.setState({
                    loadingTAndCFlag: true
                })
                TermsAndConditionsApi.fetchAcceptTermsAndConditions(memberId)
                    .then(data => {
                        if (
                            data.Status === 'OK' &&
                            data.Payload &&
                            data.Payload.Terms &&
                            data.Payload.Terms.length > 0
                        ) {
                            localStorage.setItem(`${memberId}-T&C`, 'true')
                            this.setState({
                                loadingTAndCFlag: false,
                                acceptTAndCFlag: true
                            })
                        } else {
                            localStorage.setItem(`${memberId}-T&C`, 'false')
                            this.setState({
                                loadingTAndCFlag: false,
                                acceptTAndCFlag: false
                            })
                        }
                    })
                    .catch(error =>
                        this.setState({
                            loadingTAndCFlag: false,
                            acceptTAndCFlag: false
                        })
                    )
            } else {
                this.setState({
                    loadingTAndCFlag: false,
                    acceptTAndCFlag: true
                })
            }
        }

        acceptTermsAndConditions = () => {
            const memberId = localStorage.getItem('member_id')
            localStorage.setItem(`${memberId}-T&C`, 'true')
            this.setState({
                loadingTAndCFlag: false,
                acceptTAndCFlag: true
            })
            TermsAndConditionsApi.putAcceptTermsAndConditions(memberId)
        }

        render() {
            const { acceptTAndCFlag, loadingTAndCFlag } = this.state

            if (loadingTAndCFlag)
                return (
                    <div className="device section-layout-1200">
                        <Loading
                            center
                            size="40px"
                            thickness="1px"
                            paddingAround="200px"
                            message="Evaluating your Terms and Conditions"
                        />
                    </div>
                )
            else if (!acceptTAndCFlag)
                return (
                    <div className="terms-conditions">
                        <Modal
                            isOpen={!acceptTAndCFlag}
                            showClose={true}
                            closeOnClickOutside={true}
                            closeOnEscapeClick={true}
                            onClose={() => {
                                this.setState({
                                    acceptTAndCFlag: false
                                })
                                history.push('/')
                            }}
                            width="35rem"
                            hideHeader={false}
                            type="transactional"
                            renderHeader={() => (
                                <div className="header">Terms of Use</div>
                            )}
                            renderFooter={() => (
                                <div className="footer">
                                    <Button
                                        btnClass="btn__1-r fs-vsmall"
                                        btnTo={'/'}
                                        btnText="Decline"
                                    />
                                    <Button
                                        btnClass="btn__4-r fs-vsmall"
                                        onClick={() => {
                                            this.acceptTermsAndConditions()
                                        }}
                                        btnText="Accept"
                                    />
                                </div>
                            )}
                        >
                            <div className="content">
                                <TermsConditionsContent />
                            </div>
                        </Modal>
                        <div className="blurred">
                            <WrappedComponent {...this.props} />
                        </div>
                    </div>
                )

            return <WrappedComponent {...this.props} />
        }
    }
}

export default TermsAndConditions
