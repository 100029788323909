// @flow

export const IS_SERVER = typeof window === 'undefined'

export const AU = 'en_AU'
export const NZ = 'en_NZ'

export const FIELD_TYPES = {
    CHECKBOX: 'checkbox',
    DATE: 'date',
    RADIO: 'radio',
    RANGE: 'range',
    SELECT: 'select',
    TEXTAREA: 'textarea',
    TOGGLE: 'toggle',
    TEXT: 'text',
    EMAIL: 'email',
    PHONE: 'phone',
    TEL: 'tel',
    FILE: 'file',
    FILEINPUT: 'fileInput'
}

export const MATERIAL_ICONS = [
    {icon: 'image', extension: 'jpg'},
    {icon: 'image', extension: 'png'},
    {icon: 'image', extension: 'gif'},
    {icon: 'image', extension: 'jpeg'},
    {icon: 'file-pdf', extension: 'pdf'},
    {icon: 'link', extension: 'link'},
    {icon: 'globe', extension: 'url'},
    {icon: 'youtube', extension: 'video'}
]

export const FORMULATE_FIELD_TYPES = {
    TEXT: 'TextField',
    SELECT: 'DropDownField',
    TEXTAREA: 'TextAreaField'
}

export const FORMULATE_VALIDATION_TYPES = {
    REGEX: 'ValidationRegex',
    REQUIRED: 'ValidationMandatory'
}

export const TIME_LIMT_FOR_FETCH = 3000000

export const STATES = [
    {
        label: 'New South Wales',
        value: 'NSW'
    },
    {
        label: 'Victoria',
        value: 'VIC'
    },
    {
        label: 'Queensland',
        value: 'QLD'
    },
    {
        label: 'Tasmania',
        value: 'TAS'
    },
    {
        label: 'South Australia',
        value: 'SA'
    },
    {
        label: 'Western Australia',
        value: 'WA'
    },
    {
        label: 'Northern Territory',
        value: 'NT'
    },
    {
        label: 'Australian Capital Territory',
        value: 'ACT'
    }
]

export const PHONE_COUNTRIES = ['AU', 'FJ', 'JP', 'NZ', 'NF', 'PG', 'PH', 'SG', 'US']

// contact categories
export const CONTACT_CATEGORIES = [
    {
        label: 'eConsumable',
        value: 'EFC'
    },
    {
        label: 'eMonitoring',
        value: 'EFS'
    },
    {
        label: 'eMeter Reading',
        value: 'EMR'
    },
    {
        label: 'Consumable Order',
        value: 'FC'
    },
    {
        label: 'Field Quotation',
        value: 'FQ'
    },
    {
        label: 'Field Service Order',
        value: 'FS'
    },
    {
        label: 'Indoor Quotation',
        value: 'IQ'
    },
    {
        label: 'Indoor Service Order',
        value: 'IS'
    },
    {
        label: 'IT Administrator',
        value: 'IT'
    },
    {
        label: 'Meter Reading',
        value: 'MR'
    },
    {
        label: 'Primary Contact',
        value: 'PC'
    },
    {
        label: 'Re-new Contract',
        value: 'RC'
    },
    {
        label: 'Contract Billing',
        value: 'TG'
    },
]


export const CONTRACT_STATUSES = [
    {label: 'Active', value: 'Active'},
    {label: 'Expired', value: 'Expired'},
    {label: 'Draft', value: 'Draft'},
    {label: 'Confirmed', value: 'Confirmed'}
]

export const restrictOptionsAu = [
    {
        label: 'Restrict by Serial, Site, State or Postcode',
        value: ''
    },
    {
        label: ' Restricted by Serial Number',
        value: 'SerialNumber'
    },
    {
        label: 'Restricted by Postcode',
        value: 'PostCode'
    },
    {
        label: 'Restricted by State',
        value: 'State'
    },
    {
        label: 'Restricted by Site',
        value: 'Site'
    }
]

export const restrictOptions = [
    {
        label: 'Restrict by Serial or Postcode',
        value: ''
    },
    {
        label: ' Restricted by Serial Number',
        value: 'SerialNumber'
    },
    {
        label: 'Restricted by Postcode',
        value: 'PostCode'
    }
]

export const contactOptions = [
    {
        label: 'Update information',
        value: 'Update information'
    },
    {
        label: 'Suggestions and feedback',
        value: 'Suggestions and feedback'
    },
    {
        label: 'Request copy of a contract',
        value: 'Request copy of a contract'
    },
    {
        label: 'Network forms help',
        value: 'Network forms help'
    },
    {
        label: 'Safety forms help',
        value: 'Safety forms help'
    },
    {
        label: 'Other',
        value: 'Other'
    }
]
